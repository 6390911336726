// src/components/Users.jsx

import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Alert, Image, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaCog, FaTrash, FaEnvelope, FaUser, FaCheckCircle, FaTimesCircle, FaKey } from 'react-icons/fa'; // Import Font Awesome Icons
import Axios from 'axios';
import ItemBubble from './ItemBubble';
import GenericModal from './GenericModal'; // Import the GenericModal component
import InviteUser from './InviteUser';
import UserDetails from './UserDetails';
import EditUser from './EditUser';
import permissionsData from './permissions.json'; // Adjust the path as needed
import './Users.css'; // Import your CSS file if not already

const Users = ({ token }) => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [modalContent, setModalContent] = useState(null); // State to hold the content of the modal
  const [modalTitle, setModalTitle] = useState(''); // State to hold the title of the modal

  // Create a code-to-name mapping for permissions
  const codeToNameMap = {};

  Object.values(permissionsData).forEach(category => {
    Object.entries(category).forEach(([name, code]) => {
      codeToNameMap[code] = name;
    });
  });

  useEffect(() => {
    fetchUsers();
    fetchPendingInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = () => {
    Axios.get('https://api.njpresearch.com/api/fetch_users', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setUsers(response.data))
      .catch(error => {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users.');
      });
  };

  const fetchPendingInvites = async () => {
    try {
      const response = await fetch('https://api.njpresearch.com/api/fetch-invites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData);
        throw new Error(errorData.detail);
      }

      const data = await response.json();
      console.log(data);
      setPendingInvites(data);
    } catch (err) {
      console.error('Error fetching pending invites:', err);
      setError(err.message);
    }
  };

  const cancelInvite = async (invite_id) => {
    try {
      const response = await fetch('https://api.njpresearch.com/api/delete-invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ invite_id: invite_id }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail);
      }
      const data = await response.json();
      setResponse(data.message);
      // Refresh the pending invites list
      fetchPendingInvites();
    } catch (err) {
      console.error('Error cancelling invite:', err);
      setError(err.message);
    }
  };

  const formatDate = (dateString) => {
    const options = { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handleOpenModal = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setShowModal(true);
  };

  const handleUserClick = (user) => {
    handleOpenModal(
      <UserDetails user={user} />,
      user.username
    );
  };

  const handleInviteUser = () => {
    handleOpenModal(<InviteUser token={token} />, 'Invite User');
  };

  const handleEditUser = (user) => {
    handleOpenModal(<EditUser user={user} token={token} />, 'Edit User');
  };

  const handleInviteClick = (invite) => {
    const permissionNames = getPermissionNames(invite.permissions);
    handleOpenModal(
      <div>
        <p><strong>Email:</strong> {invite.email}</p>
        <p><strong>Permissions:</strong> {permissionNames.join(', ')}</p>
        <p><strong>Expiry:</strong> {formatDate(invite.expiry)}</p>
      </div>,
      'Invite Details'
    );
  };

  // Helper function to limit displayed permissions and add ellipsis if necessary
  const displayPermissionNames = (permissionNames, max = 3) => {
    if (permissionNames.length <= max) {
      return { displayed: permissionNames, remaining: 0 };
    } else {
      const displayed = permissionNames.slice(0, max);
      const remaining = permissionNames.length - max;
      return { displayed, remaining };
    }
  };

  // Utility function to map permission codes to names
  const getPermissionNames = (permissionCodes) => {
    if (!permissionCodes) return [];
    // Handle if permissions are stored as a string
    let codesArray = [];
    if (typeof permissionCodes === 'string') {
      try {
        codesArray = JSON.parse(permissionCodes);
        if (!Array.isArray(codesArray)) {
          codesArray = [];
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
        codesArray = [];
      }
    } else if (Array.isArray(permissionCodes)) {
      codesArray = permissionCodes;
    } else if (typeof permissionCodes === 'number') {
      codesArray = [permissionCodes];
    } else {
      codesArray = [];
    }

    return codesArray.map(code => codeToNameMap[code] || `Unknown (${code})`);
  };

  return (
    <Container fluid className="py-3 min-vh-100 d-flex justify-content-center ">
      <div className="frosted-glass p-3 rounded shadow-lg" style={{ width: '100%' }}>
      {/* Display Errors and Responses */}
      {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
      {response && <Alert variant="success" onClose={() => setResponse(null)} dismissible>{response}</Alert>}

      {/* Registered Users Section */}
      <section className="mb-3">
        <Row className="align-items-center mb-2">
          <Col>
            <h5>Registered Users</h5>
          </Col>
          <Col className="text-end">
            <Button variant="primary" onClick={handleInviteUser}>
              <FaEnvelope className="me-2" />
              Invite User
            </Button>
          </Col>
        </Row>
        <Row>
        {users.length === 0 ? (
          <p className="text-muted">No users found.</p>
        ) : (
          users.map(user => {
            const permissionNames = getPermissionNames(user.permissions);
            const { displayed: displayedPermissions, remaining: remainingPermissions } = displayPermissionNames(permissionNames, 3);

            return (
              <Col xs={12} xl ={6} key={user.username}>
              <ItemBubble
                key={user.username}
                bubbleColor='white' // Optional: Set bubble color
                onClick={() => handleUserClick(user)}
                onRight={() => handleEditUser(user)}
                onLeft={() => { /* Optional: Handle left action if needed */ }}
                rightBubble={{
                  color: '#0d6efd', // Bootstrap primary color
                  icon: 'Cog',
                  iconColor: 'white',
                  show: true,
                }}
              >
                <Container fluid>
                  <Row className="align-items-center">
                    <Col xs={3} md = {2} xl={2} className="text-center text-md-start">
                      <Image
                        src={`${user.picture}_small.avif`}
                        alt={user.username}
                        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%' }}
                        fluid
                      />
                    </Col>
                    <Col xs={7} md = {3} xl={3} className="mb-2 mb-md-0">
                      <h5 className="mb-0">{user.username}</h5>
                      <p className="mb-0 text-muted">{user.first_name} {user.last_name}</p>
                    </Col>
                    <Col xs={12} md = {4} xl={6} className="mb-2 mb-md-0 d-none d-md-block">
                      <div className="d-flex flex-wrap align-items-center">
                        {displayedPermissions.map((perm, index) => (
                          <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={<Tooltip id={`tooltip-${index}`}>{perm}</Tooltip>}
                          >
                            <span className="permission-icon me-2 d-flex align-items-center">
                              <FaKey color="#198754" className="me-1" />
                              <span className="d-none d-md-inline">{perm}</span>
                            </span>
                          </OverlayTrigger>
                        ))}
                        {remainingPermissions > 0 && (
                          <span className="text-muted">+{remainingPermissions} more</span>
                        )}
                      </div>
                    </Col>
                    <Col xs={2} xl={1} className="text-md-end">
                      {user.active ? (
                        <FaCheckCircle color="#721e5d" size={30} title="Active User" aria-label="Active User" />
                      ) : (
                        <FaTimesCircle color="#dc3545" size={30} title="Inactive User" aria-label="Inactive User" />
                      )}
                    </Col>
                  </Row>
                </Container>
              </ItemBubble>
              </Col>
            );
          })
        )}
        </Row>
      </section>

      {/* Pending Invites Section */}
      <section>
        <h5 className="mb-3">Pending Invites</h5>
        {pendingInvites.length === 0 ? (
          <p className="text-muted">No pending invites.</p>
        ) : (
          pendingInvites.map(invite => {
            const permissionNames = getPermissionNames(invite.permissions);
            const { displayed: displayedInvitePermissions, remaining: remainingInvitePermissions } = displayPermissionNames(permissionNames, 12);

            return (
              <ItemBubble
                key={invite.email}
                onClick={() => handleInviteClick(invite)}
                onRight={() => cancelInvite(invite.id)}
                onLeft={() => { /* Optional: Handle left action if needed */ }}
                rightBubble={{
                  color: '#dc3545', // Bootstrap danger color
                  icon: 'Trash',
                  iconColor: 'white',
                  show: true,
                  confirmationText: 'Cancel',
                  confirmation: true,
                }}
                className="mb-3 shadow-sm"
              >
                <Container fluid>
                  <Row className="align-items-center">
                    <Col xs = {12} xl = {4} className="mb-2 mb-md-0">
                      <Col xs={12} className="mb-2 mb-md-0">
                        <h5 className="mb-0">{invite.email}</h5>
                      </Col>
                      <Col xs={12}>
                        <p className="mb-0"><strong>Expiry:</strong> {formatDate(invite.expiry)}</p>
                      </Col>
                    </Col>
                    <Col xs={12} xl={8} className="mb-2 mb-md-0 d-none d-md-block">
                      <div className="d-flex flex-wrap align-items-center">
                        {displayedInvitePermissions.map((perm, index) => (
                          // <OverlayTrigger
                          //   key={index}
                          //   placement="top"
                          //   overlay={<Tooltip id={`tooltip-invite-${index}`}>{perm}</Tooltip>}
                          // >
                            <span className="permission-icon me-2 d-flex align-items-center">
                              <FaKey color="#198754" className="me-1" />
                              <span className="d-none d-md-inline">{perm}</span>
                            </span>
                          // </OverlayTrigger>
                        ))}
                        {remainingInvitePermissions > 0 && (
                          <span className="text-muted">+{remainingInvitePermissions} more</span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ItemBubble>
            );
          })
        )}
      </section>

      {/* Generic Modal */}
      <GenericModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        size="lg" // Optional: Adjust modal size as needed
      >
        {modalContent}
      </GenericModal>
      </div>
    </Container>
  );
};

export default Users;
