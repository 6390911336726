import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import { debounce } from 'lodash';

function interpretActionCode(actionId) {
  const actionTypes = {
    1: 'Delete',
    2: 'Add',
    3: 'Change'
  };

  const entityTypes = {
    1: 'Scanned',
    2: 'Inventory',
    3: 'Users',
    4: 'Shipments',
    5: 'Suppliers'
  };

  const actionType = Math.floor(actionId / 100);
  const entityType = actionId % 100;

  const action = actionTypes[actionType];
  const entity = entityTypes[entityType];

  if (action && entity) {
    return `${action} ${entity}`;
  } else {
    return 'Unknown Action'; // Default message for unrecognized codes
  }
}


function UserActivityPage({ token }) {
  const [userActivities, setUserActivities] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const fetchUserActivities = useCallback(debounce(async () => {
    if (!hasMore) return;  // Early return if there's no more data to fetch

    setLoading(true);
    try {
      const response = await axios.get(`https://api.njpresearch.com/api/fetch_user_activity`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { page: page, page_size: 100 }
      });
      console.log('User activities:', response.data);
      setUserActivities(prev => [...prev, ...response.data]);
      setHasMore(response.data.length === 100);  // Only set hasMore to true if a full page is returned
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch user activities:', error);
      setLoading(false);
    }
  }, 300), [page, token, hasMore]);  // Include hasMore in dependencies to re-create debounce function when it changes

  useEffect(() => {
    fetchUserActivities();
    return () => {
      fetchUserActivities.cancel(); // Cancel the debounce on unmount to avoid memory leaks
    };
  }, [fetchUserActivities]);

  const lastActivityElementRef = useCallback(node => {
    if (loading || !hasMore) return;  // Prevent setting up new observer if loading or no more items
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  return (
    <Container>
      <h1>User Activities</h1>
      {userActivities.map((activity, index) => {
        const isLastElement = userActivities.length === index + 1;
        const description = interpretActionCode(activity.action_id);
        return (
          <Card ref={isLastElement ? lastActivityElementRef : null} key={activity.id}>
            <Card.Body>
              <Card.Title>Activity {index + 1}</Card.Title>
              <Card.Text>
                Description: {description}
              </Card.Text>
            </Card.Body>
          </Card>
        );
      })}
      {loading && <p>Loading...</p>}
    </Container>
  );
}

export default UserActivityPage;
