// OffCanvasContent.js

import React from 'react';
import { Nav, Accordion, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './OffCanvasContent.css'; // Import the custom CSS
import {
  FaHome,
  FaBoxOpen,
  FaChartBar,
  FaFilter,
  FaBarcode,
  FaSearch,
  FaUsers,
  FaUserPlus,
  FaUserTimes,
  FaMapMarkerAlt,
  FaGavel,
  FaWarehouse,
  FaUserCircle,
  FaSignInAlt,
  FaUserEdit,
  FaSignOutAlt,
  FaTruck,
} from 'react-icons/fa';

const OffCanvasContent = ({ loggedIn, user, logout }) => {
  // Define all event keys for the accordions
  const accordionKeys = ['main', 'options', 'userManagement', 'auth'];

  return (
    <Nav className="flex-column offcanvas-nav" style={{ width: '100%', height: '100%', backgroundColor:'white'}}>
      <Accordion defaultActiveKey={accordionKeys} alwaysOpen bsPrefix="custom-accordion">
        {/* Main Navigation Accordion */}
        <Accordion.Item eventKey="main" bsPrefix="custom-accordion-item">
          <Accordion.Header bsPrefix="custom-accordion-header">
            <FaHome className="nav-icon" /> Main Navigation
          </Accordion.Header>
          <Accordion.Body bsPrefix="custom-accordion-body">
            <NavDropdown.Item as={Link} to="/" style={{ color: 'grey' }}>
              <FaHome className="nav-icon" /> Item Lookup
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/inventory" style={{ color: 'grey' }}>
              <FaBoxOpen className="nav-icon" /> Inventory
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/user-activity" style={{ color: 'grey' }}>
              <FaChartBar className="nav-icon" /> Activity
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/user-activity-filter" style={{ color: 'grey' }}>
              <FaFilter className="nav-icon" /> Activity Filter
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/scanned" style={{ color: 'grey' }}>
              <FaBarcode className="nav-icon" /> Scanned
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/item-search" style={{ color: 'grey' }}>
              <FaSearch className="nav-icon" /> Item Search
            </NavDropdown.Item>
          </Accordion.Body>
        </Accordion.Item>

        {/* More Options Accordion */}
        <Accordion.Item eventKey="options" bsPrefix="custom-accordion-item">
          <Accordion.Header bsPrefix="custom-accordion-header">
            <FaUsers className="nav-icon" /> More Options
          </Accordion.Header>
          <Accordion.Body bsPrefix="custom-accordion-body">
            <NavDropdown.Item as={Link} to="/suppliers" style={{ color: 'grey' }}>
              <FaWarehouse className="nav-icon" /> Suppliers
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/shipments" style={{ color: 'grey' }}>
              <FaTruck className="nav-icon" /> Shipments
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/auctions" style={{ color: 'grey' }}>
              <FaGavel className="nav-icon" /> Auctions
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/locations" style={{ color: 'grey' }}>
              <FaMapMarkerAlt className="nav-icon" /> Locations
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/barcode-scanner" style={{ color: 'grey' }}>
              <FaBarcode className="nav-icon" /> Scanner
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/auction-preview/" style={{ color: 'grey' }}>
              <FaGavel className="nav-icon" /> Auction Preview
            </NavDropdown.Item>
          </Accordion.Body>
        </Accordion.Item>

        {/* User Management Accordion */}
        <Accordion.Item eventKey="userManagement" bsPrefix="custom-accordion-item">
          <Accordion.Header bsPrefix="custom-accordion-header">
            <FaUserPlus className="nav-icon" /> User Management
          </Accordion.Header>
          <Accordion.Body bsPrefix="custom-accordion-body">
            <NavDropdown.Item as={Link} to="/users" style={{ color: 'grey' }}>
              <FaUsers className="nav-icon" /> Users
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/invalidate-user" style={{ color: 'grey' }}>
              <FaUserTimes className="nav-icon" /> Invalidate User
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/invite-user" style={{ color: 'grey' }}>
              <FaUserPlus className="nav-icon" /> Invite User
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/create-user" style={{ color: 'grey' }}>
              <FaUserPlus className="nav-icon" /> Create User
            </NavDropdown.Item>
          </Accordion.Body>
        </Accordion.Item>

        {/* Authentication Accordion */}
        <Accordion.Item eventKey="auth" bsPrefix="custom-accordion-item">
          <Accordion.Header bsPrefix="custom-accordion-header">
            <FaUserCircle className="nav-icon" /> Authentication
          </Accordion.Header>
          <Accordion.Body bsPrefix="custom-accordion-body">
            {loggedIn ? (
              <>
                <NavDropdown.Item style={{ color: 'grey' }}>
                  <FaUserCircle className="nav-icon" /> Logged in as: {user.username}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout} style={{ color: 'grey' }}>
                  <FaSignOutAlt className="nav-icon" /> Logout
                </NavDropdown.Item>
              </>
            ) : (
              <>
                <NavDropdown.Item as={Link} to="/login" style={{ color: 'grey' }}>
                  <FaSignInAlt className="nav-icon" /> Login
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/signup" style={{ color: 'grey' }}>
                  <FaUserEdit className="nav-icon" /> Signup
                </NavDropdown.Item>
              </>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Nav>
  );
};

export default OffCanvasContent;
