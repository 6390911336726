// utils.js
export const getImageLink = (item) => {
    const getImageSrc = () => {
      if (!item) return null;
      if (item.image_url) return item.image_url;
      if (item.image) return item.image;
      if (item.images && item.images.length > 0) return item.images[0];
      return null;
    };
  
    let imageSrc = getImageSrc();
    if (imageSrc) {
      imageSrc = imageSrc.includes("bdwarehouse") ? imageSrc + "_medium.avif" : imageSrc;
    }
    return imageSrc;
  };