// src/components/IntcodeNavigator.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import BarcodeListener from './BarcodeListener';
import { barcodePatterns } from './BarcodePatterns';

/**
 * IntcodeNavigator Component
 * 
 * Listens for barcode scans and navigates to /item/{npcoderead} when an 'INTCODE' is detected.
 */
const IntcodeNavigator = () => {
  const navigate = useNavigate();

  const handleBarcodeDetected = ({ name, value }) => {
    if (name === 'INTCODE') {
      console.log(`INTCODE detected: ${value}`);
      // Navigate to /item/{npcoderead}
      navigate(`/confirmation/${value}`);
    }
    // Handle other barcode types if needed
    else {
      console.log(`Detected ${name}: ${value}`);
    }
  };

  return (
    <BarcodeListener
      patterns={barcodePatterns}
      onDetected={handleBarcodeDetected}
      bufferTimeout={100} // Adjust as needed
    />
  );
};

export default IntcodeNavigator;
