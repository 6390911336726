import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedComponent = ({ token }) => {
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const response = await fetch('https://api.njpresearch.com/users/me/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setIsValid(true);
      } else {
        setIsValid(false);
        navigate('/login');
      }
    };

    verifyToken();
  }, [token, navigate]);

  if (!isValid) {
    return null;
  }

  return (
    <div>
      <h2>This is a protected route</h2>
    </div>
  );
};

export default ProtectedComponent;
