import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Form, Button, Card, Dropdown, InputGroup, FormControl, Accordion } from 'react-bootstrap';
import axios from 'axios';

function interpretActionCode(actionId) {
    const actionTypes = {
      1: 'Delete',
      2: 'Add',
      3: 'Change'
    };
  
    const entityTypes = {
      1: 'Scanned',
      2: 'Inventory',
      3: 'Users',
      4: 'Shipments',
      5: 'Suppliers'
    };
  
    const actionType = Math.floor(actionId / 100);
    const entityType = actionId % 100;
  
    const action = actionTypes[actionType];
    const entity = entityTypes[entityType];
  
    if (action && entity) {
      return `${action} ${entity}`;
    } else {
      return 'Unknown Action'; // Default message for unrecognized codes
    }
  }
  

function UserActivityFilterPage({ token }) {
  const [activities, setActivities] = useState([]);
  const [actionCodes, setActionCodes] = useState([]);
  const [activityAreas, setActivityAreas] = useState([]);
  const [users, setUsers] = useState([]); // State to hold user data
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [startDate, setStartDate] = useState('');  // Start date state
  const [endDate, setEndDate] = useState('');      // End date state
  const [limit, setLimit] = useState(50); // Default limit

  useEffect(() => {
    // Mock fetch or define your static options here
    fetchUsers();
    setActionCodes([
      { id: 100, name: 'Delete' },
      { id: 200, name: 'Add' },
      { id: 300, name: 'Change' }
    ]);
    setActivityAreas([
      { id: 1, name: 'Scanned' },
      { id: 2, name: 'Inventory' },
      { id: 3, name: 'Users' },
      { id: 4, name: 'Shipments' },
      { id: 5, name: 'Suppliers' }
    ]);
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://api.njpresearch.com/api/fetch_users', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data); // Set user data
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  const fetchActivities = async () => {
    try {
        console.log(selectedCodes);
        console.log(selectedAreas);
        const queryParams = new URLSearchParams();
        queryParams.append('limit', limit);
        if (selectedCodes.length) {
          selectedCodes.forEach(code => queryParams.append('action_codes', code));
        }
        if (selectedAreas.length) {
          selectedAreas.forEach(area => queryParams.append('activity_areas', area));
        }
        if (selectedUsers.length) {
          selectedUsers.forEach(user => queryParams.append('user_ids', user));
        }
        if (startDate) {
          queryParams.append('start_date', startDate);
        }
        if (endDate) {
          queryParams.append('end_date', endDate);
        }
        const response = await axios.get(`https://api.njpresearch.com/api/fetch_all_user_activity?${queryParams}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      setActivities(response.data);
    } catch (error) {
      console.error('Failed to fetch activities:', error);
    }
  };

  const handleToggleCode = (codeId) => {
    setSelectedCodes(codes =>
      codes.includes(codeId) ? codes.filter(c => c !== codeId) : [...codes, codeId]
    );
  };

  const handleToggleArea = (areaId) => {
    setSelectedAreas(areas =>
      areas.includes(areaId) ? areas.filter(a => a !== areaId) : [...areas, areaId]
    );
  };

  const handleToggleUser = (userId) => {
    setSelectedUsers(prevSelectedUsers =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter(id => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };
  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    // fetchActivities(); // Optionally, you might want to refetch the activities upon limit change
  };

  const getUsernameById = (id) => {
    const user = users.find(user => user.id === id);
    return user ? user.username : 'Unknown'; // Default to 'Unknown' if user isn't found
  };

  const clearDates = () => {
    setStartDate('');
    setEndDate('');
  };

  return (
    <Container>
      <h1>User Activities</h1>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Filters</Accordion.Header>
                    <Accordion.Body>
                    <Row>
                        { /* Filters setup for users, action codes, and activity areas */ }
                        <Col md={4}>
                            <strong>Users</strong>
                            {users.map(user => (
                            <Form.Check
                                type="checkbox"
                                id={`user-${user.id}`}
                                label={user.username}
                                checked={selectedUsers.includes(user.id)}
                                onChange={() => handleToggleUser(user.id)}
                            />
                            ))}
                        </Col>
                        <Col md={4}>
                            <strong>Action Codes</strong>
                            {actionCodes.map(code => (
                            <Form.Check
                                type="checkbox"
                                id={`action-${code.id}`}
                                label={`${code.name} (${code.id})`}
                                checked={selectedCodes.includes(code.id)}
                                onChange={() => handleToggleCode(code.id)}
                            />
                            ))}
                        </Col>
                        <Col md={4}>
                            <strong>Activity Areas</strong>
                            {activityAreas.map(area => (
                            <Form.Check
                                type="checkbox"
                                id={`area-${area.id}`}
                                label={area.name}
                                checked={selectedAreas.includes(area.id)}
                                onChange={() => handleToggleArea(area.id)}
                            />
                            ))}
                        </Col>
                          <Col md={6}>
                            <Form.Group controlId="startDate">
                              <Form.Label>Start Date</Form.Label>
                              <Form.Control
                                type="date"
                                value={startDate}
                                onChange={e => setStartDate(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="endDate">
                              <Form.Label>End Date</Form.Label>
                              <Form.Control
                                type="date"
                                value={endDate}
                                onChange={e => setEndDate(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Select value={limit} onChange={(e) => handleLimitChange(e.target.value)}>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                            </Form.Select>
                          </Col>
                          <Col>
                            <Button variant="secondary" onClick={clearDates}>Clear Dates</Button>
                          </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Button variant="primary" onClick={fetchActivities}>Fetch Activities</Button>
                            </Col>
                        </Row>
                    </Accordion.Body>
            </Accordion.Item>
        </Accordion>
      <Row className="mt-4">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>User ID</th>
              <th>Action ID</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, index) => (
              <tr key={index}>
                <td>{activity.id}</td>
                <td>{getUsernameById(activity.user_id)}</td>
                <td>{interpretActionCode(activity.action_id)}</td>
                <td>{activity.time}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default UserActivityFilterPage;
