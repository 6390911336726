import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const LocationSettingsModal = ({ show, handleClose, applySettings }) => {
    const [displayType, setDisplayType] = useState('table');

    useEffect(() => {
        const savedDisplayType = localStorage.getItem('displayType');
        if (savedDisplayType) {
            setDisplayType(savedDisplayType);
        }
    }, []);

    const handleSave = () => {
        localStorage.setItem('displayType', displayType);
        applySettings(displayType);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="displayType">
                        <Form.Label>Select Display Type</Form.Label>
                        <Form.Control as="select" value={displayType} onChange={(e) => setDisplayType(e.target.value)}>
                            <option value="table">Table</option>
                            <option value="cards">Cards</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationSettingsModal;
