import React, { useState, useEffect } from 'react';
import { Button, Table, Container, Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import SupplierForm from './SupplierForm';
import ItemBubble from './ItemBubble';
import GenericModal from './GenericModal'; // Import the GenericModal component
import EditSupplier from './EditSupplier';
import AddSupplier from './AddSupplier';
import SupplierDetails from './SupplierDetails';
import { FaCog, FaTrash, FaEnvelope, FaUser, FaCheckCircle, FaTimesCircle, FaKey } from 'react-icons/fa'; // Import Font Awesome Icons
const Suppliers = ({ token }) => {
    const [suppliers, setSuppliers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(null);  // Change to store supplier object
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState(null);
    useEffect(() => {
        fetchSuppliers();
    }, []);

    const fetchSuppliers = () => {
        Axios.get('https://api.njpresearch.com/api/fetch_suppliers', {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => setSuppliers(response.data))
        .catch(error => console.error('Error fetching suppliers:', error));
    };

    // const handleEdit = (supplier) => {
    //     setCurrentSupplier(supplier);  // Now storing the whole supplier object
    //     setShowModal(true);
    // };

    const handleAdd = () => {
        
        handleOpenModal(<AddSupplier/>, 'Edit User');
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleOpenModal = (content, title) => {
        setModalContent(content);
        setModalTitle(title);
        setShowModal(true);
      };
      const handleEditSupplier = (supplier) => {
        handleOpenModal(<EditSupplier supplier={supplier}/>, supplier.name);
      };
      const handleViewSupplier = (supplier) => {
        handleOpenModal(<SupplierDetails supplier={supplier}/>, supplier.name);
      }

    

    return (
    <Container fluid className="py-3 d-flex justify-content-center " style = {{height:'100%'}}>
      <div className="frosted-glass p-3 rounded shadow-lg" style={{ maxWidth:"800px", width: '100%' }}>
            <h1>Suppliers List</h1>
            {suppliers.map(supplier => (
                <ItemBubble
                    key={supplier.id}
                    item={supplier}
                    onClick={() => handleViewSupplier(supplier)}
                    onRight={() => handleEditSupplier(supplier)}
                    // onLeft={() => deleteSupplier(supplier.id)}
                    rightBubble={{
                        color: '#0d6efd', // Bootstrap primary color
                        icon: 'Cog',
                        iconColor: 'white',
                        show: true,
                      }}
                    
                >
                <Container fluid>
                    <Row className="align-items-center">
                        <Col xs={11} md = {11}className="mb-2 mb-md-0">
                            <h5 className="mb-0">{supplier.name}</h5>
                            <p className="mb-0 text-muted">{supplier.representative_name}: {supplier.email}</p>
                        </Col>
                        <Col xs={1} className="text-end">
                            {supplier.active ? (
                                <FaCheckCircle color="#721e5d" size={20} title="Active User" aria-label="Active User" />
                            ) : (
                                <FaTimesCircle color="#dc3545" size={20} title="Inactive User" aria-label="Inactive User" />
                            )}
                        </Col>
                    </Row>
                </Container>
                </ItemBubble>
            ))}
                        <Button className="animated-button btn-purple" style={{width:'100%'}}onClick={handleAdd}>Add Supplier</Button>
                
            {/* Generic Modal */}
            <GenericModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={modalTitle}
                size="lg" // Optional: Adjust modal size as needed
            >
                {modalContent}
            </GenericModal>
        </div>
    </Container>
    );
    
};

export default Suppliers;
