import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import Axios from 'axios';

const AddItemsToLocationModal = ({ show, handleClose, locationCode, token }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [addedItems, setAddedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const searchInputRef = useRef(null);
    const finalConfirmInputRef = useRef(null);

    useEffect(() => {
        if (show) {
            setSearchResults([]);
            setAddedItems([]);
            setSearchTerm('');
            setNoResults(false);
            searchInputRef.current.focus();
        }
    }, [show]);

    useEffect(() => {
        if (showConfirmation) {
            finalConfirmInputRef.current.focus();
        }
    }, [showConfirmation]);

    const handleSearch = () => {
        if (searchTerm === locationCode) {
            setSearchTerm('');
            handleConfirm();
        } else {
            setLoading(true);
            setNoResults(false);
            Axios.get(`https://api.njpresearch.com/api/fetch_inventory_item/${searchTerm}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(response => {
                if (response.data) {
                    handleAddItem(response.data);
                    setSearchResults([response.data]);
                } else {
                    setNoResults(true);
                }
                setLoading(false);
                setSearchTerm('');
                searchInputRef.current.focus();
            })
            .catch(error => {
                console.error('Error fetching item:', error);
                setLoading(false);
                setNoResults(true);
            });
        }
    };

    const handleAddItem = (item) => {
        if (!addedItems.find(i => i.intcode === item.intcode)) {
            setAddedItems(prevItems => [...prevItems, item]);
        }
    };

    const handleRemoveItem = (intcode) => {
        setAddedItems(prevItems => prevItems.filter(item => item.intcode !== intcode));
    };

    const handleConfirm = () => {
        setShowConfirmation(true);
    };

    const handleFinalConfirm = () => {
        const itemIntcodes = addedItems.map(item => item.intcode);
        Axios.post('https://api.njpresearch.com/api/update_inventory_location', {
            location_number: locationCode,
            barcodes: itemIntcodes
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
            alert('Items added to location successfully!');
            setAddedItems([]);
            setSearchResults([]);
            setSearchTerm('');
            handleClose();
        })
        .catch(error => {
            console.error('Error updating location:', error);
            alert('Error updating location: ' + (error.response.data.detail || error.message));
        });
        setShowConfirmation(false);
    };

    const handleFinalCancel = () => {
        setShowConfirmation(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    const handleFinalKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleFinalConfirm();
        }
    };

    return (
        <>
            <Modal show={show && !showConfirmation} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Items to Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group controlId="searchTerm">
                            <Form.Label>Search Item by Intcode</Form.Label>
                            <Form.Control
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter intcode"
                                ref={searchInputRef}
                                autoComplete="off"
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleSearch} disabled={loading}>
                            {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Search'}
                        </Button>
                    </Form>
                    {noResults && <Alert variant="warning" style={{ marginTop: '10px' }}>No results found.</Alert>}
                    <div className="search-results">
                        {searchResults.map(item => (
                            <Card key={item.intcode} style={{ marginTop: '10px' }}>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.intcode}</Card.Text>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                    <div className="added-items" style={{ marginTop: '20px' }}>
                        <h5>Added Items:</h5>
                        {addedItems.map(item => (
                            <Card key={item.intcode} style={{ marginTop: '10px' }}>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.intcode}</Card.Text>
                                    <Button variant="danger" onClick={() => handleRemoveItem(item.intcode)}>Remove</Button>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmation} onHide={handleFinalCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to add the following items to location {locationCode}?</p>
                    <ul>
                        {addedItems.map(item => (
                            <li key={item.intcode}>{item.title} ({item.intcode})</li>
                        ))}
                    </ul>
                    <Form.Control
                        type="text"
                        style={{ position: 'absolute', left: '-9999px' }}
                        onKeyDown={handleFinalKeyDown}
                        ref={finalConfirmInputRef}
                        autoComplete="off"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFinalCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleFinalConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddItemsToLocationModal;
