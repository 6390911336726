import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const EditSupplier = ({ supplier }) => {
    const token = localStorage.getItem('token'); // Declare the token variable
    // Initialize state with the supplier's existing data
    const [formData, setFormData] = useState({
        name: '',
        phone_number:'',
        address: '',
        business_number: '',
        extra_notes: '',
        email: '',
        payout_email: '',
        status: '',
        representative_name: "",
    });

    // Handle input change to update state
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const response = await axios.put('https://api.njpresearch.com/api/add-supplier/', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (response.status === 200) {
                alert('Supplier updated successfully!');
                // Optional: Redirect or refresh page
            } else {
                alert('Failed to update supplier');
            }
        } catch (error) {
            console.error('Error updating supplier:', error);
            alert('An error occurred while updating supplier');
        }
    };

    return (
        <Container classname="justify-content-center text-center">
            <h2>Add Supplier</h2>
            <Form onSubmit={handleSubmit}>
                <Row >
                    <Col md={6}>
                        <Form.Group controlId="name">
                            <Form.Label>Supplier Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="phone_number">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="business_number">
                            <Form.Label>Business Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="business_number"
                                value={formData.business_number}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="payout_email">
                            <Form.Label>Payout Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="payout_email"
                                value={formData.payout_email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="representative_name">
                            <Form.Label>Representative Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="representative_name"
                                value={formData.representative_name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId="extra_notes">
                    <Form.Label>Extra Notes</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        name="extra_notes"
                        value={formData.extra_notes}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Update Supplier
                </Button>
            </Form>
        </Container>
    );
};

export default EditSupplier;