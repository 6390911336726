import React, { useState } from 'react';
import { Row, Form, FormControl, Button, Alert, Col, Spinner, InputGroup, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SearchResultCard from './SearchResultCard';
import { getImageLink } from './utils'; // Assuming you have a utility function to get image links

const SearchTab = ({ handleSearchBar, searchBarResults, handleUse, handleShowModal, usedItemLink, isLoading }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const validateAndProcessLink = async () => {
    const inputParts = inputValue.split(' '); // Split the input by spaces
    const validLink = inputParts.find(part => part.includes('://') && part.includes('http')); // Find the valid link
  
    if (!validLink) {
      setError('Invalid URL');
      return;
    }
  
    setError('');
  
    // Function to extract relevant part of Amazon link
    const extractAmazonLink = (link) => {
      const amazonRegex = /(https:\/\/(www\.)?amazon\.(com|ca)\/dp\/[A-Z0-9]+)/i;
      const match = link.match(amazonRegex);
      return match ? match[0] : link;
    };
  
    const processedLink = validLink.includes('amazon') ? extractAmazonLink(validLink) : validLink;
  
    try {
      if (processedLink.includes('amazon.ca')) {
        await handleSearchBar(processedLink, 'aca');
      } else if (processedLink.includes('walmart')) {
        setError('Cannot process Walmart links');
      } else if (processedLink.includes('amazon.com')) {
        await handleSearchBar(processedLink, 'acom');
      } else {
        await handleSearchBar(processedLink, 'other');
      }
    } catch (error) {
      console.error('Failed to handle search bar:', error);
    } finally {
      setInputValue('');
    }
  };

  return (
    <Container style = {{border:'red'}}classname = 'm-1'>
      <Row className='p-2' >
        <InputGroup>
          <FormControl
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Button className='custom-button-bg' onClick={validateAndProcessLink} disabled={isLoading}>
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Searching...
              </>
            ) : (
              'Search'
            )}
          </Button>
        </InputGroup>
        {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
      </Row>
      <Row>
        {searchBarResults.map((result, index) => (
          <Col key={index} xs={6} md={3} xl={3}>
            <SearchResultCard
              item={result}
              imageUrl={getImageLink(result)} // Assuming getImageLink is a function that returns the image URL
              onUse={() => handleUse(result)}
              onPreview={() => handleShowModal(index, result)}
              showUseButton={true}
              isLoading={result.loading}
              expanded={result.expanded}
              used={result.link === usedItemLink}
              overwriteUse={true}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

SearchTab.propTypes = {
  handleSearchBar: PropTypes.func.isRequired,
  searchBarResults: PropTypes.array.isRequired,
  handleUse: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  usedItemLink: PropTypes.string,
  isLoading: PropTypes.bool.isRequired, // Add the new prop type
};

export default SearchTab;