import React, { useState, useEffect, useRef } from 'react';
import { Table, Container, Row, Col, Form, Button, Image, Accordion } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import InventoryItemModal from './InventoryItemModal';
import './InventoryPage.css';
import axios from 'axios';
const ItemTypes = {
  HEADER: 'header',
};


function DraggableHeader({ column, index, moveHeader }) {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.HEADER,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveHeader(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.HEADER,
    item: { type: ItemTypes.HEADER, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <th
      ref={ref}
      className={`draggable-header ${isDragging ? 'dragging' : ''}`}
    >
      {column}
    </th>
  );
}


function InventoryPage({ token }) {
  const [availableAuctions, setAvailableAuctions] = useState([]);
  const tableContainerRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const navigate = useNavigate();
  const [selectMultiple, setSelectMultiple] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filters, setFilters] = useState({
    condition: '',
    approved: '',
    date: '',
    subcategory: '',
    order_by: 'id_desc' // Default order by id ascending
  });
  const [limit, setLimit] = useState(() => {
    const savedLimit = localStorage.getItem('InventoryLimit');
    return savedLimit ? parseInt(savedLimit) : 50; // Default limit
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState(null);
  
  const handleMouseDown = (index) => {
    setIsDragging(true);
    setDragStartIndex(index);
  };
  
  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartIndex(null);
  };
  
  const handleMouseEnter = (index, event) => {
    if (isDragging && dragStartIndex !== null) {
      const start = Math.min(dragStartIndex, index);
      const end = Math.max(dragStartIndex, index);
      const newSelectedItems = inventory.slice(start, end + 1).map(item => item.id);
      setSelectedItems((prevSelectedItems) => {
        const updatedSelection = new Set(prevSelectedItems);
        newSelectedItems.forEach(id => updatedSelection.add(id));
        return Array.from(updatedSelection);
      });
  
      const { clientY } = event;
      const edgeThreshold = 50;
  
      if (clientY < edgeThreshold) {
        startAutoScroll('up');
      } else if (clientY > window.innerHeight - edgeThreshold) {
        startAutoScroll('down');
      } else {
        setIsAutoScrolling(false);
      }
    }
  };
  
  const startAutoScroll = (direction) => {
    if (!isAutoScrolling) {
      setIsAutoScrolling(true);
      const scrollStep = 10;
      const scrollInterval = setInterval(() => {
        if (direction === 'down') {
          window.scrollBy(0, scrollStep);
        } else if (direction === 'up') {
          window.scrollBy(0, -scrollStep);
        }
      }, 50);
  
      const stopAutoScroll = () => {
        clearInterval(scrollInterval);
        setIsAutoScrolling(false);
      };
  
      document.addEventListener('mouseup', stopAutoScroll, { once: true });
    }
  };

  const [currentPage, setCurrentPage] = useState(1); // Default to first page
  const [selectedColumns, setSelectedColumns] = useState(() => {
    const savedColumns = localStorage.getItem('selectedColumns');
    return savedColumns ? JSON.parse(savedColumns) : ['id', 'title', 'category', 'price']; // Default columns
  });

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries || entries.length === 0) {
        return;
      }
      setContainerWidth(entries[0].contentRect.width);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const categories = {
    "Antiques & Collectables": ["Collectables", "Antiques"],
    "Arts and Crafts": ["Craft Supplies", "Craft Machines", "Sewing Machines"],
    "Music": ["Vinyl Records", "Musical Instruments", "Music Accessories"],
    "Video Gaming": ["Video Games", "Gaming Consoles", "Accessories and Components"],
    "Media": ["DVD/Blu-Ray", "Books"],
    "Home Furniture and Patio": ["Dining Room", "Living Room", "Bedroom Furniture", "Patio Furiture", "Bedroom Items"],
    "Adults": ["Adult Product", "Adult Toys"],
    "Automobiles": ["Cars and Vehicles", "Electrical Scooters & Bicycles", "Boats & Boat's Motors", "Skidoo and Jet Skis"],
    "Office Producs": ["Office Furniture", "Printers, Phones and Office Equipment", "Stationary Supplies"],
    "Food and Grocery": ["Food and Grocery"],
    "Toys & Games": ["Toys & Games"],
    "Beauty & Personal Care": ["Health & Personal Care", "Beauty"],
    "Industrial and Medical": ["Medical", "Industrial"],
    "Personal Accessories": ["Wallets & Bags", "Watches", "Jewellery", "Glasses and Frames"],
    "Automotive": ["Parts & Accessories"],
    "Baby Products": ["Baby Supplies", "Car Seats & Strollers", "Baby Furniture", "Baby Food"],
    "Tools and Home Improvement": ["Hand Tools", "Powered Tools", "Home Improvement", "Accessories & Parts"],
    "Shoes": ["Men's Shoes", "Women's Shoes", "Unisex Shoes", "Baby's & Kids Shoes"],
    "Liquidation": ["Liquidation Pallets", "Liquidation Lots"],
    "Pets": ["Pet Furniture", "Pet Supplies"],
    "Fitness and Gym Equipment": ["Weights and Dumbbells", "Treadmills, Cycling & Rowing", "Workout Stations"],
    "Appliances": ["Home Appliances", "Kitchen Appliances", "Major Appliances"],
    "Clothing": ["Men's Clothing", "Unisex Clothing", "Women's Clothing", "Baby and Kids Clothing"],
    "Home & Kitchen": ["Home Decor", "Bathroom", "Kitchen Items", "Cleaning"],
    "Electronics": ["Speakers, Headphone & Earbuds", "Laptops and Computers", "Electronic Accessories", "Smart Watches", "Tablets and Cell Phones", "TV's and Monitors", "Cameras and Lenses", "Parts and Components"],
    "Sports & Outdoors": ["Outdoor", "Sports"]
  };

  useEffect(() => {
    const loadItems = debounce(() => {
      fetchAvailableAuctions();
      fetchInventory();
    }, 300); // Debounce for 300 ms

    loadItems();
    return () => loadItems.cancel(); // Cleanup debounce
  }, [filters, limit, currentPage, selectedColumns]); // Add selectedColumns as dependencies

  const fetchAvailableAuctions = async () => {
    const apiUrl = `https://api.njpresearch.com/api/fetch_auctions_list`;
    const body = JSON.stringify({ 'articles_processed':true });
    try {
      const response = await axios.post(apiUrl, body, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        console.log('Available auctions:', response.data);
        setAvailableAuctions(response.data);

      } else {
        console.error('Failed to fetch available auctions:', response.data);
        setAvailableAuctions([]);
      }
    } catch (error) {
      console.error('Error fetching available auctions:', error);
      setAvailableAuctions([]);
    }
  };

  const apiUrl = 'https://api.njpresearch.com/api/fetch_inventory';

  const fetchInventory = async () => {
    const queryParams = new URLSearchParams({
      ...filters,
      limit: limit.toString(),
      page: currentPage.toString(),
      // Uncomment and modify the following line if you need to include order_by
      // order_by: filters.order_by.split('_')[0],
    }).toString();
  
    try {
      setLoading(true); // Set loading to true before fetching data
      const response = await fetch(`${apiUrl}?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch inventory');
      }
      const data = await response.json();
      setLoading(false);
  
      // Modify each item in data to parse images JSON
      const updatedInventory = data.map((item) => ({
        ...item,
        images: JSON.parse(item.images), // Parse images JSON
      }));
  
      setInventory(updatedInventory);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'An error occurred');
      setLoading(false);
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    localStorage.setItem('InventoryLimit', newLimit);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setFilters({ ...filters, category: category, subcategory: '' }); // Also reset subcategory in filters
  };

  const handleSubcategoryChange = (event) => {
    const subcategory = event.target.value;
    setSelectedSubcategory(subcategory);
    setFilters({ ...filters, subcategory: subcategory });
  };

  const openInventoryItemModal = (itemId, auctions) => {
    const item = inventory.find((item) => item.id === itemId);
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleDestinationChange = (itemId, newDestination) => {
    const updatedInventory = inventory.map((item) => {
      if (item.id === itemId) {
        return { ...item, suggested_destination: newDestination };
      }
      return item;
    });
    setInventory(updatedInventory);
  };

  const handleColumnChange = (event) => {
    const { value, checked } = event.target;
    setSelectedColumns(prevSelectedColumns =>
      checked ? [...prevSelectedColumns, value] : prevSelectedColumns.filter(column => column !== value)
    );
  };


  const updateDestination = async (itemIds, destination) => {
    const apiUrl = 'https://api.njpresearch.com/api/update-destination'; // Change the API endpoint as needed
    
    try {
      const response = await axios.post(apiUrl, {
        item_ids: itemIds, // Pass item IDs
        destination,       // Pass the destination string
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        console.log('Destination updated successfully', response.data);
        // Optionally, refresh the inventory after the update
        fetchInventory();
      } else {
        console.error('Failed to update destination:', response.data);
      }
    } catch (error) {
      console.error('Error updating destination:', error);
    }
  };

  
  
  const handleOrderByChange = (event) => {
    setFilters({ ...filters, order_by: event.target.value });
  };

  useEffect(() => {
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const moveHeader = (dragIndex, hoverIndex) => {
    const draggedColumn = selectedColumns[dragIndex];
    const newColumns = [...selectedColumns];
    newColumns.splice(dragIndex, 1);
    newColumns.splice(hoverIndex, 0, draggedColumn);
    setSelectedColumns(newColumns);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const allColumns = [
    'id', 'intcode', 'title', 'asin', 'ean', 'upc', 'link', 'price', 'images', 'fnsku', 'dimensions', 'added_by', 'approved_by', 'bullets', 'description', 'time', 'category', 'subcategory', 'suggested_destination', 'destination', 'location', 'brand', 'item_condition', 'origin_code', 'notes', 'image_label'
  ];
  const splitColumns = (arr, numCols) => {
    const perCol = Math.ceil(arr.length / numCols);
    return Array.from({ length: numCols }, (v, i) =>
      arr.slice(i * perCol, i * perCol + perCol)
    );
  };

  
  const columnsChunks = splitColumns(allColumns, containerWidth <= 576 ? 2 : 4);
  return (
    <Container ref={containerRef} fluid>

      {selectedItem && (
        <InventoryItemModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          item={selectedItem}
          auctions={availableAuctions}
          updateDestination={updateDestination}  // Pass the new function to the modal
        />
      )}
<Accordion defaultActiveKey="" className="mt-3">
  <Accordion.Item eventKey="0">
    <Accordion.Header>Filters</Accordion.Header>
    <Accordion.Body>

      <Row className="mt-3">
        <Col>
          <h5>Select Columns to Display</h5>
          <Form>
            <Row>
              {columnsChunks.map((chunk, chunkIndex) => (
                <Col key={chunkIndex} md={3} sm={6}>
                  {chunk.map((column) => (
                    <Form.Check
                      key={column}
                      type="checkbox"
                      label={column}
                      value={column}
                      checked={selectedColumns.includes(column)}
                      onChange={handleColumnChange}
                    />
                  ))}
                </Col>
              ))}
            </Row>
          </Form>
        </Col>
      </Row>
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
      <Row className="mt-3">
      <Col md={2} className='p-2'>
  <Form.Check 
  type="switch"
  id="select-multiple-switch"
  label="Select Multiple"
  checked={selectMultiple}
  onChange={(e) => {
    setSelectMultiple(e.target.checked);
    if (!e.target.checked) {
      setSelectedItems([]); // Deselect all items when turning off "Select Multiple"
    }
  }}
  />
  </Col>
        <Col md={2}>
          <Form.Select
            value={filters.condition}
            onChange={(e) => setFilters({ ...filters, condition: e.target.value })}
            aria-label="Condition"
          >
            <option value="">Condition</option>
            <option value="New">New</option>
            <option value="Open Box">Open Box</option>
            <option value="Refurbished">Refurbished</option>
            <option value="Used">Used</option>
            <option value="As-is">As-is</option>
          </Form.Select>
        </Col>
        <Col md={2}>
          <Form.Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            aria-label="Category"
          >
            <option value="">Category</option>
            {Object.keys(categories).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={2}>
          <Form.Select
            value={selectedSubcategory}
            onChange={handleSubcategoryChange}
            disabled={!selectedCategory}
            aria-label="Subcategory"
          >
            <option value="">Subcategory</option>
            {selectedCategory &&
              categories[selectedCategory].map((subcategory) => (
                <option key={subcategory} value={subcategory}>
                  {subcategory}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col md={1}>
          <Form.Select
            value={filters.approved}
            onChange={(e) => setFilters({ ...filters, approved: e.target.value })}
            aria-label="Approval"
          >
            <option value="">Approval</option>
            <option value="true">Approved</option>
            <option value="false">Not Approved</option>
          </Form.Select>
        </Col>

        <Col md={1} sm={6}>
          <Form.Select
            value={limit}
            onChange={handleLimitChange}
            aria-label="Limit"
          >
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </Form.Select>
        </Col>
        <Col md={1} sm={6}>
          <Form.Select
            value={filters.order_by}
            onChange={handleOrderByChange}
            aria-label="Order By"
          >
            <option value="id_asc">Newest</option>
            <option value="id_desc">Oldest</option>
            <option value="price_asc">Least Expensive</option>
            <option value="price_desc">Most Expensive</option>
          </Form.Select>
        </Col>
</Row>
<Table bordered hover className="mt-3 table-tight" style={{ fontSize: '12px' }}>
  <thead>
    <tr>
      {selectMultiple && <th>Select</th>}
      {selectedColumns.map((column, index) => (
        <DraggableHeader
          key={column}
          column={column}
          index={index}
          moveHeader={moveHeader}
        />
      ))}
    </tr>
  </thead>
  <tbody onMouseUp={handleMouseUp}>
    {inventory.map((item, index) => (
      <tr
        key={item.id}
        onClick={() => openInventoryItemModal(item.id, availableAuctions)}
        style={{ cursor: 'pointer' }}
      >
        {selectMultiple && (
          <td
            style={{ textAlign: 'center', verticalAlign: 'middle' }}
            onMouseDown={(e) => {
              e.stopPropagation(); // Prevent row click event
              handleMouseDown(index);
            }}
            onMouseEnter={(e) => handleMouseEnter(index, e)}
          >
           <Form.Check 
              type="checkbox"
              value={item.id}
              checked={selectedItems.includes(item.id)}
              onClick={(e) => e.stopPropagation()} // Prevent row click event
              onChange={(e) => {
                const itemId = parseInt(e.target.value);
                setSelectedItems((prevSelectedItems) => 
                  prevSelectedItems.includes(itemId) 
                    ? prevSelectedItems.filter(id => id !== itemId)
                    : [...prevSelectedItems, itemId]
                );
              }}
              style={{ transform: 'scale(2)' }} // Scale the checkbox to 2.5 rem
            />
          </td>
        )}
        {selectedColumns.map((column) => (
          column === 'images' ? (
            <td key={column} className = 'title-cell'>
              <Image
                src={`${item.images[0]}_small.avif`}
                className="table-img"
                fluid
              />
            </td>
          ) : (
            <td key={column}>{item[column]}</td>
          )
        ))}
      </tr>
    ))}
  </tbody>
</Table>
      {/* Pagination Controls */}
      <Row className="mt-3">
        <Col>
          <Button
            variant="secondary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <span className="mx-2">Page {currentPage}</span>
          <Button
            variant="secondary"
            disabled={inventory.length < limit}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default InventoryPage;