import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GenericModal from './GenericModal';
import CreateLocation from './CreateLocation';
import EditLocation from './EditLocation';
import ItemBubble from './ItemBubble';


function Locations() {
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get('https://api.njpresearch.com/api/locations/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  const handleOpenModal = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setShowModal(true);
  };


  const handleEditLocation = (location) => {
    handleOpenModal(<EditLocation location={location} />, 'Edit User');
  };
  const handleAddLocation = () => {
    handleOpenModal(<CreateLocation />, 'Create Location');
  };

  return (
    <div>
      <h1>Locations</h1>
      {/* Display locations */}
      {locations.map((location) => (
        <div key={location.id}>
          <ItemBubble
            title={location.name}
            subtitle={location.code}
            onClick={console.log(location)}
            rightBubble={{
              color: '#0d6efd', // Bootstrap primary color
              icon: 'Cog',
              iconColor: 'white',
              show: true,
            }}
            onRight={() => handleEditLocation(location)}
          >
            <h3>Type: {location.type}</h3>
            <p>Dimensions: {JSON.stringify(location.dimensions)}</p>
            <p>Contents: {JSON.stringify(location.contents)}</p>
          </ItemBubble>
        </div>
      ))}
      <button onClick={handleAddLocation}>Add Location</button>
      <GenericModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        size="lg" // Optional: Adjust modal size as needed
      >
        {modalContent}
      </GenericModal>
    </div>
  );
}

export default Locations;
