import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import NavbarExample from './navbar';
import ListingConfirmationPage from './listingConfirmationPage';
import LoginPage from './LoginPage';
import ProtectedComponent from './ProtectedComponent';
import InventoryPage from './InventoryPage';
import ScannedPage from './ScannedPage';
import SignupPage from './Signup';
import './App.css';
import Suppliers from './Suppliers';
import Shipments from './Shipments';
import Users from './Users';
import UserPage from './UserPage';
import UserActivity from './UserActivity';
import UserActivityFilterPage from './UserActivityFilterPage';
import UserAddingData from './UserAddingData';
import Auctions from './Auctions';
import LocationsPage from './LocationsPage';
import ItemEditPage from './ItemEditPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ItemSearch from './ItemSearch';
import ForgotPasswordRecovery from './ForgotPasswordRecovery';
import ResetPassword from './ResetPassword';
import InvalidateUser from './InvalidateUser';
import CreateUser from './CreateUser';
import InviteUser from './InviteUser';
import EditInventoryItem from './EditInventoryItem';
import AuctionPreview from './AuctionPreview';
import OffCanvasContent from './OffCanvasContnet';
import { Offcanvas } from 'react-bootstrap';
import Locations from './Locations';


const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true');
  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '');
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || '');
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }, [token]);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      localStorage.removeItem('refreshToken');
    }
  }, [refreshToken]);

  useEffect(() => {
    if (loggedIn) {
      localStorage.setItem('loggedIn', 'true');
    } else {
      localStorage.setItem('loggedIn', 'false');
    }
  }, [loggedIn]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const refreshAccessToken = async () => {
    try {
      console.log('Refreshing access token...');
      // console.log('Refresh token:', localStorage.refreshToken);
      const response = await fetch('https://api.njpresearch.com/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });

      if (response.ok) {
        const data = await response.json();
        const newExpiryTime = Date.now() + 60 * 60 * 1000; // 60 minutes from now
        setToken(data.access_token);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('expiryTime', newExpiryTime.toString());
      } else {
        // Handle refresh token failure (e.g., redirect to login)
        logout();
      }
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      logout();
    }
  };

  useEffect(() => {
    console.log("Checking token expiry");
    const checkTokenExpiry = () => {
      const expiryTime = localStorage.getItem('expiryTime');
      if (expiryTime) {
        const timeRemaining = Number(expiryTime) - Date.now();
        // If token has already expired or is about to expire within 6 minutes
        const interval_minutes = 6;
        const interval = interval_minutes * 60 * 1000;
        if (timeRemaining < interval) { 
          refreshAccessToken();
        }
      }
    };
  
    if (token) {
      // Check immediately if the token is expired or close to expiring
      checkTokenExpiry();
  
      // Continue checking every 10 minutes (adjusted from 600 * 1000 ms to 10 minutes)
      const interval = setInterval(checkTokenExpiry, 600 * 1000); // 10 minutes
      return () => clearInterval(interval);
    }
  }, [token, refreshToken]);

  const logout = () => {
    setToken('');
    setRefreshToken('');
    setLoggedIn(false);
    setUser('');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('user');
  };

  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <AppContent 
          token={token} 
          setToken={setToken} 
          setRefreshToken={setRefreshToken} 
          setLoggedIn={setLoggedIn} 
          setUser={setUser} 
          loggedIn={loggedIn} 
          user={user} 
          logout={logout} 
          showOffcanvas={showOffcanvas}
          handleShow={handleShow}
          handleClose={handleClose}
        />
      </DndProvider>
    </Router>
  );
};

const AppContent = ({ 
  token, 
  setToken, 
  setRefreshToken, 
  setLoggedIn, 
  setUser, 
  loggedIn, 
  user, 
  logout, 
  showOffcanvas,
  handleShow,
  handleClose,
}) => {
  const location = useLocation();

  // Define routes where Navbar should be hidden
  const hideNavbarRoutes = ['/login', '/signup', '/forgot-password-recovery', '/reset-password'];

  // Check if current path is in hideNavbarRoutes
  const shouldHideNavbar = hideNavbarRoutes.some((path) => {
    // Handle dynamic routes with parameters
    if (path.includes(':')) {
      const basePath = path.split('/:')[0];
      return location.pathname.startsWith(basePath);
    }
    return location.pathname === path;
  });

  return (
    <div className='animated-gradient'>
      {/* Conditionally render Navbar */}
      {!shouldHideNavbar && <NavbarExample handleShow={handleShow} />}
      
      <div className="app-body">
        {/* Side Offcanvas */}
        {!shouldHideNavbar && (
        <Offcanvas
          show={showOffcanvas}
          onHide={handleClose}
          responsive="lg"
          className="offcanvas-custom"
        >
          <Offcanvas.Header closeButton className="d-lg-none">
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ padding: '0px', backgroundColor: 'white' }}>
            <OffCanvasContent loggedIn={loggedIn} user={user} logout={logout} />
          </Offcanvas.Body>
        </Offcanvas>
        )}

        {/* Main Content */}
        <div className="content">
          <Routes>
            {/* Public Routes (No Navbar) */}
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage setToken={setToken} setRefreshToken={setRefreshToken} setLoggedIn={setLoggedIn} setUser={setUser} />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password-recovery" element={<ForgotPasswordRecovery />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* Protected Routes */}
            <Route
              path="/create-user"
              element={token ? <CreateUser /> : <Navigate to="/login" />}
            />
            <Route
              path="/confirmation/:itemId"
              element={token ? <ListingConfirmationPage token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/protected"
              element={token ? <ProtectedComponent /> : <Navigate to="/login" />}
            />
            <Route
              path="/inventory"
              element={token ? <InventoryPage token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/scanned"
              element={token ? <ScannedPage token={token} user={user} /> : <Navigate to="/login" />}
            />
            <Route
              path="/suppliers"
              element={token ? <Suppliers token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/shipments"
              element={token ? <Shipments token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/users"
              element={token ? <Users token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/user"
              element={token ? <UserPage token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/user-activity"
              element={token ? <UserActivity token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/user-activity-filter"
              element={token ? <UserActivityFilterPage token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/user-adding-data"
              element={token ? <UserAddingData token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/auctions"
              element={token ? <Auctions token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/locat"
              element={token ? <LocationsPage token={token} /> : <Navigate to="/login" />}  
            />
            <Route
              path="/item-search"
              element={token ? <ItemSearch token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/item/:itemId"
              element={token ? <ItemEditPage token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/invalidate-user"
              element={token ? <InvalidateUser token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/invite-user"
              element={token ? <InviteUser token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/edit-inventory-item/:itemId"
              element={token ? <EditInventoryItem token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/auction-preview/"
              element={token ? <AuctionPreview token={token} /> : <Navigate to="/login" />}
            />
            <Route
              path="/locations"
              element={token ? <Locations token={token} /> : <Navigate to="/login" />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
