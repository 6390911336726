// src/services/fetchUserStatistics.js
import axios from 'axios';


const fetchUserStatistics = async (userIds, actionTypes) => {
    // Input Validation
    if (!userIds || !Array.isArray(userIds) || userIds.length === 0) {
        throw new Error('No user IDs provided or userIds is not an array.');
    }

    if (!actionTypes || !Array.isArray(actionTypes) || actionTypes.length === 0) {
        throw new Error('No action types provided or actionTypes is not an array.');
    }

    try {
        // Define the API endpoint
        const API_ENDPOINT = 'https://api.njpresearch.com/api/fetch-user-actions';

        // Prepare the request payload
        const payload = {
            user_ids: userIds,
            action_types: actionTypes
            // You can add additional filters here if your API supports them, such as date ranges
            // start_date: '2023-01-01T00:00:00Z',
            // end_date: '2023-12-31T23:59:59Z'
        };

        // Make the POST request to the API endpoint
        const response = await axios.post(API_ENDPOINT, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token if required
            }
        });

        // Extract data from the response
        const data = response.data;

        // Validate the response data format
        if (!Array.isArray(data)) {
            throw new Error('Invalid data format received from server. Expected an array.');
        }

        // Optional: You can perform additional validation on the data items here
        // For example, ensure each item has required fields like 'date' and 'performance'

        console.log('Fetched user statistics:', data);
        return data;
    } catch (error) {
        // Log the error for debugging purposes
        console.error('Failed to fetch user statistics:', error);

        // Handle different error scenarios
        if (error.response) {
            // Server responded with a status other than 2xx
            throw new Error(`Server Error: ${error.response.status} ${error.response.statusText}`);
        } else if (error.request) {
            // Request was made but no response received
            throw new Error('Network Error: No response received from server.');
        } else {
            // Something else happened while setting up the request
            throw new Error(`Error: ${error.message}`);
        }
    }
};

export default fetchUserStatistics;
