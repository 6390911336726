import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Spinner,
} from 'react-bootstrap';
import axios from 'axios';

const CreateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('invite_token');

  const [inviteInfo, setInviteInfo] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    last_name: '',
    invite_token: token,
  });
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation States
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    letter: false,
    number: false,
  });
  const [passwordsMatch, setPasswordsMatch] = useState(null);
  const [fieldValid, setFieldValid] = useState({
    username: null,
    first_name: null,
    last_name: null,
  });

  // Track touched fields
  const [touchedFields, setTouchedFields] = useState({
    username: false,
    password: false,
    confirmPassword: false,
    first_name: false,
    last_name: false,
  });

  useEffect(() => {
    // Fetch invite information using the token
    const fetchInviteInfo = async () => {
      try {
        const response = await axios.post(
          'https://api.njpresearch.com/api/verify-invite',
          {
            invite_token: token,
          }
        );
        const data = response.data;
        console.log('Invite info:', data);
        setInviteInfo(data);
        setFormData((prevData) => ({
          ...prevData,
          email: data.email, // Pre-fill the email from invite info
        }));
      } catch (err) {
        setError(err.response?.data?.detail || err.message);
      }
    };

    if (token) {
      fetchInviteInfo();
    } else {
      setError('Invalid invite token.');
    }
  }, [token]);

  // Password Strength Validation
  useEffect(() => {
    const { password, confirmPassword } = formData;

    // Validate password strength
    const lengthValid = password.length >= 8;
    const letterValid = /[A-Za-z]/.test(password);
    const numberValid = /[0-9]/.test(password);

    setPasswordValid({
      length: lengthValid,
      letter: letterValid,
      number: numberValid,
    });

    // Check if passwords match
    if (confirmPassword.length > 0) {
      setPasswordsMatch(password === confirmPassword);
    } else {
      setPasswordsMatch(null);
    }
  }, [formData.password, formData.confirmPassword]);

  // Field Validation for Username, First Name, Last Name
  useEffect(() => {
    const { username, first_name, last_name } = formData;

    setFieldValid({
      username: username.trim().length > 0,
      first_name: first_name.trim().length > 0,
      last_name: last_name.trim().length > 0,
    });
  }, [formData.username, formData.first_name, formData.last_name]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear password error when user starts typing
    if (name === 'password' || name === 'confirmPassword') {
      setPasswordError(null);
    }

    // Clear general error when user starts typing
    if (error) {
      setError(null);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields({
      ...touchedFields,
      [name]: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation for password confirmation
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    // Check password strength
    if (
      !passwordValid.length ||
      !passwordValid.letter ||
      !passwordValid.number
    ) {
      setPasswordError('Password does not meet the required criteria.');
      return;
    }

    // Check other required fields
    if (
      !fieldValid.username ||
      !fieldValid.first_name ||
      !fieldValid.last_name
    ) {
      setError('Please fill out all required fields.');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await axios.post(
        'https://api.njpresearch.com/api/create-user',
        {
          email: formData.email,
          username: formData.username,
          password: formData.password,
          first_name: formData.first_name,
          last_name: formData.last_name,
          invite_token: formData.invite_token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      navigate('/success');
    } catch (err) {
      setError(err.response?.data?.detail || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Helper function to render password criteria
  const renderPasswordCriteria = () => {
    return (
      <ul className="mb-3">
        <li style={{ color: passwordValid.length ? 'green' : 'red' }}>
          At least 8 characters
        </li>
        <li style={{ color: passwordValid.letter ? 'green' : 'red' }}>
          Contains at least one letter
        </li>
        <li style={{ color: passwordValid.number ? 'green' : 'red' }}>
          Contains at least one number
        </li>
      </ul>
    );
  };

  // Helper function to render password match indicator
  const renderPasswordMatch = () => {
    if (passwordsMatch === null) return null;
    return (
      <div className="mb-3">
        {passwordsMatch ? (
          <span style={{ color: 'green' }}>Passwords match</span>
        ) : (
          <span style={{ color: 'red' }}>Passwords do not match</span>
        )}
      </div>
    );
  };

  // Helper function to render field validation indicators

  if (error && !inviteInfo) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
        </Alert>
      </Container>
    );
  }

  if (!inviteInfo) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title className="mb-4 text-center">
                Create Your Account
              </Card.Title>
              {error && (
                <Alert
                  variant="danger"
                  onClose={() => setError(null)}
                  dismissible
                >
                  {error}
                </Alert>
              )}
              {passwordError && (
                <Alert
                  variant="warning"
                  onClose={() => setPasswordError(null)}
                  dismissible
                >
                  {passwordError}
                </Alert>
              )}
              <Form onSubmit={handleSubmit} noValidate>
                {/* Email Field */}
                <Form.Group as={Row} className="mb-3" controlId="email">
                  <Form.Label column sm={4}>
                    Email
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      readOnly
                      plaintext
                      isValid
                      className="bg-light"
                    />
                  </Col>
                </Form.Group>

                {/* Username Field */}
                <Form.Group as={Row} className="mb-3" controlId="username">
                  <Form.Label column sm={4}>
                    Username
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      required
                      placeholder="Enter your username"
                      isValid={fieldValid.username === true}
                      isInvalid={fieldValid.username === false}
                    />
                    {/* {renderFieldValidation('username')} */}
                  </Col>
                </Form.Group>

                {/* Password Field */}
                <Form.Group as={Row} className="mb-3" controlId="password">
                  <Form.Label column sm={4}>
                    Password
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      required
                      placeholder="Enter your password"
                      isValid={
                        passwordValid.length &&
                        passwordValid.letter &&
                        passwordValid.number
                      }
                      isInvalid={
                        formData.password.length > 0 &&
                        (!passwordValid.length ||
                          !passwordValid.letter ||
                          !passwordValid.number)
                      }
                    />
                    {renderPasswordCriteria()}
                  </Col>
                </Form.Group>

                {/* Confirm Password Field */}
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="confirmPassword"
                >
                  <Form.Label column sm={4}>
                    Confirm Password
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      required
                      placeholder="Re-enter your password"
                      isValid={passwordsMatch === true}
                      isInvalid={passwordsMatch === false}
                    />
                    {renderPasswordMatch()}
                  </Col>
                </Form.Group>

                {/* First Name Field */}
                <Form.Group as={Row} className="mb-3" controlId="first_name">
                  <Form.Label column sm={4}>
                    First Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      required
                      placeholder="Enter your first name"
                      isValid={fieldValid.first_name === true}
                      isInvalid={fieldValid.first_name === false}
                    />
                    {/* {renderFieldValidation('first_name')} */}
                  </Col>
                </Form.Group>

                {/* Last Name Field */}
                <Form.Group as={Row} className="mb-4" controlId="last_name">
                  <Form.Label column sm={4}>
                    Last Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      required
                      placeholder="Enter your last name"
                      isValid={fieldValid.last_name === true}
                      isInvalid={fieldValid.last_name === false}
                    />
                    {/* {renderFieldValidation('last_name')} */}
                  </Col>
                </Form.Group>

                {/* Submit Button */}
                <div className="d-grid">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Creating...
                      </>
                    ) : (
                      'Create Account'
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateUser;
