import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import ImageModal from './ImageModal';
import './UploadImage.css';

const ImageUpload = forwardRef(({ initialImages = [], onChange, size = 'medium', title = '', border = [], marking = false, selectedDisplay = 'none' }, ref) => {
  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [newImageFromUrl, setNewImageFromUrl] = useState(null);
  const [markedImage, setMarkedImage] = useState(null);
  console.log('initialImages:', initialImages);
  const sizeSuffix = {
    small: '_small.avif',
    medium: '_medium.avif',
    large: '_large.avif',
  };

  // This useEffect only runs once, during the initial render.
  useEffect(() => {
    const formattedImages = initialImages.map((imageUrl) => ({
      url: imageUrl + sizeSuffix[size],
      name: imageUrl.split('/').pop(),
      isNew: false,
    }));
    setExistingImages(formattedImages);

    if (marking && formattedImages.length > 0 && !markedImage) {
      setMarkedImage(formattedImages[0].name);
    }
  }, []); // Empty dependency array ensures this runs only once

  const handleImageUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    const newUploadedImages = uploadedFiles.reduce((acc, file) => {
      const fileName = file.name;
      const isDuplicate = existingImages.some(image => image.name === fileName) || newImages.some(image => image.name === fileName);

      if (!isDuplicate) {
        acc.push({
          url: URL.createObjectURL(file),
          name: fileName,
          file: file,
          isNew: true,
        });
      }

      return acc;
    }, []);

    const updatedNewImages = [...newImages, ...newUploadedImages];
    setNewImages(updatedNewImages);

    if (marking && !markedImage && updatedNewImages.length > 0) {
      const firstNewImageName = updatedNewImages[0].name;
      setMarkedImage(firstNewImageName);
      handleSubmit(existingImages, updatedNewImages, firstNewImageName);
    } else {
      handleSubmit(existingImages, updatedNewImages);
    }
  };

  const handleRemoveImage = (imageName) => {
    const updatedExistingImages = existingImages.filter((image) => image.name !== imageName);
    const updatedNewImages = newImages.filter((image) => image.name !== imageName);
    setExistingImages(updatedExistingImages);
    setNewImages(updatedNewImages);
    handleSubmit(updatedExistingImages, updatedNewImages);

    if (markedImage === imageName) {
      setMarkedImage(null);
    }
  };

  const handleMarkImage = (image) => {
    setMarkedImage(image.name);
    handleSubmit(existingImages, newImages, image.name);
  };

  const handleSubmit = (updatedExistingImages = existingImages, updatedNewImages = newImages, markedImg = markedImage) => {
    const remainingExistingLinks = updatedExistingImages.map((image) => image.url);
    const newFiles = updatedNewImages.map((image) => image.file);
    if (onChange) {
      onChange(remainingExistingLinks, newFiles, markedImg);
    }
  };

  const downloadImage = async (imageUrl) => {
    try {
        // Attempt to fetch the image directly
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const fileName = `temp_${Date.now()}.jpg`; // Temporary name
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    } catch (error) {
        // If a CORS error occurs, fetch the image through the backend
        console.error('Direct fetch failed, attempting to fetch through backend:', error);
        
        // Prepare the POST request to the backend
        const backendUrl = 'https://api.njpresearch.com/api/fetch_image_from_link';
        const response = await fetch(backendUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ url: imageUrl }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const fileName = `temp_${Date.now()}.jpg`; // Temporary name
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    }
};


  const addImageFromUrl = async (imageUrl) => {
    const file = await downloadImage(imageUrl);
    const newImage = {
      url: URL.createObjectURL(file),
      name: file.name,
      file: file,
      isNew: true,
    };
  
    let updatedNewImages;
    if (!newImageFromUrl) {
      setNewImageFromUrl(newImage);
      updatedNewImages = [...newImages, newImage];
    } else {
      // Filter out the old image from newImages
      updatedNewImages = newImages.filter(image => image.name !== newImageFromUrl.name);
      // Update newImageFromUrl with the new image
      setNewImageFromUrl(newImage);
      // Add the new image to the updatedNewImages array
      updatedNewImages = [...updatedNewImages, newImage];
    }
  
    // Update the state with the new images array
    setNewImages(updatedNewImages);
  
    // Handle marking and submission
    if (marking && !markedImage) {
      setMarkedImage(newImage.name);
      handleSubmit(existingImages, updatedNewImages, newImage.name);
    } else {
      setMarkedImage(newImage.name);
      console.log('Submitting new image marked Image:', newImage.name);
      handleSubmit(existingImages, updatedNewImages, newImage.name);
    }
  };

  useImperativeHandle(ref, () => ({
    addImageFromUrl,
  }));

  const borderStyle = border.reduce((style, side) => {
    style[`border-${side}`] = '5px solid #ccc';
    return style;
  }, { padding: '15px' });

  return (
    <Container style={borderStyle}>
      {title && <h3 className="image-upload-title">{title}</h3>}
      <Row className={`mt-3 ${selectedDisplay !== 'none' && 'selected-display'}`}>
        {selectedDisplay !== 'none' && markedImage && (
          <Col xs={12} md={6} className="mb-3">
            <div className="large-preview-container">
              <div className="large-preview">
                <img src={existingImages.find(image => image.name === markedImage)?.url || newImages.find(image => image.name === markedImage)?.url} alt="Selected Preview" />
              </div>
            </div>
          </Col>
        )}
        <Col xs={12} md={selectedDisplay !== 'none' ? 6 : 12}>
          <Row>
            {[...existingImages, ...newImages].map((image, index) => (
              <Col key={index} xs={6} sm={4} md={selectedDisplay !== 'none' ? 6 : 4} lg={selectedDisplay !== 'none' ? 4 : 3} className="mb-3">
                <div 
                  className={`image-container position-relative ${markedImage === image.name ? 'marked' : ''}`}
                >
                  {marking && (
                    <Form.Check 
                      type="checkbox"
                      className="position-absolute custom-checkbox"
                      style={{ top: '10px', left: '10px', zIndex: 2 }}
                      checked={markedImage === image.name}
                      onChange={() => handleMarkImage(image)}
                    />
                  )}
                  <ImageModal image={image} />
                  <Button
                    variant="danger"
                    size="sm"
                    className="position-absolute"
                    style={{ top: '5px', right: '5px', zIndex: 2 }}
                    onClick={() => handleRemoveImage(image.name)}
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            ))}
            <Col xs={6} sm={4} md={selectedDisplay !== 'none' ? 6 : 4} lg={selectedDisplay !== 'none' ? 4 : 3} className="mb-3">
              <div className="image-container position-relative add-image-container d-flex align-items-center justify-content-center">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  id="imageUploadInput"
                />
                <label
                  htmlFor="imageUploadInput"
                  className="w-100 h-100 d-flex align-items-center justify-content-center"
                  style={{ cursor: 'pointer', position: 'absolute', top: 0, left: 0, margin: 0 }}
                >
                  <div className="plus-icon">+</div>
                </label>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
});

export default ImageUpload;