import React from 'react';
import { Row, Col, Badge, Tab } from 'react-bootstrap';
import SearchResultCard from './SearchResultCard';

const KeepaResultsTab = ({ keepaResults, handleShowModal, Scrapegraph }) => (
    <Row className='p-2'>
      <Col xs={6}  md = {3}  lg={2}>
        <SearchResultCard
          key={0}
          item={keepaResults}
          imageUrl={keepaResults.image_url}
          onUse={Scrapegraph}
          onPreview={() => handleShowModal(0, keepaResults)}
        />
      </Col>
    </Row>
);

export default KeepaResultsTab;