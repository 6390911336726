// barcodePatterns.js
export const barcodePatterns = [
  {
    name: 'ASIN',
    regex: /^B0\w{8}$/, // Starts with "B0" and is 10 characters long
  },
  {
    name: 'UPC',
    regex: /^\d{12}$/, // 12 digits
  },
  {
    name: 'EAN',
    regex: /^\d{13}$/, // 13 digits
  },
  {
    name: 'FNSKU',
    regex: /^X0[A-Z0-9]{8}$/, // Starts with "X0" and is 10 alphanumeric characters
  },
  {
    name: 'INTCODE',
    regex: /^NP\w{6}$/, // Starts with "NP" and is 8 characters long
  },
  {
    name: 'ORIGIN_CODE',
    regex: /^OG\w{5}$/, // Starts with "OG" and is 7 characters long
  },
  // Add more patterns as needed
];
