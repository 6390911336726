import React, { useState, useEffect } from 'react';
import { Button, Table, Container } from 'react-bootstrap';
import Axios from 'axios';
import ShipmentForm from './ShipmentForm.js';

const Shipments = ({ token }) => {
    const [shipments, setShipments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentShipment, setCurrentShipment] = useState(null);

    useEffect(() => {
        fetchShipments();
    }, []);

    const fetchShipments = () => {
        Axios.get('https://api.njpresearch.com/api/fetch_shipments', {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => setShipments(response.data))
        .catch(error => console.error('Error fetching shipments:', error));
    };

    const handleEdit = (shipment) => {
        setCurrentShipment(shipment);
        setShowModal(true);
    };

    const handleAdd = () => {
        setCurrentShipment(null);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const deleteShipment = (shipmentId) => {
        if (window.confirm("Are you sure you want to delete this shipment?")) {
            Axios.delete(`https://api.njpresearch.com/api/delete_shipments/${shipmentId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(() => {
                alert('Shipment deleted successfully!');
                fetchShipments();  // Refresh the list after deletion
            })
            .catch(error => {
                console.error('Error deleting shipment:', error.response.data);
                alert('Error deleting shipment: ' + (error.response.data.detail || error.message));
            });
        }
    };
    

    return (
        <Container>
            <h1>Shipments List</h1>
            <Button variant="primary" onClick={handleAdd}>Add Shipment</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Shipment ID</th>
                        <th>Article Number</th>
                        <th>Purchase Date</th>
                        <th>Receiving Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {shipments.map(shipment => (
                        <tr key={shipment.id}>
                            <td>{shipment.id}</td>
                            <td>{shipment.expected_article_number}</td>
                            <td>{shipment.purchase_date}</td>
                            <td>{shipment.receiving_date}</td>
                            <td>{shipment.status}</td>
                            <td>
                                <Button variant="info" onClick={() => handleEdit(shipment)}>Edit</Button>{' '}
                                <Button variant="danger" onClick={() => deleteShipment(shipment.id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ShipmentForm show={showModal} handleClose={handleClose} shipment={currentShipment} refreshList={fetchShipments} token={token}/>
        </Container>
    );
    
};

export default Shipments;
