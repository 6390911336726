import React, { useEffect, useState, useCallback,useRef  } from 'react';
import { Container, Row, Col, Form, Button, Image , InputGroup} from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import BarcodeScannerButton from './camera_barcode_reader/BarcodeScannerButton';
import ImageUpload from './ImageUpload'; // Import the ImageUpload component
import StringBubbles from './StringBubbles';
import ItemFillTool from './ItemFillTool';
import axios from 'axios';


const categories = {
  "Antiques & Collectables": ["Collectables", "Antiques"],
  "Arts and Crafts": ["Craft Supplies", "Craft Machines", "Sewing Machines"],
  "Music": ["Vinyl Records", "Musical Instruments", "Music Accessories"],
  "Video Gaming": ["Video Games", "Gaming Consoles", "Accessories and Components"],
  "Media": ["DVD/Blu-Ray", "Books"],
  "Home Furniture and Patio": ["Dining Room", "Living Room", "Bedroom Furniture", "Patio Furiture", "Bedroom Items"],
  "Adults": ["Adult Product", "Adult Toys"],
  "Automobiles": ["Cars and Vehicles", "Electrical Scooters & Bicycles", "Boats & Boat's Motors", "Skidoo and Jet Skis"],
  "Office Producs": ["Office Furniture", "Printers, Phones and Office Equipment", "Stationary Supplies"],
  "Food and Grocery": ["Food and Grocery"],
  "Toys & Games": ["Toys & Games"],
  "Beauty & Personal Care": ["Health & Personal Care", "Beauty"],
  "Industrial and Medical": ["Medical", "Industrial"],
  "Personal Accessories": ["Wallets & Bags", "Watches", "Jewellery", "Glasses and Frames"],
  "Automotive": ["Parts & Accessories"],
  "Baby Products": ["Baby Supplies", "Car Seats & Strollers", "Baby Furniture", "Baby Food"],
  "Tools and Home Improvement": ["Hand Tools", "Powered Tools", "Home Improvement", "Accessories & Parts"],
  "Shoes": ["Men's Shoes", "Women's Shoes", "Unisex Shoes", "Baby's & Kids Shoes"],
  "Liquidation": ["Liquidation Pallets", "Liquidation Lots"],
  "Pets": ["Pet Furniture", "Pet Supplies"],
  "Fitness and Gym Equipment": ["Weights and Dumbbells", "Treadmills, Cycling & Rowing", "Workout Stations"],
  "Appliances": ["Home Appliances", "Kitchen Appliances", "Major Appliances"],
  "Clothing": ["Men's Clothing", "Unisex Clothing", "Women's Clothing", "Baby and Kids Clothing"],
  "Home & Kitchen": ["Home Decor", "Bathroom", "Kitchen Items", "Cleaning"],
  "Electronics": ["Speakers, Headphone & Earbuds", "Laptops and Computers", "Electronic Accessories", "Smart Watches", "Tablets and Cell Phones", "TV's and Monitors", "Cameras and Lenses", "Parts and Components"],
  "Sports & Outdoors": ["Outdoor", "Sports"]
};

const ListingConfirmationPage = ({ token }) => {
  const imageUploadRef = useRef();
  const { itemId } = useParams();
  const [itemData, setItemData] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    price: 0,
    height: 0,
    width: 0,
    length: 0,
    fnsku: '',
    upc: '',
    ean: '',
    asin: '',
    link: '',
    category: '',
    subcategory: '',
    weight: 0,
    intcode: '',
    brand: '',
    bullets: [],
    description: '',
    extra_barcodes: '',
    condition: '',
    notes: '',
    origin_code: '',
    location: '',
    remainingImages: [], // No initial images
    newImages: [], // Files of new images to upload
    markedImage: '',
    imageLabel: false,
    imageLabelText: '',
    imageLabelPosition: '',
    imageLabelSize: '',
    added_by: '',
    
  });
  const [isVerified, setIsVerified] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showImageLabels, setShowImageLabels] = useState(false);
  const navigate = useNavigate();
  const [FillItemImage, setFillItemImage] = useState(null);

  useEffect(() => {
    const apiUrl = `https://api.njpresearch.com/api/scanned/${itemId}`;

    const fetchItemData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        console.log('Fetched data:', data);
        if (data) {
          if (data.image_url) {
            if (data.image_url.includes('bdwarehouse')) { // Use .includes() to check for substring
              data.image_url = data.image_url + '_medium.avif';
            }
          }


          
          setItemData(data);
          setFormData({
            intcode: data.intcode || '',
            title: data.title || '',
            price: data.price || 0,
            height: data.height || 0,
            width: data.width || 0,
            length: data.length || 0,
            fnsku: data.fnsku || '',
            upc: JSON.parse(data.upc)[0] || '',
            ean: JSON.parse(data.ean)[0] || '',
            asin: data.asin || '',
            link: data.link || '',
            category: data.category || '',
            subcategory: data.subcategory || '',
            weight: data.weight || 0,
            brand: data.brand || '',
            bullets: JSON.parse(data.bullets) || [],
            description: data.description || '',
            extra_barcodes: data.extra_barcodes || [],
            condition: data.condition || '',
            notes: data.notes || '',
            origin_code: data.origin_code || '',
            remainingImages: JSON.parse(data.images), // No initial images
            newImages: [], // Files of new images to upload
            markedImage: '',
            imageLabel: false,
            imageLabelText: '',
            imageLabelPosition: '',
            imageLabelSize: '',
            added_by: data.added_by || '',
          });

          setTimeout(() => {
            if (data.image_url) {
            handleAddImage(data.image_url);
            }
          }, 1000);
        } else {
          console.error('No data found in response:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchItemData();
  }, [itemId, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  


  const handleImageChange = (remainingLinks, newFiles, markedImage) => {
    console.log('Remaining Existing Images:', remainingLinks);
    console.log('New Images to Upload:', newFiles);
    console.log('Marked Image:', markedImage);

    // Update form data with image info
    setFormData({
      ...formData,
      remainingImages: remainingLinks,
      newImages: newFiles,
      markedImage: markedImage,
    });
  };

  const handleBarcodeDetected = (targetId, barcode) => {
    setFormData((prevData) => ({
      ...prevData,
      [targetId]: barcode,
    }));
  };



  const handleAmazonData = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: data.title || '',
      price: data.price || 0,
      brand: data.brand || '',
      bullets: data.bullets || [],
      height: data.dimensions?.height || prevFormData.height,
      width: data.dimensions?.width || prevFormData.width,
      length: data.dimensions?.length || prevFormData.length,
      weight: data.dimensions?.weight || prevFormData.weight,
      description: data.description || '',
      link: data.link || '',
      fnsku: prevFormData.fnsku,
      upc: prevFormData.upc,
      ean: prevFormData.ean,
      asin: data.asin || '',
      extra_barcodes: '',
      condition: '',
      remainingImages: [], // Reset images
      newImages: [], // Reset new images
      markedImage: '',
      imageLabelText: prevFormData.imageLabelText,
      imageLabelPosition: prevFormData.imageLabelPosition,
      imageLabelSize: prevFormData.imageLabelSize,
      added_by: '',
    }));
    setTimeout(() => {
      handleAddImage(data.image_url);
    }, 1000);
  };
  
  const handleScrapegraphData = (data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: data.title || '',
      price: data.price || 0,
      brand: data.brand || '',
      link: data.link || '',
      description: data.description || '',
      fnsku: prevFormData.fnsku,
      upc: prevFormData.upc,
      ean: prevFormData.ean,
      asin: prevFormData.asin,
      bullets: [],
      extra_barcodes: '',
      condition: prevFormData.condition,
      remainingImages: [], // Reset images
      newImages: [], // Reset new images
      markedImage: '',
      imageLabelText: prevFormData.imageLabelText,
      imageLabelPosition: prevFormData.imageLabelPosition,
      imageLabelSize: prevFormData.imageLabelSize,
      added_by: prevFormData.added_by,
    }));
    setTimeout(() => {
      handleAddImage(data.image_url);
    }, 1000);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory,
      subcategory: '' // Reset subcategory when category changes
    }));
  };
  
  const handleUpdateBubbles = (bullets) => {
    setFormData((prevFormData) => {
        if (JSON.stringify(prevFormData.bullets) !== JSON.stringify(bullets)) {
            return {
                ...prevFormData,
                bullets
            };
        }
        return prevFormData;
    });
};


const handleAddImage = (imageUrl) => {
  imageUploadRef.current.addImageFromUrl(imageUrl);

};



const handleImageLabelToggle = (e) => {
  const isChecked = e.target.checked;
  setShowImageLabels(isChecked);
  setFormData((prevFormData) => ({
    ...prevFormData,
    imageLabel: isChecked
  }));
};

const handleSubmit = async (event) => {
  event.preventDefault();
  const form = event.currentTarget;
  if (form.checkValidity() === false || !isVerified || !formData.category) {
    event.stopPropagation();
    if (!isVerified) {
      alert('Please verify the information before submitting.');
    }
    if (!formData.category) {
      alert('Please select a category.');
    }
  } else {
    console.log('Form data:', formData);

    const apiUrl = 'https://api.njpresearch.com/api/confirm_item_listing';
    const data = new FormData();

    // Create a JSON object for the form data
    const jsonData = {};
    const dimensions = {};

    for (const key in formData) {
      console.log(key);
      if (key === 'newImages') {
        // Append new images to FormData
        formData.newImages.forEach(file => {
          data.append('newImages', file); // Ensure the field name matches the backend
        });
      } else if (key === 'bullets') {
        jsonData[key] = JSON.stringify(formData[key]); // Convert array to JSON string
      } else if (key === 'imageLabel' && formData[key]) {
        // Construct the image_label dictionary only if imageLabelText is not empty
        if (formData.imageLabelText) {
          jsonData[key] = {
            imageLabelText: formData.imageLabelText,
            imageLabelPosition: formData.imageLabelPosition || 'top-right',
            imageLabelSize: formData.imageLabelSize || 'medium'
          };
        }
      } else if (key === 'imageLabelText' || key === 'imageLabelPosition' || key === 'imageLabelSize') {
        // Skip these fields
      } else if (key === 'remainingImages') {
        const updatedRemainingImages = formData.remainingImages.map(image => {
          const updatedImage = image.replace('_medium.avif', '');
          return updatedImage;
        });
        jsonData['images'] = updatedRemainingImages;
      } else if (key === 'height' || key === 'weight' || key === 'length' || key === 'width') {
        // Add dimensions to the dimensions dictionary
        dimensions[key] = formData[key];
      } else {
        jsonData[key] = formData[key];
      }
    }

    // Add dimensions dictionary to jsonData if it has any entries
    if (Object.keys(dimensions).length > 0) {
      jsonData['dimensions'] = dimensions;
    }

    // Append the JSON object to FormData
    data.append('item_data', JSON.stringify(jsonData)); // Ensure the field name matches the backend

    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert('Item confirmed successfully!');
        navigate('/item-search');
      } else {
        alert('Failed to confirm item.');
      }
    } catch (error) {
      console.error('Error confirming item:', error);
      alert('An error occurred. Please try again.');
    }
  }

  setValidated(true);
};
  


  if (!itemData) {
    return <div>Loading...</div>;
  }

  return (
    
    <Container fluid className="py-3 min-vh-100 d-flex align-items-center justify-content-center ">
      <ItemFillTool jsonFile={itemData} onAmazonData={handleAmazonData} onScrapegraphData={handleScrapegraphData} />
      <div className="frosted-glass p-3 rounded shadow-lg" style={{ maxWidth: '1200px', width: '100%' }}>
        <ImageUpload 
          ref={imageUploadRef}
          selectedDisplay = 'left'
          initialImages={formData.remainingImages} // No initial images
          onChange={handleImageChange} 
          border={['top', 'bottom']} 
          marking={true} 
        />

      <Form noValidate validated={validated} onSubmit={handleSubmit}>


        <Form.Group className="mb-3" controlId="addImageLabelSwitch">
            <Form.Check 
            type="switch" 
            label="Add Image Label" 
            checked={showImageLabels}
            onChange={handleImageLabelToggle}
            />
          </Form.Group>

          {showImageLabels && (
            <Row className='mt-3 mb-3' name='ImageLabels' style={{  borderRadius: '10px' }}>
            <Col sm={4}>
              <Form.Group className="mb-3">
              <Form.Label><strong>Image Label</strong></Form.Label>
              <Form.Control
                type="text"
                name="imageLabelText"
                value={formData.imageLabelText}
                onChange={handleInputChange}
              />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
              <Form.Label><strong>Image Label Position</strong></Form.Label>
              <Form.Control
                as="select"
                name="imageLabelPosition"
                value={formData.imageLabelPosition}
                onChange={handleInputChange}
              >
                <option value="">Select position</option>
                <option value="top-left">Top Left</option>
                <option value="top-right">Top Right</option>
                <option value="bottom-left">Bottom Left</option>
                <option value="bottom-right">Bottom Right</option>
              </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
              <Form.Label><strong>Image Label Size</strong></Form.Label>
              <Form.Control
                as="select"
                name="imageLabelSize"
                value={formData.imageLabelSize}
                onChange={handleInputChange}
              >
                <option value="">Select size</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </Form.Control>
              </Form.Group>
            </Col>
            </Row>
          )}

          
          <Row className='mt-3 mb-3 CYellowBg ' name='Codes' style={{ borderRadius: '10px' }}>
            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="intcode">
                <Form.Label><strong>Intcode</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    name="intcode"
                    value={formData.intcode}
                    onChange={handleInputChange}
                    id="intcode"
                  />
                  <BarcodeScannerButton targetId="intcode" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="origin_code">
                <Form.Label><strong>Origin Code</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    name="origin_code"
                    value={formData.origin_code}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="origin_code" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="asin">
                <Form.Label><strong>ASIN</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="asin"
                    value={formData.asin}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="asin" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="fnsku">
                <Form.Label><strong>FNSKU</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="fnsku"
                    value={formData.fnsku}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="fnsku" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="upc">
                <Form.Label><strong>UPC</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="upc"
                    value={formData.upc}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="upc" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={12} md={6} lg={4}>
              <Form.Group className="mb-3" controlId="ean">
                <Form.Label><strong>EAN</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="ean"
                    value={formData.ean}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="ean" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>


          <Row className='mt-3 mb-3 CBlueBg' name='ItemPrimary' style={{ borderRadius: '10px' }}>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label><strong className='CYellowText'>Title</strong></Form.Label>
                <Form.Control 
                  required
                  as="textarea" 
                  rows={3} 
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange} 
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Price</strong></Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Brand</strong></Form.Label>
                <Form.Control
                  type="text"
                  name="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Link</strong></Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={formData.link}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>



          <Row className='mt-3 mb-3 CYellowBg' name='ItemExtras' style={{ borderRadius: '10px' }}>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CBlueText'>Description</strong></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CBlueText'>Bullets</strong></Form.Label>
                  <StringBubbles
                    initialStrings={formData.bullets}
                    onBubblesChange={handleUpdateBubbles}
                    bubbleBackgroundColor="#6223c8"
                    deleteButtonColor="#6223c8"
                    deleteButtonHoverColor="#ff4500"
                    addButtonColor="white"
                    addButtonText="Add New Bubble"
                    textColor='white'
                  />
              </Form.Group>
            </Col>

            {/* <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>Extra Barcodes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="extra_barcodes"
                  value={formData.extra_barcodes.join('\n')}
                  onChange={(e) => {
                    const extra_barcodes = e.target.value.split('\n');
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      extra_barcodes
                    }));
                  }}
                />
              </Form.Group>
            </Col> */}

            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CBlueText'>Extra Notes</strong></Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>


          <Row className='mt-3 mb-3' name='ItemExtras' style={{ borderRadius: '10px' }}>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Height</strong></Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="height"
                  value={formData.height}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Width</strong></Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="width"
                  value={formData.width}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Length</strong></Form.Label>
                <Form.Control
                  required
                  type="number"
                  name="length"
                  value={formData.length}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                  <Form.Label><strong className='CYellowText'>Weight</strong></Form.Label>
                  <Form.Control
                      required
                      type="number"
                      name="weight"
                      value={formData.weight}
                      onChange={handleInputChange}
                  />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Condition</strong></Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="condition"
                  value={formData.condition}
                  onChange={handleInputChange}
                >
                  <option value="">Select condition</option>
                  <option value="New">New</option>
                  <option value="Open">Open Box</option>
                  <option value="Refurbrished">Refurbrished</option>
                  <option value="Used">Used</option>
                  <option value="Asis">As-is</option>
                </Form.Control>
              </Form.Group>
            </Col>
            

            <Col sm={4}>
            <Form.Group className="mb-3">
              <Form.Label><strong className='CYellowText'>Category</strong></Form.Label>
              <Form.Control
                required
                as="select"
                name="category"
                value={formData.category}
                onChange={handleCategoryChange}
              >
                <option value="">Select a category</option>
                {Object.keys(categories).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            </Col>
            <Col sm={4}>
            {formData.category && (
              <Form.Group className="mb-3">
                <Form.Label><strong className='CYellowText'>Subcategory</strong></Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="subcategory"
                  value={formData.subcategory}
                  onChange={handleInputChange}
                >
                  <option value="">Select a subcategory</option>
                  {categories[formData.category].map((subcategory) => (
                    <option key={subcategory} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3" controlId="location">
                <Form.Label><strong className='CYellowText'>Location</strong></Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton targetId="location" readers={['upc_reader', 'ean_reader', 'code_128_reader']} onBarcodeDetected={handleBarcodeDetected} />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        

        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check 
            type="checkbox" 
            label="I have verified that the information is correct" 
            checked={isVerified}
            onChange={(e) => setIsVerified(e.target.checked)}
          />
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button className="animated-button btn-purple" style={{ width: '50%' }} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
      <div style={{ height: '100px' }}></div>
      </div>
    </Container>
  );
};

export default ListingConfirmationPage;
