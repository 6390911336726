// NavbarExample.js

import React from 'react';
import { Container, Navbar, Button } from 'react-bootstrap';

function NavbarExample({ handleShow }) {
  return (
    <>
      {/* Top Navbar */}
      <Navbar fixed="top" style={{ background: 'linear-gradient(to right, #e2e2e2, #e2e2e2)' }}>
        <Container fluid>
          {/* Hamburger Button for Small Screens */}
          <Button
            variant="outline-secondary"
            className="d-lg-none me-2"
            onClick={handleShow}
          >
            <span className="navbar-toggler-icon" />
          </Button>
          <Navbar.Brand href="/user">Tester Site</Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarExample;
