import React from 'react';
import { Badge, Tab, Row, Col } from 'react-bootstrap';
import SearchResultCard from './SearchResultCard';
import { getImageLink } from './utils';

const DatabaseResultsTab = ({ databaseResults, handleShowModal, Scrapegraph }) => (
  <Row className='p-2 tab-content'>
      {databaseResults.map((result, idx) => (
        result.image_url = getImageLink(result),
        <Col xs={6}  md = {3}  lg={2}>
        <SearchResultCard
          key={idx}
          item={result}
          imageUrl={result.image_url}
          onUse={Scrapegraph}
          onPreview={() => handleShowModal(idx, result)}
        />
      </Col>
      ))}
    </Row>
);

export default DatabaseResultsTab;