import React from 'react';
import { Card, Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import americanFlag from './img/americanFlag.webp';
import canadianFlag from './img/canadianFlag.webp';
import './SearchResultCard.css';

const SearchResultCard = ({ item, imageUrl, onUse, onExpand, onPreview, showExpandButton = false, showUseButton = false, isLoading = false, expanded = false, used = false, overwriteUse = false }) => {
  const domainName = item?.link ? new URL(item.link).hostname : '';
  const isAmazonCom = item?.link?.includes('amazon.com');
  const isAmazonCa = item?.link?.includes('amazon.ca');

  return (
    <Card className={`search-result-card mb-3 ${expanded ? 'expanded' : ''} ${used ? 'used' : ''}`} onClick={onPreview} style={{ cursor: 'pointer', position: 'relative' }}>
      <Card.Body>
        {imageUrl && (
          <div className="search-result-card-image-container">
            <Card.Img variant="top" src={imageUrl} className="search-result-card-image-fit" />
          </div>
        )}
        <Card.Title className="search-result-card-title">
          {item.title}
        </Card.Title>
        <Button 
          variant="primary" 
          href={item?.link} 
          target="_blank" 
          rel="noopener noreferrer" 
          onClick={(e) => e.stopPropagation()}
          style={{ width: '100%' }}
          className="mt-1"
        >
          Check on {domainName}
        </Button>
        {showExpandButton && !item.expanded && (
          <Button
            variant="info"
            onClick={(e) => {
              e.stopPropagation();
              onExpand(item.link);
            }}
            style={{ width: '100%' }}
            className="mt-1"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> Loading...
              </>
            ) : (
              'Expand'
            )}
          </Button>
        )}
        {(item.expanded || overwriteUse) && (
          <Button 
            variant="secondary" 
            onClick={(e) => {
              e.stopPropagation();
              onUse(item.link);
            }} 
            style={{ width: '100%' }}
            className="mt-1"
          >
            Use
          </Button>
        )}
        {isAmazonCom && (
          <img src={americanFlag} alt="American Flag" className="search-result-card-flag-icon" />
        )}
        {isAmazonCa && (
          <img src={canadianFlag} alt="Canadian Flag" className="search-result-card-flag-icon" />
        )}
      </Card.Body>
    </Card>
  );
};

SearchResultCard.propTypes = {
  item: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  onUse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  showExpandButton: PropTypes.bool,
  showUseButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  expanded: PropTypes.bool,
  used: PropTypes.bool,
  overwriteUse: PropTypes.bool, // Add the new prop type
};

export default SearchResultCard;