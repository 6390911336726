import React from 'react';
import { Modal, Button, Image, ListGroup, Col, Row, Container } from 'react-bootstrap';

const ItemPreviewModal = ({ show, handleClose, item }) => {
  const bullets = (typeof item?.bullets === 'string') ? JSON.parse(item.bullets) : (item?.bullets || []);
  const dimensions = (typeof item?.dimensions === 'string') ? JSON.parse(item.dimensions) : (item?.dimensions || {});
  const domainName = item?.link ? new URL(item.link).hostname : '';

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Item Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', overflow: 'hidden' }}>
                <Image src={item?.image_url} alt={item?.title} style={{ maxHeight: '100%', maxWidth: '100%' }} fluid />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <h6 className="mt-3">{item?.title}</h6>
              <p className="mb-1"><strong>Price:</strong> {item?.price}</p>
              <p className="mb-1"><strong>Brand:</strong> {item?.brand}</p>
              <p className="mb-1"><strong>Dimensions:</strong> {dimensions?.length && `${dimensions.length} x `}{dimensions?.width && `${dimensions.width} x `}{dimensions?.height && `${dimensions.height}`} mm</p>
              <p className="mb-1"><strong>Weight:</strong> {dimensions?.weight} g</p>
              <Button variant="primary" href={item?.link} target="_blank" className="mt-3">
                Check on {domainName}
              </Button>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12} md={6}>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <p><strong>Description:</strong> {item?.description}</p>
              <ListGroup className="mt-3 w-100">
                {bullets.map((bullet, idx) => (
                  <ListGroup.Item key={idx} style={{ fontSize: '0.9rem' }}>{bullet}</ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemPreviewModal;