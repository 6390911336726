import React, { useState, useEffect } from 'react';
import { Button, Offcanvas, Tabs, Tab, Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import ItemPreviewModal from './ItemPreviewModal';
import AmazonResultsTab from './AmazonResultsTab';
import KeepaResultsTab from './KeepaResultsTab';
import GoogleResultsTab from './GoogleResultsTab';
import DatabaseResultsTab from './DatabaseResultsTab';
import SearchTab from './SearchResultsTab';
import { getImageLink } from './utils'; // Assuming you have moved the getImageLink function to a utils file
import './ItemFillTool.css';

const ItemFillTool = ({ jsonFile, onAmazonData, onScrapegraphData }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [amazonResults, setAmazonResults] = useState([]); // State to store amazon results
  const [keepaResults, setKeepaResults] = useState({});
  const [googleResults, setGoogleResults] = useState({ valid_results: [], sponsored_results: [] });
  const [databaseResults, setDatabaseResults] = useState([]);
  const [usedItemLink, setUsedItemLink] = useState(null); // State to track the used item
  const [searchBarResults, setSearchBarResults] = useState([]);
  const [activeTab, setActiveTab] = useState('search'); // State to manage the active tab
  
  // Parse the amazon_results, keepa_results, and google_results strings into JSON objects
  useEffect(() => {
    if (jsonFile) {
      setAmazonResults(jsonFile.amazon_results ? JSON.parse(jsonFile.amazon_results).map(item => ({ ...item, expanded: false, loading: false })) : []);
      setKeepaResults(jsonFile.keepa_results ? JSON.parse(jsonFile.keepa_results) : {});
      setGoogleResults(jsonFile.google_results ? JSON.parse(jsonFile.google_results) : { valid_results: [], sponsored_results: [] });
      setDatabaseResults(jsonFile.database_results ? JSON.parse(jsonFile.database_results) : []);
    }
  }, [jsonFile]);

  // Combine all results into a single array for modal management
  const allResults = [...amazonResults, ...Object.values(keepaResults), ...databaseResults, ...searchBarResults];

  // Update modalVisibility and selectedItems based on the length of allResults
  useEffect(() => {
    setModalVisibility(Array(allResults.length).fill(false));
    setSelectedItems(Array(allResults.length).fill({}));
  }, [allResults.length]);

  if (!jsonFile) {
    return null; // Render nothing if jsonFile is null
  }

  const handleButtonClick = () => {
    setShowSidebar(true);
  };

  const handleClose = () => {
    setShowSidebar(false);
  };

  const handleShowModal = (index, item) => {
    const newVisibility = [...modalVisibility];
    const newSelectedItems = [...selectedItems];
    newVisibility[index] = true;
    newSelectedItems[index] = item;
    setModalVisibility(newVisibility);
    setSelectedItems(newSelectedItems);
  };

  const handleCloseModal = (index) => {
    const newVisibility = [...modalVisibility];
    newVisibility[index] = false;
    setModalVisibility(newVisibility);
  };

  const expandAmazonItem = async (link) => {
    try {
      const response = await axios.get('https://api.njpresearch.com/api/amazon_link_search', {
        params: { link }
      });
      console.log('API call successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  };

  const handleExpand = async (link) => {
    setLoading(true);
    const updatedAmazonResults = amazonResults.map(item => 
      item.link === link ? { ...item, loading: true } : item
    );
    setAmazonResults(updatedAmazonResults); // Update the state with the modified array
    try {
      const data = await expandAmazonItem(link);
      const updatedAmazonResults = amazonResults.map(item => 
        item.link === link ? { ...item, ...data, expanded: true, loading: false } : item
      );
      setAmazonResults(updatedAmazonResults); // Update the state with the modified array
    } catch (error) {
      console.error('Failed to expand item:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchBar = async (link, type) => {
    setLoading(true);
    try {
      if (type === 'aca' || type === 'acom') {
        const searchbar_result = await expandAmazonItem(link);
        console.log('Search bar result:', searchbar_result);
        console.log('type of result:', typeof searchbar_result);
        setSearchBarResults(searchBarResults.concat(searchbar_result));
      } else {
        await Scrapegraph(link);
      }
    } catch (error) {
      console.error('Failed to handle search bar:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUse = (result) => {
    setUsedItemLink(result.link); // Set the used item link
    onAmazonData(result); // Call the callback with the selected item
  };

  const handleExpandAndUse = async (link) => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.njpresearch.com/api/amazon_link_search', {
        params: { link }
      });
      console.log('API call successful:', response.data);
      onAmazonData(response.data); // Call the callback with the fetched data
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const Scrapegraph = async (scrape_link) => {
    setLoading(true);
    if (scrape_link.includes('amazon') || scrape_link.includes('amazon.ca')) {
      await handleExpandAndUse(scrape_link);
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get('https://api.njpresearch.com/api/scrape_web', {
        params: { scrape_link },
      });
      console.log('API call successful:', response.data);
      response.data.link = scrape_link;
      if (response.data.original_price) {
        if (response.data.original_price > response.data.price) {
          response.data.current_price = response.data.price;
          response.data.price = response.data.original_price;
        }
      }

      // Add the returned data to the searchBarResults state
      setSearchBarResults(prevResults => [...prevResults, response.data]);

      // Set the active tab to 'search'
      setActiveTab('search');
    } catch (error) {
      console.error('API call failed:', error);
    } finally {
      setLoading(false);
    }
  };

  console.log('amazonResults:', amazonResults);
  console.log('keepaResults:', keepaResults);
  console.log('googleResults:', googleResults);
  console.log('databaseResults:', databaseResults);

  // Determine the default active tab
  let defaultActiveKey = 'search';
  if (amazonResults.length > 0) {
    defaultActiveKey = 'amazon';
  } else if (Object.keys(keepaResults).length > 0) {
    defaultActiveKey = 'keepa';
  }

  return (
    <>
      <Button className="fixed-bottom-button" onClick={handleButtonClick}>Open Sidebar</Button>

      <Offcanvas show={showSidebar} onHide={handleClose} placement="bottom" style={{ height: '80vh', backgroundColor:'lightgrey'}}>
        <Offcanvas.Header closeButton style={{height: "7vh"}}>
          <Offcanvas.Title>Item Fill Tool</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0' >
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="result-tabs" justify style={{height: "5vh", zIndex:"900", borderColor:'#EFF1F3'}}>
          <Tab eventKey="search" title="Search" style={{height: "calc(68vh - 1px)"}}>
            <SearchTab 
                handleSearchBar={handleSearchBar} 
                searchBarResults={searchBarResults} 
                handleUse={handleUse} 
                handleShowModal={handleShowModal} 
                usedItemLink={usedItemLink} 
                isLoading={loading} // Pass loading state
              />
            </Tab>
            {amazonResults.length > 0 && (
              <Tab eventKey="amazon" style={{height: "calc(68vh - 1px)", overflow:'auto'}} title={<><span>Amazon</span> <Badge className='custom-badge-bg'>{amazonResults.length}</Badge></>}>
              <AmazonResultsTab
                amazonResults={amazonResults}
                handleExpand={handleExpand}
                handleUse={handleUse}
                handleShowModal={handleShowModal}
                usedItemLink={usedItemLink}
              />
            </Tab>
            )}
            {Object.keys(keepaResults).length > 0 && (
            <Tab eventKey="keepa" title={<><span>Keepa</span> <Badge className='custom-badge-bg'>1</Badge></>}>
              <KeepaResultsTab
                keepaResults={keepaResults}
                handleShowModal={handleShowModal}
                Scrapegraph={Scrapegraph}
              />
            </Tab>
            )}
            {googleResults.valid_results.length > 0 && (
            <Tab eventKey="google" style={{height: "calc(68vh - 1px)", overflow:'auto'}} title={<><span>Google</span> <Badge className='custom-badge-bg'>{googleResults.valid_results.length + googleResults.sponsored_results.length}</Badge></>}>
              <GoogleResultsTab
                googleResults={googleResults}
                handleShowModal={handleShowModal}
                Scrapegraph={Scrapegraph}
              />
            </Tab>
            )}
            {databaseResults.length > 0 && (
            <Tab eventKey="database" title={<><span>Database</span> <Badge className='custom-badge-bg'>{databaseResults.length}</Badge></>}>
              <DatabaseResultsTab databaseResults={databaseResults} />
            </Tab>
            )}
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>

      {allResults.map((item, index) => (
        <ItemPreviewModal
          key={index}
          show={modalVisibility[index]}
          handleClose={() => handleCloseModal(index)} // Ensure handleClose is passed correctly
          item={selectedItems[index]}
          usedItemLink={usedItemLink}
        />
      ))}
    </>
  );
};

ItemFillTool.propTypes = {
  jsonFile: PropTypes.object,
  onAmazonData: PropTypes.func.isRequired,
  onScrapegraphData: PropTypes.func.isRequired,
};

export default ItemFillTool;