import React, { useState } from 'react';
import { Modal, Button, ListGroup, Col, Row, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MyCoolImageCarousel from './MyCoolImageCarousel';

const InventoryItemModal = ({ show, handleClose, item, auctions, updateDestination }) => {
  const navigate = useNavigate();
  const [selectedAuction, setSelectedAuction] = useState('');  // Track the selected auction

  console.log(item);
  console.log(auctions);

  const images = (typeof item?.images === 'string') ? JSON.parse(item.images) : (item?.images || []);
  const validImages = images.filter(image => image); // Filter out empty or invalid images
  const dimensions = (typeof item?.dimensions === 'string') ? JSON.parse(item.dimensions) : (item?.dimensions || {});
  const bullets = (typeof item?.bullets === 'string') ? JSON.parse(item.bullets) : (item?.bullets || []);
  const imageLabel = (typeof item?.image_label === 'string') ? JSON.parse(item.image_label) : (item?.image_label || []);
  const domainName = item?.link ? new URL(item.link).hostname.replace(/^www\./, '') : '';

  const getFieldValue = (field) => field ? field : 'N/A';

  const formatDate = (datetime) => {
    if (!datetime) return 'N/A';
    const date = new Date(datetime);
    return date.toLocaleDateString();
  };

  const formatTime = (datetime) => {
    if (!datetime) return 'N/A';
    const date = new Date(datetime);
    return date.toLocaleTimeString();
  };

  const handleEditClick = () => {
    navigate(`/edit-inventory-item/${item.intcode}`);
  };

  const handleUpdateClick = () => {
    const itemId = item.id;
    updateDestination([itemId], selectedAuction);  // Pass the item's ID and selected auction to the function
    handleClose();  // Optionally close the modal after updating
  };
  
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton />
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6}>
            <MyCoolImageCarousel 
              images={validImages}
              imageLabels={imageLabel}
              size='medium'
              previewPosition='top'
            />
            </Col>
            <Col xs={12} md={6}>
              {item?.title && <h6 className="mt-3">{item.title}</h6>}
              <p className="mb-1"><strong>Intcode:</strong> {getFieldValue(item?.intcode)}</p>
              <p className="mb-1"><strong>Origin Code:</strong> {getFieldValue(item?.origin_code)}</p>
              <p className="mb-1"><strong>Dimensions:</strong> 
                {dimensions.length && `${dimensions.length} x `}{dimensions.width && `${dimensions.width} x `}{dimensions.height && `${dimensions.height}`} mm
              </p>
              <p className="mb-1"><strong>Condition:</strong> {getFieldValue(item?.item_condition)}</p>
              <p className="mb-1"><strong>Location:</strong> {getFieldValue(item?.location)}</p>
              <p className="mb-1"><strong>Category:</strong> {getFieldValue(item?.category)}</p>
              <p className="mb-1"><strong>Subcategory:</strong> {getFieldValue(item?.subcategory)}</p>
              {item?.link && (
                <Button variant="primary" href={item.link} target="_blank" className="mb-2" style={{width:"100%"}}>
                  Check it out at: {domainName}
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={6} lg={4} className='mt-2'>
              <p className="mb-1"><strong>Price:</strong> {getFieldValue(item?.price)}</p>
              <p className="mb-1"><strong>Brand:</strong> {getFieldValue(item?.brand)}</p>
              <p className="mb-1"><strong>Weight:</strong> {getFieldValue(dimensions?.weight)} g</p>
            </Col>
            <Col xs={6} lg={4} className='mt-2'>
              <p className="mb-1"><strong>FNSKU:</strong> {getFieldValue(item?.fnsku)}</p>
              <p className="mb-1"><strong>ASIN:</strong> {getFieldValue(item?.asin)}</p>
              <p className="mb-1"><strong>EAN:</strong> {getFieldValue(item?.ean)}</p>
              <p className="mb-1"><strong>UPC:</strong> {getFieldValue(item?.upc)}</p>
            </Col>
            <Col xs={6} lg={4} className='mt-2'>
              <p className="mb-1"><strong>Added By:</strong> {getFieldValue(item?.added_by)}</p>
              <p className="mb-1"><strong>Approved By:</strong> {getFieldValue(item?.approved_by)}</p>
              <p className="mb-1"><strong>Date:</strong> {formatDate(item?.time)}</p>
              <p className="mb-1"><strong>Time:</strong> {formatTime(item?.time)}</p>
            </Col>
            <Col xs={6} className='mt-2'>
              <p className="mb-1"><strong>Suggested Destination:</strong> {getFieldValue(item?.suggested_destination)}</p>
              <p className="mb-1"><strong>Destination:</strong> {getFieldValue(item?.destination)}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {item?.notes && <p><strong>Notes:</strong> {item.notes}</p>}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {item?.description && <p><strong>Description:</strong> {item.description}</p>}
              {bullets.length > 0 && (
                <ListGroup className="mt-3 w-100">
                  {bullets.map((bullet, idx) => (
                    <ListGroup.Item key={idx} style={{ fontSize: '0.9rem' }}>{bullet}</ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>
            <Row className="mt-3">
              <Col xs={12}>
              <Form.Group className="mt-3">
                <Form.Label><strong>Select Auction:</strong></Form.Label>
                <Form.Select
                  value={selectedAuction}
                  onChange={(e) => setSelectedAuction(e.target.value)}
                  aria-label="Select Auction"
                >
                  <option value="">Select an auction</option>
                  {auctions.map((auction) => (
                    <option key={auction.id} value={auction.title}>
                      {auction.title}
                    </option>
                  ))}
                </Form.Select>
                <Button className="mt-2" variant="primary" onClick={handleUpdateClick}>
                  Update Destination
                </Button>
              </Form.Group>
              </Col>
            </Row>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleEditClick}>
          Edit Item
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryItemModal;