import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StringBubbles.css';

const StringBubbles = ({ 
    initialStrings, 
    onBubblesChange, 
    maxWidth = 900, 
    minWidth = 60, 
    bubbleBackgroundColor = "#e1e1e1",  
    deleteButtonColor = "#ff6b6b",      
    deleteButtonHoverColor = "#ff4c4c", 
    addButtonColor = "#007bff",         
    addButtonText = "Add String",
    textColor = "#000000",        
}) => {
    const [bubbles, setBubbles] = useState(initialStrings);
    const textareaRefs = useRef([]);

    useEffect(() => {
        textareaRefs.current = textareaRefs.current.slice(0, bubbles.length);
    }, [bubbles]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onBubblesChange(bubbles); 
        }, 1000); 

        return () => {
            clearTimeout(timeoutId); 
        };
    }, [bubbles, onBubblesChange]);

    useEffect(() => {
        setBubbles(initialStrings);
    }, [initialStrings]);

    useEffect(() => {
        bubbles.forEach((_, index) => adjustSize(index));
    }, [bubbles]);

    useEffect(() => {
        initialStrings.forEach((_, index) => adjustSize(index));
    }, [initialStrings]); // Adjust size when initialStrings changes

    const handleChange = (index, value) => {
        const newBubbles = [...bubbles];
        newBubbles[index] = value;
        setBubbles(newBubbles);
        adjustSize(index);
    };

    const adjustSize = (index) => {
        const textarea = textareaRefs.current[index];
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;

            const maxLineWidth = getMaxLineWidth(textarea, maxWidth);

            const finalWidth = Math.max(Math.min(maxLineWidth + 18, maxWidth), minWidth);
            textarea.style.width = `${finalWidth}px`;
        }
    };

    const getMaxLineWidth = (textarea, containerMaxWidth) => {
        const lines = textarea.value.split('\n');
        let maxLineWidth = 0;

        lines.forEach((line) => {
            const words = line.split(' ');
            let lineWidth = 0;

            words.forEach((word) => {
                const wordWidth = getTextWidth(word + ' ', getComputedStyle(textarea).font);
                if (lineWidth + wordWidth > containerMaxWidth) {
                    maxLineWidth = Math.max(maxLineWidth, lineWidth);
                    lineWidth = wordWidth;
                } else {
                    lineWidth += wordWidth;
                }
                maxLineWidth = Math.max(maxLineWidth, lineWidth);
            });
        });

        return maxLineWidth;
    };

    const getTextWidth = (text, font) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
    };

    useEffect(() => {
        bubbles.forEach((_, index) => adjustSize(index));
    }, [bubbles]);

    useEffect(() => {
        bubbles.forEach((_, index) => adjustSize(index));
    }, []); // Adjust size on initial render

    const addBubble = () => {
        setBubbles([...bubbles, '']);
    };

    const removeBubble = (index) => {
        const newBubbles = bubbles.filter((_, i) => i !== index);
        setBubbles(newBubbles);
    };

    return (
        <Container fluid>
            <div className = {'textbubbles-container'}>
                {bubbles.map((bubble, index) => (
                    <Col key={index} xs="auto" className="mb-2 mt-2">
                        <div 
                            className="bubble"
                            style={{
                                background: `linear-gradient(145deg, ${bubbleBackgroundColor}, ${bubbleBackgroundColor === "#e1e1e1" ? "#dcdcdc" : shadeColor(bubbleBackgroundColor, -20)})`,
                                borderColor: shadeColor(bubbleBackgroundColor, -15),
                            }}
                        >
                            <textarea
                                ref={el => textareaRefs.current[index] = el}
                                className="bubble-textarea"
                                value={bubble}
                                style = {{color: textColor}}
                                onChange={(e) => handleChange(index, e.target.value)}
                                rows={1}
                            />
                            <Button 
                                className="delete-button" 
                                onClick={() => removeBubble(index)}
                                style={{
                                    backgroundColor: deleteButtonColor,
                                    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.2)`,
                                }}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = deleteButtonHoverColor}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = deleteButtonColor}
                            >
                                ×
                            </Button>
                        </div>
                    </Col>
                ))}
                <Col xs="auto" className="mt-2 mb-2">
                    <Button 
                        variant="outline-secondary" 
                        className="add-button" 
                        onClick={addBubble}
                        style={{
                            '--add-button-color': addButtonColor,
                        }}
                    >
                        {addButtonText}
                    </Button>
                </Col>
            </div>
        </Container>
    );
};

const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1,3),16);
    let G = parseInt(color.substring(3,5),16);
    let B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    const RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    const GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    const BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return `#${RR}${GG}${BB}`;
};

export default StringBubbles;