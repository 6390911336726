// src/components/ConstructCharts.js
import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import { format, subDays, isSameDay, parseISO } from 'date-fns';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement, // Import BarElement
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components, including BarElement
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement, // Register BarElement
    Title,
    Tooltip,
    Legend
);

/**
 * Helper function to aggregate data
 *
 * @param {Array} data - Array of action dictionaries.
 * @param {string} timeframe - 'day', 'week', or 'month'.
 * @param {Date} now - Current date.
 * @returns {Object} - Aggregated data per time slot and user-action combination.
 */
const aggregateData = (data, timeframe, now) => {
    const aggregation = {};
    let labels = [];
    let timeSlots = [];

    if (timeframe === 'day') {
        // Create 24 hourly slots
        for (let hour = 0; hour < 24; hour++) {
            const label = `${hour}:00`;
            aggregation[label] = {};
            timeSlots.push(label);
        }

        // Filter data for today
        const todayData = data.filter(item => {
            const date = parseISO(item.action_time);
            return isSameDay(date, now);
        });

        console.log(`Aggregating data for 'day' timeframe:`, todayData);

        todayData.forEach(item => {
            const date = parseISO(item.action_time);
            const hour = format(date, 'H:00'); // e.g., '14:00'
            if (aggregation[hour]) {
                const key = `${item.user_id}_${item.action_type}`;
                if (aggregation[hour][key]) {
                    aggregation[hour][key] += 1;
                } else {
                    aggregation[hour][key] = 1;
                }
            }
        });

        labels = timeSlots;
    } else if (timeframe === 'week') {
        // Create 7 daily slots
        for (let i = 6; i >= 0; i--) {
            const date = subDays(now, i);
            const label = format(date, 'MM-dd');
            aggregation[label] = {};
            timeSlots.push(label);
        }

        // Filter data for the last 7 days
        const weekData = data.filter(item => {
            const date = parseISO(item.action_time);
            return date >= subDays(now, 6) && date <= now;
        });

        console.log(`Aggregating data for 'week' timeframe:`, weekData);

        weekData.forEach(item => {
            const date = parseISO(item.action_time);
            const label = format(date, 'MM-dd');
            if (aggregation[label]) {
                const key = `${item.user_id}_${item.action_type}`;
                if (aggregation[label][key]) {
                    aggregation[label][key] += 1;
                } else {
                    aggregation[label][key] = 1;
                }
            }
        });

        labels = timeSlots;
    } else if (timeframe === 'month') {
        // Create 30 daily slots
        for (let i = 29; i >= 0; i--) {
            const date = subDays(now, i);
            const label = format(date, 'MM-dd');
            aggregation[label] = {};
            timeSlots.push(label);
        }

        // Filter data for the last 30 days
        const monthData = data.filter(item => {
            const date = parseISO(item.action_time);
            return date >= subDays(now, 29) && date <= now;
        });

        console.log(`Aggregating data for 'month' timeframe:`, monthData);

        monthData.forEach(item => {
            const date = parseISO(item.action_time);
            const label = format(date, 'MM-dd');
            if (aggregation[label]) {
                const key = `${item.user_id}_${item.action_type}`;
                if (aggregation[label][key]) {
                    aggregation[label][key] += 1;
                } else {
                    aggregation[label][key] = 1;
                }
            }
        });

        labels = timeSlots;
    }

    console.log(`Aggregated Data for '${timeframe}':`, aggregation);

    return { aggregation, labels };
};

/**
 * Constructs charts based on the provided data and options.
 *
 * @param {Object} props
 * @param {Array} props.data - Array of action dictionaries.
 * @param {boolean} props.day - Whether to include the daily chart.
 * @param {boolean} props.week - Whether to include the weekly chart.
 * @param {boolean} props.month - Whether to include the monthly chart.
 * @param {number[]} props.userIds - Array of user IDs to include.
 * @param {string[]} props.actionTypes - Array of action types to include.
 * @param {string[]} props.chartTypes - Array of chart types to render ('line', 'doughnut', 'bar').
 * @returns {JSX.Element} - Rendered charts within Bootstrap tabs.
 */
const ConstructCharts = ({ data, day, week, month, userIds, actionTypes, chartTypes = ['line'] }) => {
    // Determine which timeframes to include
    const timeframesToRender = [];
    if (day) timeframesToRender.push('day');
    if (week) timeframesToRender.push('week');
    if (month) timeframesToRender.push('month');

    // Filter data based on userIds and actionTypes
    const filteredData = data.filter(item =>
        userIds.includes(item.user_id) && actionTypes.includes(item.action_type)
    );

    console.log('Filtered Data:', filteredData);

    /**
     * Generates chart data for Line and Bar charts.
     *
     * @param {Object} aggregatedData - Aggregated data per time slot and user-action combination.
     * @param {Array} labels - Array of labels for the x-axis.
     * @returns {Object} - Data object for Line/Bar charts.
     */
    const generateChartData = (aggregatedData, labels) => {
        const actionKeys = new Set();
        
        // Collect all unique action keys (user_id_action_type)
        Object.values(aggregatedData).forEach(actionObj => {
            Object.keys(actionObj).forEach(actionKey => actionKeys.add(actionKey));
        });
    
        const sortedActionKeys = Array.from(actionKeys).sort();
    
        // Assign a color to each action key
        const colors = sortedActionKeys.map(() => getRandomColor());
    
        const datasets = sortedActionKeys.map((actionKey, index) => {
            // Calculate total for each actionKey across all time slots (labels)
            const total = labels.reduce((sum, label) => {
                return sum + (aggregatedData[label][actionKey] || 0);
            }, 0);
    
            const dataPoints = labels.map(label => {
                return aggregatedData[label][actionKey] || 0;
            });
    
            return {
                label: `${actionKey.replace('_', ' - ')} (Total: ${total})`, // Append the total count to the label
                data: dataPoints,
                fill: false,
                backgroundColor: colors[index],
                borderColor: colors[index],
            };
        });
    
        console.log('Generated Chart Data with Totals:', { labels, datasets });
    
        return {
            labels,
            datasets,
        };
    };

    /**
     * Generates data for Doughnut charts.
     *
     * @param {Object} aggregatedData - Aggregated data per time slot and user-action combination.
     * @returns {Object} - Data object for Doughnut charts.
     */
    const generateDoughnutData = (aggregatedData) => {
        const actionKeys = new Set();
        const totals = {};
        Object.values(aggregatedData).forEach(actionObj => {
            Object.keys(actionObj).forEach(actionKey => {
                actionKeys.add(actionKey);
                totals[actionKey] = (totals[actionKey] || 0) + actionObj[actionKey];
            });
        });

        const labels = Array.from(actionKeys).map(key => key.replace('_', ' - '));
        const data = labels.map(label => totals[label.replace(' - ', '_')]);
        const colors = labels.map(() => getRandomColor());

        return {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                },
            ],
        };
    };
    
    /**
     * Utility function to generate random colors
     *
     * @returns {string} - RGBA color string.
     */
    const getRandomColor = () => {
        const r = Math.floor(Math.random() * 200); // Reduced range for better visibility
        const g = Math.floor(Math.random() * 200);
        const b = Math.floor(Math.random() * 200);
        return `rgba(${r}, ${g}, ${b}, 0.6)`;
    };

    /**
     * Maps chart type strings to their corresponding components.
     */
    const chartTypeComponents = {
        line: Line,
        doughnut: Doughnut,
        bar: Bar,
    };

    return (
        <Tabs defaultActiveKey={timeframesToRender[0]} id="construct-charts-tabs" justify>
            {timeframesToRender.map((timeframe) => {
                const now = new Date();
                const { aggregation, labels } = aggregateData(filteredData, timeframe, now);
                const chartData = generateChartData(aggregation, labels);
                const doughnutData = generateDoughnutData(aggregation);
                const chartTitle = {
                    day: '24 Hours',
                    week: '7 Days',
                    month: '30 Days'
                }[timeframe];

                const xAxisTitle = {
                    day: 'Hour of Day',
                    week: 'Date',
                    month: 'Date'
                }[timeframe];

                return (
                    <Tab eventKey={timeframe} title={chartTitle} key={timeframe} style={{border: "1px solid black"}}>
                        <div className="d-flex flex-column align-items-center">
                            {chartTypes.map((type) => {
                                let data;
                                let options;
                                let ChartComponent = chartTypeComponents[type];

                                if (!ChartComponent) {
                                    console.warn(`Unsupported chart type: ${type}`);
                                    return null;
                                }

                                switch (type) {
                                    case 'line':
                                        data = chartData;
                                        options = {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                    labels: {
                                                        usePointStyle: true,
                                                    },
                                                },
                                                title: {
                                                    display: true,
                                                    text: `${chartTitle} - Line Chart`,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                        text: xAxisTitle,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Number of Actions',
                                                    },
                                                    beginAtZero: true,
                                                },
                                            },
                                        };
                                        break;
                                    case 'bar':
                                        data = chartData;
                                        options = {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'top',
                                                    labels: {
                                                        usePointStyle: true,
                                                    },
                                                },
                                                title: {
                                                    display: true,
                                                    text: `${chartTitle} - Bar Chart`,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                        text: xAxisTitle,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Number of Actions',
                                                    },
                                                    beginAtZero: true,
                                                },
                                            },
                                        };
                                        break;
                                    case 'doughnut':
                                        data = doughnutData;
                                        options = {
                                            responsive: true,
                                            plugins: {
                                                legend: { position: 'top' },
                                                title: { display: true, text: `${chartTitle} - Doughnut Overview` },
                                            },
                                        };
                                        break;
                                    default:
                                        return null;
                                }

                                return (
                                    <div key={`${timeframe}-${type}`} style={{ width: '100%', maxWidth: '800px', minHeight: '300px', marginBottom: '40px' }}>
                                        <ChartComponent data={data} options={options} />
                                    </div>
                                );
                            })}
                        </div>
                    </Tab>
                );
            })}
        </Tabs>
    );
};

export default ConstructCharts;
