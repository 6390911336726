import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Dropdown, Button, Form } from 'react-bootstrap';
import Users from './Users';
import Suppliers from './Suppliers';
import Shipments from './Shipments';
import InventoryPage from './InventoryPage';
import ScannedPage from './ScannedPage';
import UserAddingData from './UserAddingData';
import './UserPage.css';

const UserPage = ({ token }) => {
  const [modules, setModules] = useState(() => {
    const savedModules = localStorage.getItem('modules');
    return savedModules ? JSON.parse(savedModules) : [];
  });

  const [modulesPerRow, setModulesPerRow] = useState(() => {
    const savedModulesPerRow = localStorage.getItem('modulesPerRow');
    return savedModulesPerRow ? JSON.parse(savedModulesPerRow) : 2;
  });

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    localStorage.setItem('modules', JSON.stringify(modules));
  }, [modules]);

  useEffect(() => {
    localStorage.setItem('modulesPerRow', JSON.stringify(modulesPerRow));
  }, [modulesPerRow]);

  const addModule = () => {
    setModules([...modules, { id: Date.now(), type: null }]);
  };

  const removeModule = (moduleId) => {
    setModules(modules.filter(module => module.id !== moduleId));
  };

  const handleSelect = (moduleId, selection) => {
    setModules(modules.map(module =>
      module.id === moduleId ? { ...module, type: selection } : module
    ));
  };

  const renderComponent = (componentName) => {
    switch (componentName) {
      case 'Users':
        return <Users token={token} />;
      case 'Suppliers':
        return <Suppliers token={token} />;
      case 'Shipments':
        return <Shipments token={token} />;
      case 'Scanned':
        return <ScannedPage token={token} />;
      case 'Inventory':
        return <InventoryPage token={token} />;
      case 'UserAddingData':
        return <UserAddingData token={token} />;
      default:
        return <div>Select a component</div>;
    }
  };

  const handleModulesPerRowChange = (event) => {
    setModulesPerRow(parseInt(event.target.value));
  };

  const getColSize = () => {
    switch (modulesPerRow) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      case 4:
        return 3;
      default:
        return 6;
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <Button variant="primary" onClick={toggleEditMode}>
            {editMode ? 'Disable Edit Mode' : 'Enable Edit Mode'}
          </Button>
        </Col>
      </Row>
      {editMode && (
        <Row className="mt-3">
          <Col>
            <Form.Select
              value={modulesPerRow}
              onChange={handleModulesPerRowChange}
              aria-label="Modules per row"
            >
              <option value="1">1 per row</option>
              <option value="2">2 per row</option>
              <option value="3">3 per row</option>
              <option value="4">4 per row</option>
            </Form.Select>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        {modules.map(module => (
          <Col key={module.id} sm={12} md={getColSize()} lg={getColSize()} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body className="d-flex flex-column custom-card-body">
                {editMode && (
                  <Card.Title>
                    <Row>
                      <Col sm={8}>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id={`dropdown-${module.id}`}>
                            Choose Component
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'Users')}>Users</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'Suppliers')}>Suppliers</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'Shipments')}>Shipments</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'Inventory')}>Inventory</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'Scanned')}>Scanned</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelect(module.id, 'UserAddingData')}>User Adding Data</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                      <Col sm={4} className='text-end'>
                        <Button variant="danger" className="ms-auto" onClick={() => removeModule(module.id)}>Remove</Button>
                      </Col>
                    </Row>
                  </Card.Title>
                )}
                <div className="flex-grow-1 overflow-auto">
                  {module.type && renderComponent(module.type)}
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {editMode && (
          <Col sm={12} md={getColSize()} lg={getColSize()} className="mb-4">
            <Card className="h-100 custom-card">
              <Card.Body className="d-flex flex-column custom-card-body justify-content-center align-items-center">
                <Button variant="primary" onClick={addModule}>Add Module</Button>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default UserPage;
