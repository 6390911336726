import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import BarcodeScanner from './BarcodeScanner';
import scanIcon from './camera_scanner.avif';

const BarcodeScannerButton = ({ targetId, readers = [], onBarcodeDetected }) => {
  const [showScanner, setShowScanner] = useState(false);
  const inputRef = useRef(null);

  const handleShowScanner = () => {
    const inputElement = document.getElementById(targetId);
    if (inputElement) {
      inputRef.current = inputElement;
      setShowScanner(true);
    }
  };

  const handleCloseScanner = () => {
    setShowScanner(false);
    inputRef.current = null; // Reset the inputRef when the modal is closed
  };

  const handleBarcodeDetected = (barcode) => {
    if (inputRef.current) {
      onBarcodeDetected(targetId, barcode); // Call the prop function to update the state
    }
    handleCloseScanner(); // Close the modal after a successful scan
  };

  return (
    <>
      <Button 
        onClick={handleShowScanner}
        style={{ backgroundColor: 'white', border: '', padding: '8px', borderRadius: '4px' }} // Adjust styling as needed
      >
        <img 
          src={scanIcon} 
          alt="Scan Barcode" 
          style={{ width: '24px', height: '24px' }} // Adjust size as needed
        />
      </Button>

      <BarcodeScanner
        show={showScanner}
        handleClose={handleCloseScanner}
        onBarcodeDetected={handleBarcodeDetected}
        readers={readers}
      />
    </>
  );
};

export default BarcodeScannerButton;