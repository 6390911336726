import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './MyCoolImageCarousel.css';

const MyCoolImageCarousel = ({ images = [], imageLabels = {}, size = 'medium', title = '', border = [], previewPosition = 'left' }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const containerRef = useRef(null);

  const sizeSuffix = {
    small: '_small.avif',
    medium: '_medium.avif',
    large: '_large.avif',
  };

  useEffect(() => {
    if (images.length > 0) {
      setCurrentImageIndex(0);
    }
  }, [images]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handleSwipe = (direction) => {
    if (direction === 'left') {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    } else if (direction === 'right') {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    containerRef.current.touchStartX = touchStartX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchStartX = containerRef.current.touchStartX;
    if (touchStartX - touchEndX > 50) {
      handleSwipe('left');
    } else if (touchEndX - touchStartX > 50) {
      handleSwipe('right');
    }
  };

  const borderStyle = border.reduce((style, side) => {
    style[`border-${side}`] = '5px solid #ccc';
    return style;
  }, { padding: '15px' });

  const getLabelPositionStyle = (position) => {
    // Define CSS based on position (top-left, top-right, etc.)
    switch (position) {
      case 'top-left':
        return { top: '10px', left: '10px' };
      case 'top-right':
        return { top: '10px', right: '10px' };
      case 'bottom-left':
        return { bottom: '10px', left: '10px' };
      case 'bottom-right':
        return { bottom: '10px', right: '10px' };
      default:
        return { top: '10px', left: '10px' }; // Default to top-left if not specified
    }
  };

  const getLabelSizeClass = (size) => {
    // Return a CSS class based on the size (small, medium, large)
    return size ? `label-size-${size}` : 'label-size-medium'; // Default to medium size
  };

  return (
    <Container style={borderStyle} ref={containerRef} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      {title && <h3 className="cool-image-carousel-title">{title}</h3>}
      <Row>
        {previewPosition === 'top' && images.length > 0 && (
          <Col xs={12} className="mb-3">
            <div className="large-preview-container position-relative">
              <div className="large-preview">
                <img src={images[currentImageIndex] + sizeSuffix[size]} alt="Selected Preview" />
                {/* Add label overlay */}
                {imageLabels[currentImageIndex] && (
                  <div
                    className={`image-label ${getLabelSizeClass(imageLabels[currentImageIndex].imageLabelSize)}`}
                    style={getLabelPositionStyle(imageLabels[currentImageIndex].imageLabelPosition)}
                  >
                    {imageLabels[currentImageIndex].imageLabelText}
                  </div>
                )}
              </div>
            </div>
          </Col>
        )}
        {previewPosition === 'left' && images.length > 0 && (
          <Col xs={12} md={6} className="mb-3">
            <div className="large-preview-container position-relative">
              <div className="large-preview">
                <img src={images[currentImageIndex] + sizeSuffix[size]} alt="Selected Preview" />
                {/* Add label overlay */}
                {imageLabels[currentImageIndex] && (
                  <div
                    className={`image-label ${getLabelSizeClass(imageLabels[currentImageIndex].imageLabelSize)}`}
                    style={getLabelPositionStyle(imageLabels[currentImageIndex].imageLabelPosition)}
                  >
                    {imageLabels[currentImageIndex].imageLabelText}
                  </div>
                )}
              </div>
            </div>
          </Col>
        )}
        <Col xs={12} md={previewPosition === 'left' ? 6 : 12}>
          <Row>
            {images.map((imageUrl, index) => (
              <Col key={index} xs={3} className="mb-3">
                <div 
                  className={`cool-image-carousel-container position-relative ${currentImageIndex === index ? 'cool-image-carousel-selected' : ''}`}
                  onClick={() => handleImageClick(index)}
                >
                  <img src={imageUrl + sizeSuffix[size]} alt="Thumbnail" className="cool-image-carousel-item" />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        {previewPosition === 'right' && images.length > 0 && (
          <Col xs={12} md={6} className="mb-3">
            <div className="large-preview-container position-relative">
              <div className="large-preview">
                <img src={images[currentImageIndex] + sizeSuffix[size]} alt="Selected Preview" />
                {/* Add label overlay */}
                {imageLabels[currentImageIndex] && (
                  <div
                    className={`image-label ${getLabelSizeClass(imageLabels[currentImageIndex].imageLabelSize)}`}
                    style={getLabelPositionStyle(imageLabels[currentImageIndex].imageLabelPosition)}
                  >
                    {imageLabels[currentImageIndex].imageLabelText}
                  </div>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default MyCoolImageCarousel;
