import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, Container, Accordion, Card, Spinner, Row, Col, Form, InputGroup } from 'react-bootstrap';
import Axios from 'axios';
import LocationForm from './LocationForm';
import LocationSettingsModal from './LocationSettingsModal';
import AddItemsToLocationModal from './AddItemsToLocationModal';

const CACHE_DURATION = 30000; // 30 seconds

const LocationsPage = ({ token }) => {
    const [locations, setLocations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [loadingItems, setLoadingItems] = useState({});
    const [locationItems, setLocationItems] = useState({});
    const [showSettings, setShowSettings] = useState(false);
    const [showAddItems, setShowAddItems] = useState(false);
    const [locationCodeForAdd, setLocationCodeForAdd] = useState('');
    const [displayType, setDisplayType] = useState('table');
    const lastFetchTime = useRef({});
    const searchInputRef = useRef(null);
    const [openLocation, setOpenLocation] = useState(null);

    useEffect(() => {
        fetchLocations();
        const savedDisplayType = localStorage.getItem('displayType');
        if (savedDisplayType) {
            setDisplayType(savedDisplayType);
        }
        searchInputRef.current.focus();
    }, []);

    const fetchLocations = () => {
        Axios.get('https://api.njpresearch.com/api/fetch_all_locations', {
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => setLocations(response.data))
        .catch(error => console.error('Error fetching locations:', error));
    };

    const fetchLocationItems = (locationCode) => {
        const now = Date.now();
        if (lastFetchTime.current[locationCode] && (now - lastFetchTime.current[locationCode] < CACHE_DURATION)) {
            // Use cached items if fetched within the last CACHE_DURATION
            return;
        }

        setLoadingItems(prev => ({ ...prev, [locationCode]: true }));
        Axios.get('https://api.njpresearch.com/api/fetch_location', {
            params: { location_number: locationCode },
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
            setLocationItems(prev => ({ ...prev, [locationCode]: response.data }));
            setLoadingItems(prev => ({ ...prev, [locationCode]: false }));
            lastFetchTime.current[locationCode] = Date.now(); // Update last fetch time
        })
        .catch(error => {
            console.error('Error fetching location items:', error);
            setLoadingItems(prev => ({ ...prev, [locationCode]: false }));
        });
    };

    const handleEdit = (location) => {
        setCurrentLocation(location);
        setShowModal(true);
    };

    const handleAdd = () => {
        setCurrentLocation(null);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleOpenAddItems = (locationCode) => {
        setLocationCodeForAdd(locationCode);
        setShowAddItems(true);
    };

    const handleCloseAddItems = () => {
        setShowAddItems(false);
    };

    const deleteLocation = (locationId) => {
        if (window.confirm("Are you sure you want to delete this location?")) {
            Axios.delete(`https://api.njpresearch.com/api/delete_location/${locationId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(() => {
                alert('Location deleted successfully!');
                fetchLocations();  // Refresh the list after deletion
            })
            .catch(error => {
                console.error('Error deleting location:', error.response.data);
                alert('Error deleting location: ' + (error.response.data.detail || error.message));
            });
        }
    };

    const applySettings = (newDisplayType) => {
        setDisplayType(newDisplayType);
    };

    const handleSearch = (event) => {
        event.preventDefault();
        const locationCode = searchInputRef.current.value;
        const location = locations.find(loc => loc.location_code === locationCode);
        if (location) {
            if (openLocation === locationCode) {
                handleOpenAddItems(locationCode);
            } else {
                setOpenLocation(locationCode);
                fetchLocationItems(locationCode);
            }
        } else {
            alert('Location not found');
        }
        searchInputRef.current.value = '';
        searchInputRef.current.focus();  // Refocus the search input field
    };

    const handleAccordionClick = (locationCode) => {
        if (openLocation === locationCode) {
            setOpenLocation(null);
        } else {
            setOpenLocation(locationCode);
            fetchLocationItems(locationCode);
        }
    };

    return (
        <Container>
            <h1>Locations List</h1>
            <Form onSubmit={handleSearch} className="mb-3">
                <InputGroup>
                    <Form.Control
                        ref={searchInputRef}
                        type="text"
                        placeholder="Search by Location Code"
                    />
                    <Button variant="primary" type="submit">Search</Button>
                </InputGroup>
            </Form>
            <Button variant="primary" onClick={handleAdd}>Add Location</Button>
            <Button variant="secondary" onClick={() => setShowSettings(true)}>Settings</Button>
            <Accordion activeKey={openLocation}>
                {locations.map(location => (
                    <Accordion.Item key={location.id} eventKey={location.location_code} onClick={() => handleAccordionClick(location.location_code)}>
                        <Accordion.Header>{location.location_name} ({location.location_code})</Accordion.Header>
                        <Accordion.Body>
                            <Button variant="info" onClick={() => handleEdit(location)}>Edit</Button>{' '}
                            <Button variant="danger" onClick={() => deleteLocation(location.id)}>Delete</Button>
                            {loadingItems[location.location_code] ? <Spinner animation="border" /> : (
                                displayType === 'table' ? (
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Item ID</th>
                                                <th>Item Name</th>
                                                <th>Item Image</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    <Button variant="primary" onClick={() => handleOpenAddItems(location.location_code)}>Add Item to Location</Button>
                                                </td>
                                            </tr>
                                            {locationItems[location.location_code] && locationItems[location.location_code].length > 0 ? (
                                                locationItems[location.location_code].map(item => (
                                                    <tr key={item.id}>
                                                        <td>{item.intcode}</td>
                                                        <td>{item.title}</td>
                                                        <td><img src={item.ogimage + "_small.jpg"} alt={item.title} style={{ width: '100px' }} /></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3">No items found in this location.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <div className="card-container">
                                        <Row className="justify-content-center g-4">
                                            <Col className="d-flex justify-content-center">
                                                <Card style={{ width: '18rem', border: '2px dashed #ccc', textAlign: 'center', minHeight :'22rem' }}>
                                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                                        <Button variant="primary" onClick={() => handleOpenAddItems(location.location_code)}>Add Item to Location</Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            {locationItems[location.location_code] && locationItems[location.location_code].length > 0 ? (
                                                locationItems[location.location_code].map(item => (
                                                    <Col key={item.id} className="d-flex justify-content-center">
                                                        <Card style={{ width: '18rem' }}>
                                                            <div style={{ height: '12rem', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Card.Img variant="top" src={item.ogimage + "_medium.jpg"} style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }} />
                                                            </div>
                                                            <Card.Body>
                                                                <Card.Title style={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', height: '4.8em' }}>
                                                                    {item.title}
                                                                </Card.Title>
                                                                <Card.Text>{item.intcode}</Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))
                                            ) : (
                                                <div></div>
                                            )}
                                        </Row>
                                    </div>
                                )
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <LocationForm show={showModal} handleClose={handleClose} location={currentLocation} refreshList={fetchLocations} token={token} />
            <LocationSettingsModal show={showSettings} handleClose={() => setShowSettings(false)} applySettings={applySettings} />
            <AddItemsToLocationModal show={showAddItems} handleClose={handleCloseAddItems} locationCode={locationCodeForAdd} token={token} />
        </Container>
    );
};

export default LocationsPage;
