import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Quagga from '@ericblade/quagga2';

const BarcodeScanner = ({ show, handleClose, onBarcodeDetected, readers }) => {
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [scanCounts, setScanCounts] = useState({});
  const detectionThreshold = 5;

  useEffect(() => {
    if (show && !isCameraActive) {
      activateCamera();
    } else if (!show && isCameraActive) {
      deactivateCamera();
    }

    return () => {
      if (isCameraActive) {
        deactivateCamera();
      }
    };
  }, [show]);

  const activateCamera = () => {
    setIsCameraActive(true);
    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.querySelector('#cameraView'),
        constraints: {
          facingMode: 'environment'
        },
        area: { // restrict the scanning area to the center (percentage based)
          top: "30%",    // percentage from the top of the video
          right: "0%",  // percentage from the right of the video
          left: "0%",   // percentage from the left of the video
          bottom: "30%"  // percentage from the bottom of the video
        }
      },
      decoder: {
        readers: readers.length > 0 ? readers : ['code_128_reader', 'upc_reader', 'ean_reader']
      },
      locate: true,  // Try to help with centering the scan area
    }, function (err) {
      if (err) {
        console.error('Error initializing Quagga:', err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected(handleDetected);
  };

  const deactivateCamera = () => {
    Quagga.stop();
    setIsCameraActive(false);
    Quagga.offDetected(handleDetected);
    setScanCounts({});
  };

  const handleDetected = (result) => {
    const code = result.codeResult.code;
    setScanCounts((prevScanCounts) => {
      const newCount = (prevScanCounts[code] || 0) + 1;
      if (newCount >= detectionThreshold) {
        onBarcodeDetected(code);
        deactivateCamera();  // Stop scanning once the barcode is confirmed
      }
      return { ...prevScanCounts, [code]: newCount };
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Scan Barcode</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div 
          id="cameraView" 
          style={{
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: 'black',
          }}
        >
          <video
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',  // Ensures the video covers the area while maintaining aspect ratio
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BarcodeScanner;
