import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * BarcodeListener Component
 * 
 * Listens for barcode scans via keypress events.
 * Detects barcodes based on specified patterns and invokes callbacks.
 * 
 * Props:
 * - patterns: Array of objects with `name` and `regex` to identify barcode types.
 * - onDetected: Callback function when a barcode is detected. Receives { name, value }.
 * - bufferTimeout: Time in ms to wait before processing the buffer (default: 50ms).
 */
const BarcodeListener = ({ patterns, onDetected, bufferTimeout = 50 }) => {
  const inputBufferRef = useRef('');
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Ignore Enter key and other non-alphanumeric characters
      if (e.key === 'Enter' || !/^[a-zA-Z0-9]$/.test(e.key)) {
        return;
      }

      // Append the character to the buffer
      inputBufferRef.current += e.key;

      // Clear the previous timeout
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set a new timeout to process the buffer
      timeoutIdRef.current = setTimeout(() => {
        processBuffer(inputBufferRef.current);
        inputBufferRef.current = ''; // Clear the buffer after processing
      }, bufferTimeout);
    };

    const processBuffer = (input) => {
      const upperInput = input.toUpperCase();
      console.log('Scanned Input:', upperInput);

      for (let pattern of patterns) {
        if (pattern.regex.test(upperInput)) {
          console.log(`Matched Pattern: ${pattern.name}`);
          onDetected({ name: pattern.name, value: upperInput });
          break; // Stop after the first match
        }
      }

      // Optionally, handle unmatched barcodes
      // onDetected({ name: 'unknown', value: upperInput });
    };

    // Add keypress listener
    document.addEventListener('keypress', handleKeyPress);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [patterns, onDetected, bufferTimeout]);

  return null; // This component does not render anything
};

BarcodeListener.propTypes = {
  patterns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      regex: PropTypes.instanceOf(RegExp).isRequired,
    })
  ).isRequired,
  onDetected: PropTypes.func.isRequired,
  bufferTimeout: PropTypes.number,
};

export default BarcodeListener;
