// src/components/InviteUser.jsx

import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import permissionsData from './permissions.json'; // Adjust the path as needed
import './InviteUser.css'; // Import custom CSS for additional styling

const InviteUser = ({ token }) => {
  const [formData, setFormData] = useState({
    email: '',
    permissions: [], // JSON list as an array of numbers
  });
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle permission button toggle
  const handlePermissionToggle = (code) => {
    setFormData((prevData) => {
      const isSelected = prevData.permissions.includes(code);
      const newPermissions = isSelected
        ? prevData.permissions.filter((perm) => perm !== code)
        : [...prevData.permissions, code];
      return {
        ...prevData,
        permissions: newPermissions,
      };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.njpresearch.com/api/invite-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData), // Send permissions as an array of numbers
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to invite user.');
      }
      const data = await response.json();
      setResponse(data.message);
      setError(null);
      // Optionally, reset the form
      setFormData({
        email: '',
        permissions: [],
      });
    } catch (err) {
      setError(err.message);
      setResponse(null);
    }
  };

  return (
    <Container className="invite-user-container">
      <h2 className="mb-4 text-center">Invite User</h2>
      <Form onSubmit={handleSubmit}>
        {/* Email Input */}
        <Row className="mb-4">
          <Col md={{ span: 6, offset: 3 }}>
            <Form.Group controlId="email">
              <Form.Label>Email <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                placeholder="Enter user's email"
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Permissions Buttons */}
        <Form.Group controlId="permissions" className="mb-4">
          <Form.Label>Permissions</Form.Label>
          {Object.entries(permissionsData).map(([categoryName, actions]) => (
            <div key={categoryName} className="mb-3">
              <h5 className="mb-2">{categoryName}</h5>
              <Row>
                {Object.entries(actions).map(([actionName, code]) => (
                  <Col xs={6} sm={4} md={3} lg={2} key={code} className="mb-2">
                    <Button
                      variant={formData.permissions.includes(code) ? 'success' : 'outline-secondary'}
                      className={`permission-button w-100 ${formData.permissions.includes(code) ? 'active' : ''}`}
                      onClick={() => handlePermissionToggle(code)}
                    >
                      {actionName}
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </Form.Group>

        {/* Submit Button */}
        <Row className="mb-4">
          <Col className="text-center">
            <Button variant="primary" type="submit" className="px-4">
              Invite User
            </Button>
          </Col>
        </Row>
      </Form>

      {/* Response Alerts */}
      {response && <Alert variant="success" onClose={() => setResponse(null)} dismissible>{response}</Alert>}
      {error && <Alert variant="danger" onClose={() => setError(null)} dismissible>{error}</Alert>}
    </Container>
  );
};

export default InviteUser;
