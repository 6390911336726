import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal, Spinner, Alert } from 'react-bootstrap';
import { debounce } from 'lodash';
import axios from 'axios';
import './AuctionPreview.css'; // Optional: For custom styling
import AuctionCardAdmin from './AuctionCardAdmin';

function AuctionPreview({ token }) {
  // State Variables
  const [auctionItems, setAuctionItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [filters, setFilters] = useState({
    condition: '',
    approved: '',
    date: '',
    subcategory: '',
    order_by: 'id_desc', // Default order
  });
  
  const [limit, setLimit] = useState(() => {
    const savedLimit = localStorage.getItem('AuctionPreviewLimit');
    return savedLimit ? parseInt(savedLimit) : 20; // Default limit
  });
  
  const [currentPage, setCurrentPage] = useState(1);
  
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  
  // Categories (if applicable)
  const categories = {
    // Define categories relevant to auction items
    "Electronics": ["Phones", "Laptops", "Cameras"],
    "Furniture": ["Chairs", "Tables", "Beds"],
    // Add more categories as needed
  };
  
  
  // Fetch Auction Items
  useEffect(() => {
    const loadAuctionItems = debounce(() => {
      fetchAuctionItems();
    }, 300); // Debounce for 300 ms

    loadAuctionItems();
    return () => loadAuctionItems.cancel(); // Cleanup debounce
  }, [filters, limit, currentPage]); // Dependencies

  const fetchAuctionItems = async () => {
    const apiUrl = 'https://api.njpresearch.com/api/fetch_inventory'; // Same API endpoint
    const queryParams = new URLSearchParams({
      ...filters,
      limit: limit.toString(),
      page: currentPage.toString(),
      // Include any other necessary query parameters
    }).toString();

    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}?${queryParams}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Auction items:', response.data);
        const data = response.data || []; // Assuming 'auction_items' is the key
        // If 'auction_items' needs to be parsed from a JSON string:
        const updatedAuctionItems = data.map((item) => ({
          ...item,
          images: JSON.parse(item.images), // Parse images if stored as JSON string
        }));
        setAuctionItems(updatedAuctionItems);
        setError(null);
      } else {
        setError('Failed to fetch auction items.');
        setAuctionItems([]);
      }
    } catch (err) {
      console.error('Error fetching auction items:', err);
      setError(err.message || 'An error occurred while fetching auction items.');
      setAuctionItems([]);
    } finally {
      setLoading(false);
    }
  };

  // Handle Limit Change
  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    localStorage.setItem('AuctionPreviewLimit', newLimit);
    setCurrentPage(1); // Reset to first page
  };

  // Handle Category and Subcategory Change (if applicable)
  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setFilters({ ...filters, category: category, subcategory: '' }); // Reset subcategory
  };

  const handleSubcategoryChange = (event) => {
    const subcategory = event.target.value;
    setFilters({ ...filters, subcategory: subcategory });
  };

  // Handle Order By Change
  const handleOrderByChange = (event) => {
    setFilters({ ...filters, order_by: event.target.value });
  };

  // Handle Modal
  const openAuctionItemModal = (itemId) => {
    const item = auctionItems.find((item) => item.id === itemId);
    setSelectedItem(item);
    setShowModal(true);
  };

  const closeAuctionItemModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  // Split Columns into Chunks for Responsive Display (Optional)
  const allColumns = [
    'id', 'title', 'category', 'subcategory', 'price', 'condition', 'approved', 'date',
    // Add other relevant fields
  ];

  const splitColumns = (arr, numCols) => {
    const perCol = Math.ceil(arr.length / numCols);
    return Array.from({ length: numCols }, (v, i) =>
      arr.slice(i * perCol, i * perCol + perCol)
    );
  };

  const columnsChunks = splitColumns(allColumns, containerWidth <= 576 ? 2 : 4);

  // Responsive Grid Columns
  const getGridColumns = () => {
    if (containerWidth > 1200) return 4;
    if (containerWidth > 992) return 3;
    if (containerWidth > 768) return 2;
    return 1;
  };

  if (loading) {
    return (
      <Container className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading auction items...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <Container ref={containerRef} fluid>
      <Row className="mt-3">
        <Col>
          <h5>Filters</h5>
          <Form>
            <Row>
              {/* Condition Filter */}
              <Col md={2} sm={6} className="mb-2">
                <Form.Select
                  value={filters.condition}
                  onChange={(e) => setFilters({ ...filters, condition: e.target.value })}
                  aria-label="Condition"
                >
                  <option value="">Condition</option>
                  <option value="New">New</option>
                  <option value="Open Box">Open Box</option>
                  <option value="Refurbished">Refurbished</option>
                  <option value="Used">Used</option>
                  <option value="As-is">As-is</option>
                </Form.Select>
              </Col>

              {/* Category Filter */}
              <Col md={3} sm={6} className="mb-2">
                <Form.Select
                  value={filters.category || ''}
                  onChange={handleCategoryChange}
                  aria-label="Category"
                >
                  <option value="">Category</option>
                  {Object.keys(categories).map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              {/* Subcategory Filter */}
              <Col md={3} sm={6} className="mb-2">
                <Form.Select
                  value={filters.subcategory || ''}
                  onChange={handleSubcategoryChange}
                  disabled={!filters.category}
                  aria-label="Subcategory"
                >
                  <option value="">Subcategory</option>
                  {filters.category &&
                    categories[filters.category].map((subcategory) => (
                      <option key={subcategory} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {/* Approval Filter */}
              <Col md={2} sm={6} className="mb-2">
                <Form.Select
                  value={filters.approved}
                  onChange={(e) => setFilters({ ...filters, approved: e.target.value })}
                  aria-label="Approval"
                >
                  <option value="">Approval</option>
                  <option value="true">Approved</option>
                  <option value="false">Not Approved</option>
                </Form.Select>
              </Col>

              {/* Order By Filter */}
              <Col md={2} sm={6} className="mb-2">
                <Form.Select
                  value={filters.order_by}
                  onChange={handleOrderByChange}
                  aria-label="Order By"
                >
                  <option value="id_desc">Oldest</option>
                  <option value="id_asc">Newest</option>
                  <option value="price_asc">Least Expensive</option>
                  <option value="price_desc">Most Expensive</option>
                </Form.Select>
              </Col>
            </Row>

            {/* Limit Selection */}
            <Row className="mt-2">
              <Col md={2} sm={4}>
                <Form.Group controlId="limitSelect">
                  <Form.Label>Items per Page</Form.Label>
                  <Form.Select value={limit} onChange={handleLimitChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {/* Auction Items Grid */}
      <Row className="mt-4">
        {auctionItems.length === 0 ? (
          <Col>
            <Alert variant="info">No auction items found.</Alert>
          </Col>
        ) : (
          auctionItems.map((item) => (
            <Col key={item.id} className="mb-4" sm = {6} lg = {2}>
              <AuctionCardAdmin item={item}/>
            </Col>
          ))
        )}
      </Row>

      {/* Pagination Controls */}
      <Row className="mt-3">
        <Col className="d-flex justify-content-center align-items-center" >
          <Button
            variant="secondary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className="me-2"
          >
            Previous
          </Button>
          <span>Page {currentPage}</span>
          <Button
            variant="secondary"
            disabled={auctionItems.length < limit}
            onClick={() => setCurrentPage(currentPage + 1)}
            className="ms-2"
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AuctionPreview;
