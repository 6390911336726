import React, { useState } from 'react';
import axios from 'axios';

function EditLocation({ location }) {
  const [formData, setFormData] = useState({
    id: location.id,
    name: location.name || '',
    code: location.code || '',
    type: location.type || '',
    dimensions: location.dimensions ? JSON.stringify(location.dimensions) : '',
    contents: location.contents ? JSON.stringify(location.contents) : '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const editLocation = async () => {
    try {
      const { id, name, code, type, dimensions, contents } = formData;
      const data = {
        id,
        name,
        code,
        type,
        dimensions: dimensions ? JSON.parse(dimensions) : undefined,
        contents: contents ? JSON.parse(contents) : undefined,
      };
      await axios.put('/api/edit-location/', data);
      alert('Location updated successfully!');
    } catch (error) {
      console.error('Error editing location:', error);
    }
  };

  const deleteLocation = async (id) => {
    try {
      await axios.delete('/api/delete-location/', { data: { id } });
      alert('Location deleted successfully!');
      // fetchLocations(); // Refresh the list
    } catch (error) {
      console.error('Error deleting location:', error);
    }
  };

  return (
    <div>
      <h1>Edit Location</h1>
      <input
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        name="code"
        placeholder="Code"
        value={formData.code}
        onChange={handleChange}
        required
      />
      <input
        name="type"
        placeholder="Type"
        value={formData.type}
        onChange={handleChange}
        required
      />
      <input
        name="dimensions"
        placeholder="Dimensions (JSON)"
        value={formData.dimensions}
        onChange={handleChange}
      />
      <input
        name="contents"
        placeholder="Contents (JSON)"
        value={formData.contents}
        onChange={handleChange}
      />
      <button onClick={editLocation}>Update Location</button>
    </div>
  );
}

export default EditLocation;
