// AuctionCardCustom.js

import React from 'react';
import { Button, Badge } from 'react-bootstrap';
import './AuctionCardAdmin.css'; // Import custom CSS for styling

const AuctionCardCustom = ({ item }) => {
    console.log(item);
    return (
        <div className="auction-card">
            <div className="auction-card-image-container">
                <img src={item.images[0] + "_medium.avif"} alt={item.title} />
            </div>
            <div className="auction-card-details">
                <h5>{item.title}</h5>
                <p>{item.description}</p>
                <div className="auction-card-footer">
                    <Button variant="primary">View Item</Button>
                </div>
            </div>
        </div>

    );
}

export default AuctionCardCustom;
