import React, { useState } from 'react';
import axios from 'axios';

function CreateLocation() {
  const [formData, setFormData] = useState({
    name: '',
    code: '',
    type: '',
    dimensions: '',
    contents: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const createLocation = async () => {
    try {
      const { name, code, type, dimensions, contents } = formData;
      const data = {
        name,
        code,
        type,
        dimensions: dimensions ? JSON.parse(dimensions) : undefined,
        contents: contents ? JSON.parse(contents) : undefined,
      };
      await axios.post('https://api.njpresearch.com/api/create-location/', data);
      alert('Location created successfully!');
    } catch (error) {
      console.error('Error creating location:', error);
    }
  };

  return (
    <div>
      <h1>Create Location</h1>
      <input name="name" placeholder="Name" onChange={handleChange} required />
      <input name="code" placeholder="Code" onChange={handleChange} required />
      <input name="type" placeholder="Type" onChange={handleChange} required />
      <input
        name="dimensions"
        placeholder="Dimensions (JSON)"
        onChange={handleChange}
      />
      <input
        name="contents"
        placeholder="Contents (JSON)"
        onChange={handleChange}
      />
      <button onClick={createLocation}>Create Location</button>
    </div>
  );
}

export default CreateLocation;
