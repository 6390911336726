import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Container, Form } from 'react-bootstrap';

function UserAddingData({ token }) {
    const [activities, setActivities] = useState([]);
    const [chartData, setChartData] = useState({});
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('daily');

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const today = new Date().toISOString().split('T')[0];
                const startOfMonth = new Date(new Date().setDate(1)).toISOString().split('T')[0];
                const startOfWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toISOString().split('T')[0];
                const queryParams = new URLSearchParams({
                    action_codes: [202],
                    start_date: startOfMonth,
                    limit: 1000 // Adjust as necessary
                });
                const response = await axios.get(`https://api.njpresearch.com/api/fetch_all_user_activity`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: queryParams
                });
                const data = response.data;
                setActivities(data);
                processActivities(data, today, startOfWeek, startOfMonth, 'daily');
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch activities:', error);
                setLoading(false);
            }
        };
        fetchActivities();
    }, [token]);

    const processActivities = (activities, today, startOfWeek, startOfMonth, view) => {
        const dailyData = {};
        const weeklyData = {};
        const monthlyData = {};
        const allData = {};

        const daysInWeek = 7;
        const daysInMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

        activities.forEach(activity => {
            const date = new Date(activity.time).toISOString().split('T')[0];
            const userId = activity.user_id;

            if (!allData[userId]) allData[userId] = 0;
            if (!monthlyData[userId]) monthlyData[userId] = 0;
            if (!weeklyData[userId]) weeklyData[userId] = 0;
            if (!dailyData[userId]) dailyData[userId] = 0;

            allData[userId]++;
            if (date >= startOfMonth) monthlyData[userId]++;
            if (date >= startOfWeek) weeklyData[userId]++;
            if (date === today) dailyData[userId]++;
        });

        const dataToDisplay = view === 'daily' ? dailyData : view === 'weekly' ? weeklyData : monthlyData;
        const formattedData = formatChartData(dataToDisplay, `Activities ${view.charAt(0).toUpperCase() + view.slice(1)}`, dailyData, weeklyData, monthlyData, allData, daysInWeek, daysInMonth);
        setChartData(formattedData);
    };

    const formatChartData = (data, label, dailyData, weeklyData, monthlyData, allData, daysInWeek, daysInMonth) => ({
        labels: Object.keys(data),
        datasets: [{
            label: label,
            data: Object.values(data),
            backgroundColor: Object.keys(data).map(() => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`),
            borderColor: Object.keys(data).map(() => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`),
            borderWidth: 1
        }],
        dailyData,
        weeklyData,
        monthlyData,
        allData,
        daysInWeek,
        daysInMonth
    });

    const handleViewChange = (e) => {
        const newView = e.target.value;
        setView(newView);
        const today = new Date().toISOString().split('T')[0];
        const startOfWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).toISOString().split('T')[0];
        const startOfMonth = new Date(new Date().setDate(1)).toISOString().split('T')[0];
        processActivities(activities, today, startOfWeek, startOfMonth, newView);
    };

    return (
        <Container>
            <h1>User Activities - Bar Charts</h1>
            {loading ? <p>Loading...</p> : (
                <>
                    <Form.Group controlId="viewSelector">
                        <Form.Label>Select View</Form.Label>
                        <Form.Control as="select" value={view} onChange={handleViewChange}>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </Form.Control>
                    </Form.Group>
                    <Bar
                        data={chartData}
                        options={{
                            scales: { y: { beginAtZero: true } },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            const userId = context.label;
                                            const daily = chartData.dailyData[userId] || 0;
                                            const weekly = chartData.weeklyData[userId] || 0;
                                            const monthly = chartData.monthlyData[userId] || 0;
                                            const total = chartData.allData[userId] || 0;
                                            const weeklyDailyAvg = (weekly / chartData.daysInWeek).toFixed(2);
                                            const monthlyDailyAvg = (monthly / chartData.daysInMonth).toFixed(2);
                                            return [
                                                `Activities Today: ${daily}`,
                                                `Activities This Week: ${weekly}`,
                                                `Activities This Month: ${monthly}`,
                                                `Weekly Daily Average: ${weeklyDailyAvg}`,
                                                `Monthly Daily Average: ${monthlyDailyAvg}`
                                            ];
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </>
            )}
        </Container>
    );
}

export default UserAddingData;
