import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { icons } from './icons/icons';

const categories = {
    "Antiques & Collectables": ["Collectables", "Antiques"],
    "Arts and Crafts": ["Craft Supplies", "Craft Machines", "Sewing Machines"],
    "Music": ["Vinyl Records", "Musical Instruments", "Music Accessories"],
    "Video Gaming": ["Video Games", "Gaming Consoles", "Accessories and Components"],
    "Media": ["DVD/Blu-Ray", "Books"],
    "Home Furniture and Patio": ["Dining Room", "Living Room", "Bedroom Furniture", "Patio Furiture", "Bedroom Items"],
    "Adults": ["Adult Product", "Adult Toys"],
    "Automobiles": ["Cars and Vehicles", "Electrical Scooters & Bicycles", "Boats & Boat's Motors", "Skidoo and Jet Skis"],
    "Office Producs": ["Office Furniture", "Printers, Phones and Office Equipment", "Stationary Supplies"],
    "Food and Grocery": ["Food and Grocery"],
    "Toys & Games": ["Toys & Games"],
    "Beauty & Personal Care": ["Health & Personal Care", "Beauty"],
    "Industrial and Medical": ["Medical", "Industrial"],
    "Personal Accessories": ["Wallets & Bags", "Watches", "Jewellery", "Glasses and Frames"],
    "Automotive": ["Parts & Accessories"],
    "Baby Products": ["Baby Supplies", "Car Seats & Strollers", "Baby Furniture", "Baby Food"],
    "Tools and Home Improvement": ["Hand Tools", "Powered Tools", "Home Improvement", "Accessories & Parts"],
    "Shoes": ["Men's Shoes", "Women's Shoes", "Unisex Shoes", "Baby's & Kids Shoes"],
    "Liquidation": ["Liquidation Pallets", "Liquidation Lots"],
    "Pets": ["Pet Furniture", "Pet Supplies"],
    "Fitness and Gym Equipment": ["Weights and Dumbbells", "Treadmills, Cycling & Rowing", "Workout Stations"],
    "Appliances": ["Home Appliances", "Kitchen Appliances", "Major Appliances"],
    "Clothing": ["Men's Clothing", "Unisex Clothing", "Women's Clothing", "Baby and Kids Clothing"],
    "Home & Kitchen": ["Home Decor", "Bathroom", "Kitchen Items", "Cleaning"],
    "Electronics": ["Speakers, Headphone & Earbuds", "Laptops and Computers", "Electronic Accessories", "Smart Watches", "Tablets and Cell Phones", "TV's and Monitors", "Cameras and Lenses", "Parts and Components"],
    "Sports & Outdoors": ["Outdoor", "Sports"]
  };

const ItemEditPage = ({ token }) => {
    const { itemId } = useParams();
    const [initialFormData, setInitialFormData] = useState({});
    const [itemData, setItemData] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        price: 0,
        height: 0,
        width: 0,
        length: 0,
        fnsku: '',
        upc: '',
        ean: '',
        asin: '',
        link: '',
        category: '',
        subcategory: '',
        weight: 0,
        intcode: '',
        brand: '',
        images: [],
        bullets: '',
        description: '',
        extra_barcodes: '',
        addedImages: [],
        condition: '',
        notes: '',
        origin_code: '',
        location: '',
    });
    const [isVerified, setIsVerified] = useState(false);
    const [validated, setValidated] = useState(false);
    const [locations, setLocations] = useState(null);
    const [locationsFetched, setLocationsFetched] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const apiUrl = `https://api.njpresearch.com/api/fetch_inventory_item/${itemId}`;
        const fetchItemData = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                console.log(data);
                if (data) {
                    if (typeof data.images === 'string') {
                        // Parse the images, append '_small.jpg', and then update state
                        data.images = JSON.parse(data.images).map(img => img + '_medium.jpg');
                    }
                    if (typeof data.bullets === 'string') data.bullets = JSON.parse(data.bullets);
                    setItemData(data);
                    setFormData({
                        ...formData,
                        intcode: data.intcode || '',
                        title: data.title || '',
                        price: data.price || 0,
                        height: data.height || 0,
                        width: data.width || 0,
                        length: data.length || 0,
                        fnsku: data.fnsku || '',
                        upc: data.upc || '',
                        ean: data.ean || '',
                        asin: data.asin || '',
                        link: data.link || '',
                        category: data.category || '',
                        subcategory: data.subcategory || '',
                        weight: data.weight || 0,
                        brand: data.brand || '',
                        images: data.images || [],
                        bullets: data.bullets || '',
                        description: data.description || '',
                        extra_barcodes: data.extra_barcodes || '',
                        condition: data.item_condition || '',
                        notes: data.notes || '',
                        origin_code: data.origin_code || '',
                        location: data.location || ''
                    });
                    setInitialFormData({
                        ...formData,
                        intcode: data.intcode || '',
                        title: data.title || '',
                        price: data.price || 0,
                        height: data.height || 0,
                        width: data.width || 0,
                        length: data.length || 0,
                        fnsku: data.fnsku || '',
                        upc: data.upc || '',
                        ean: data.ean || '',
                        asin: data.asin || '',
                        link: data.link || '',
                        category: data.category || '',
                        subcategory: data.subcategory || '',
                        weight: data.weight || 0,
                        brand: data.brand || '',
                        images: data.images || [],
                        bullets: data.bullets || '',
                        description: data.description || '',
                        extra_barcodes: data.extra_barcodes || '',
                        condition: data.item_condition || '',
                        notes: data.notes || '',
                        origin_code: data.origin_code || '',
                        location: data.location || ''
                    });
                } else {
                    console.error('No data found in response:', data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchItemData();
    }, [itemId, token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleLocationsDropdownClick = async () => {
        if (!locationsFetched) {
            try {
                const response = await fetch('https://api.njpresearch.com/api/fetch_all_locations', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                const locationsData = await response.json();
                if (response.ok) {
                    setLocations(locationsData);
                    setLocationsFetched(true);
                } else {
                    console.error('Failed to fetch locations');
                }
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        }
    };

    const handleCategoryChange = (e) => {
        const { value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            category: value,
            subcategory: '' // Reset subcategory on category change
        }));
    };

    const handleRemoveImage = (index) => {
        if (window.confirm('Are you sure you want to remove this image?')) {
            const newImages = formData.images.slice();
            const newAddedImages = formData.addedImages.slice();
            if (index < newImages.length) {
                newImages.splice(index, 1);
            } else {
                newAddedImages.splice(index - newImages.length, 1);
            }
            setFormData({
                ...formData,
                images: newImages,
                addedImages: newAddedImages
            });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false || !isVerified) {
            event.stopPropagation();
            alert('Please verify the information before submitting.');
            return;
        }
    
        const changes = {};
        Object.keys(formData).forEach(key => {
            if (JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])) {
                changes[key] = formData[key];
            }
        });
    
        if (Object.keys(changes).length === 0) {
            alert('No changes to submit.');
            return;
        }
    
        console.log('Submitting the following changes:', changes);
    
        try {
            const apiUrl = `https://api.njpresearch.com/api/update_inventory_item/${itemId}`;
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(changes)
            });
    
            if (response.ok) {
                alert('Item updated successfully!');
                navigate('/');
            } else {
                const errorData = await response.json();
                alert('Failed to update item: ' + errorData.message);
            }
        } catch (error) {
            console.error('Error updating item:', error);
            alert('An error occurred. Please try again.');
        }
    };
    
    
    

    const onDrop = acceptedFiles => {
        setFormData(prevFormData => ({
            ...prevFormData,
            addedImages: [...prevFormData.addedImages, ...acceptedFiles],
            images: [...prevFormData.images, ...acceptedFiles.map(file => URL.createObjectURL(file))]
        }));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    if (!itemData) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
          <Row className="m-1 p-1"style={{ borderColor: '#011936FF', borderWidth: '4px', borderStyle: 'solid', borderRadius: '10px' }}>
            <Col sm={6}>
              <Container style={{ width: '100%', height: 'auto', maxHeight:'60vh'}}>
                <Image src={itemData.ogimage+"_medium.jpg"} alt="Item image" />
              </Container>
            </Col>
            {formData.images.length > 0 && (
              <Col sm={6}>
                <Row>
                  {formData.images.map((image, index) => (
                    <Col xs={6} key={index} className="mt-2 mb-2">
                      <div className="position-relative">
                        <Image
                          src={image}
                          alt={`Item image ${index}`}
                          fluid
                          style={{ width: '100%', height: 'auto', maxHeight: '20vh'}}
                        />
                        <Button
                          className="position-absolute top-0 end-0 CYellowBg"
                          onClick={() => handleRemoveImage(index)}
                          style={{ padding: '4px 8px' }}
                        >
                          <icons.Trash width="24" height="24" />
                        </Button>
                      </div>
                    </Col>
                  ))}
                  <Col xs={6} className="mt-2 mb-2">
                    <div
                      {...getRootProps()}
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: '100%',
                        height: '20vh',
                        border: '2px dashed #ccc',
                        cursor: 'pointer'
                      }}
                    >
                      <input {...getInputProps()} />
                      <Button className='CBlueBg'>Add Image</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
    
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
              <Row className='mt-3 mb-3 CYellowBg ' name='Codes' style={{  borderRadius: '10px' }}>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="intcode">
                    <Form.Label><strong className='CBlueText'>Intcode</strong></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="intcode"
                      value={formData.intcode}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="origin_code">
                    <Form.Label><strong className='CBlueText'>Origin Code</strong></Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="origin_code"
                      value={formData.origin_code}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="asin">
                    <Form.Label><strong className='CBlueText'>ASIN</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="asin"
                      value={formData.asin}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="fnsku">
                    <Form.Label><strong className='CBlueText'>FNSKU</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="fnsku"
                      value={formData.fnsku}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="upc">
                    <Form.Label><strong className='CBlueText'>UPC</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="upc"
                      value={formData.upc}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="ean">
                    <Form.Label><strong className='CBlueText'>EAN</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="ean"
                      value={formData.ean}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3 mb-3 CBlueBg' name='ItemPrimary' style={{ borderRadius: '10px' }}>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label><strong className='CYellowText'>Title</strong></Form.Label>
                    <Form.Control 
                      required
                      as="textarea" 
                      rows={3} 
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange} 
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Price</strong></Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Brand</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="brand"
                      value={formData.brand}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Link</strong></Form.Label>
                    <Form.Control
                      type="text"
                      name="link"
                      value={formData.link}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
    
    
    
              <Row className='mt-3 mb-3 CYellowBg' name='ItemExtras' style={{ borderRadius: '10px' }}>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CBlueText'>Description</strong></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CBlueText'>Bullets</strong></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="bullets"
                      value={formData.bullets.join('\n')}
                      onChange={(e) => {
                        const bullets = e.target.value.split('\n');
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          bullets
                        }));
                      }}
                    />
                  </Form.Group>
                </Col>
    
                {/* <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Extra Barcodes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="extra_barcodes"
                      value={formData.extra_barcodes.join('\n')}
                      onChange={(e) => {
                        const extra_barcodes = e.target.value.split('\n');
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          extra_barcodes
                        }));
                      }}
                    />
                  </Form.Group>
                </Col> */}
    
                <Col sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CBlueText'>Extra Notes</strong></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="notes"
                      value={formData.notes}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
    
    
              <Row className='mt-3 mb-3' name='ItemExtras' style={{ backgroundColor: '#011936FF', borderRadius: '10px' }}>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Height</strong></Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="height"
                      value={formData.height}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
    
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Width</strong></Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="width"
                      value={formData.width}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Length</strong></Form.Label>
                    <Form.Control
                      required
                      type="number"
                      name="length"
                      value={formData.length}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                      <Form.Label><strong className='CYellowText'>Weight</strong></Form.Label>
                      <Form.Control
                          required
                          type="number"
                          name="weight"
                          value={formData.weight}
                          onChange={handleInputChange}
                      />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Condition</strong></Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name="condition"
                      value={formData.condition}
                      onChange={handleInputChange}
                    >
                      <option value="">Select condition</option>
                      <option value="New">New</option>
                      <option value="Open">Open Box</option>
                      <option value="Refurbrished">Refurbrished</option>
                      <option value="Used">Used</option>
                      <option value="Asis">As-is</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                
    
                <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label><strong className='CYellowText'>Category</strong></Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="category"
                    value={formData.category}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select a category</option>
                    {Object.keys(categories).map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                </Col>
                <Col sm={4}>
                {formData.category && (
                  <Form.Group className="mb-3">
                    <Form.Label><strong className='CYellowText'>Subcategory</strong></Form.Label>
                    <Form.Control
                      required
                      as="select"
                      name="subcategory"
                      value={formData.subcategory}
                      onChange={handleInputChange}
                    >
                      <option value="">Select a subcategory</option>
                      {categories[formData.category].map((subcategory) => (
                        <option key={subcategory} value={subcategory}>
                          {subcategory}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}
                </Col>


                <Form.Group as={Col} sm={4}>
                    <Form.Label><strong>Location</strong></Form.Label>
                    <Form.Control as="select" value={formData.location} onClick={handleLocationsDropdownClick} onChange={handleInputChange} name="location">
                        <option value={formData.location}>{formData.location}</option>
                        {locations && locations.map((location, index) => (
                            <option key={index} value={location.location_code}>
                                {location.location_code} - {location.location_name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>


              </Row>
            </Container>
            
    
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check 
                type="checkbox" 
                label="I have verified that the information is correct" 
                checked={isVerified}
                onChange={(e) => setIsVerified(e.target.checked)}
              />
            </Form.Group>
    
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form>
        </Container>
      );
};

export default ItemEditPage;
