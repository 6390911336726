// src/components/UserDetails.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form, Spinner, Alert } from 'react-bootstrap';
import { DateTime } from 'luxon';
import ConstructCharts from './ConstructCharts';
import fetchUserStatistics from './FetchUserStatistics';
import permissionsData from './permissions.json'; // Import the permissions.json
import { FaCheckCircle } from 'react-icons/fa'; // Import Font Awesome Check Circle Icon
import './UserDetails.css';
// Helper function to flatten permissions and create mappings
const flattenPermissions = (permissionsObj) => {
  const flatPermissions = {};
  const categoryMap = {};

  Object.entries(permissionsObj).forEach(([category, perms]) => {
    Object.entries(perms).forEach(([permName, permId]) => {
      flatPermissions[permId] = { name: permName, category };
      if (!categoryMap[category]) {
        categoryMap[category] = [];
      }
      categoryMap[category].push({ id: permId, name: permName });
    });
  });

  return { flatPermissions, categoryMap };
};

const { flatPermissions, categoryMap } = flattenPermissions(permissionsData);

const UserDetails = ({ user }) => {
  // Permissions handling
  let permissions = user.permissions;
  if (typeof permissions === 'string') {
    try {
      permissions = JSON.parse(permissions);
      permissions = Array.isArray(permissions) ? permissions : [];
    } catch (error) {
      console.error('Failed to parse permissions:', error);
      permissions = [];
    }
  } else if (!Array.isArray(permissions)) {
    permissions = [];
  }

  const [userActions, setUserActions] = useState([]);
  const [selectedActionTypes, setSelectedActionTypes] = useState([]);
  const [actionTypesOptions, setActionTypesOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user actions and extract unique action types
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUserStatistics([user.id], ['invite_user', 'item_search', 'add_inventory']);

        // Convert action_time to Toronto timezone using Luxon
        const adjustedData = data.map(action => ({
          ...action,
          action_time: DateTime.fromISO(action.action_time, { zone: 'utc' })
            .setZone('America/Toronto')
            .toISO()
        }));

        setUserActions(adjustedData);

        // Extract unique action types
        const uniqueActionTypes = Array.from(new Set(adjustedData.map(action => action.action_type)));
        setActionTypesOptions(uniqueActionTypes);

        // Set all action types as selected by default
        setSelectedActionTypes(uniqueActionTypes);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user statistics:', error);
        setError('Failed to load user statistics.');
        setLoading(false);
      }
    };

    fetchData();
  }, [user.id]);

  // Handle checkbox changes for action types
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedActionTypes(prev => [...prev, value]);
    } else {
      setSelectedActionTypes(prev => prev.filter(action => action !== value));
    }
  };

  if (loading) {
    return (
      <Container fluid className="text-center py-5">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="py-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  // Define the timezone (Toronto)
  const timeZone = 'America/Toronto';

  // Filter categories to only include those with user's permissions
  const userCategoryMap = Object.entries(categoryMap).reduce((acc, [category, perms]) => {
    const userPerms = perms.filter(perm => permissions.includes(perm.id));
    if (userPerms.length > 0) {
      acc[category] = userPerms;
    }
    return acc;
  }, {});

  return (
    <Container fluid className="py-3">
      {/* User Information */}
      <Row className="align-items-center mb-4">
        <Col xs={4} md={3} className="text-center mb-3 mb-md-0">
            <div className="profile-picture-wrapper">
                <Image src={`${user.picture}_medium.avif`} className="profile-picture" fluid  circ/>
            </div>
        </Col>
        <Col xs={8} md={9} className="text-md-start text-center">
          <h4 className="mb-1">{user.username}</h4>
          <p className="mb-0 text-muted">{user.first_name} {user.last_name}</p>
          <p className="mb-0"><a href={`mailto:${user.email}`}>{user.email}</a></p>
        </Col>
      </Row>

      {/* Permissions Section */}
      <Row className="mb-4">
        <Col>
          <h5 className="mb-3">Permissions</h5>
          <Row>
            {Object.entries(userCategoryMap).map(([category, perms]) => (
              <Col xs={6} md={6} lg={4} key={category} className="mb-3">
                <h6 className="text-primary">{category}</h6>
                <ul className="list-unstyled ms-3">
                  {perms.map(({ id, name }) => (
                    <li key={id} className="d-flex align-items-center mb-1">
                      <FaCheckCircle className="text-success me-2" size={16} />
                      <span>{name}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
          </Row>
          {Object.keys(userCategoryMap).length === 0 && (
            <p className="text-muted">This user has no permissions assigned.</p>
          )}
        </Col>
      </Row>

      {/* Chart Configuration */}
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <h5 className="mb-3">Configure Charts</h5>
          <Form>
            <Form.Group controlId="actionTypes">
              <Form.Label>Select Action Types:</Form.Label>
              <div style={{ maxHeight: '200px', overflowY: 'auto', paddingLeft: '1rem' }}>
                {actionTypesOptions.map((actionType, index) => (
                  <Form.Check 
                    key={index}
                    type="checkbox"
                    label={actionType}
                    value={actionType}
                    checked={selectedActionTypes.includes(actionType)}
                    onChange={handleCheckboxChange}
                    className="mb-2"
                  />
                ))}
              </div>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={12} md={8}>
          {/* Render the charts with selected action types */}
          <ConstructCharts
            data={userActions}
            day={true}
            week={true}
            month={true}
            userIds={[user.id]}
            actionTypes={selectedActionTypes}
            chartTypes={['line']}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetails;