import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListItemBubble from './ListItemBubble';
import IntcodeNavigator from './IntocdeNavigator';

function ScannedPage({ token, user }) {
  const navigate = useNavigate();
  const [scannedItems, setScannedItems] = useState([]);
  const [showApproved, setShowApproved] = useState(() => {
    return JSON.parse(localStorage.getItem('ShowApproved')) || false;
  });
  const [showOnlyMine, setShowOnlyMine] = useState(() => {
    return JSON.parse(localStorage.getItem('ShowOnlyMine')) || false;
  });
  const [showOnlyCompleted, setShowOnlyCompleted] = useState(() => {
    return JSON.parse(localStorage.getItem('ShowOnlyCompleted')) || false;
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [limit, setLimit] = useState(() => {
    const savedLimit = localStorage.getItem('ScannedLimit');
    return savedLimit ? parseInt(savedLimit) : 50; // Default limit
  });
  const [currentPage, setCurrentPage] = useState(1); // Default to first page
  const [orderBy, setOrderBy] = useState('id_desc'); // Default order by id descending

  useEffect(() => {
    fetchScannedItems();
  }, [showApproved, showOnlyMine, showOnlyCompleted, startDate, endDate, limit, currentPage, orderBy]);

  const apiUrl = 'https://api.njpresearch.com/api/fetch_scanned';
  console.log('user id:', user.id);
  console.log('username:', user.username);
  const fetchScannedItems = async () => {
    const requestBody = {
      user_ids: showOnlyMine ? [user.id] : [],
      status: showOnlyCompleted ? 'complete' : '',
      start_date: startDate,
      end_date: endDate,
      limit: limit,
      page: currentPage,
      order_by: orderBy
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch scanned items');
      }
      const data = await response.json();
      setScannedItems(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'An error occurred');
      setLoading(false);
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    localStorage.setItem('ScannedLimit', newLimit);
  };

  const removeScannedItem = async (intcode) => {
    try {
      const response = await fetch('https://api.njpresearch.com/api/remove-scanned', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ intcode })
      });

      if (!response.ok) {
        throw new Error('Failed to remove scanned item');
      }

      // Handle successful removal (e.g., refresh the list or update the UI)
      console.log('Item removed successfully');
      setScannedItems(scannedItems.filter(item => item.intcode !== intcode));
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleOrderByChange = (event) => {
    setOrderBy(event.target.value);
  };

  const handleShowApprovedChange = (event) => {
    const newValue = event.target.checked;
    setShowApproved(newValue);
    localStorage.setItem('ShowApproved', JSON.stringify(newValue));
    fetchScannedItems();
  };

  const handleShowOnlyMineChange = (event) => {
    const newValue = event.target.checked;
    setShowOnlyMine(newValue);
    localStorage.setItem('ShowOnlyMine', JSON.stringify(newValue));
    fetchScannedItems();
  };

  const handleShowOnlyCompletedChange = (event) => {
    const newValue = event.target.checked;
    setShowOnlyCompleted(newValue);
    localStorage.setItem('ShowOnlyCompleted', JSON.stringify(newValue));
    fetchScannedItems();
  };

  const renderItems = () => (
    scannedItems.map((item) => {
      let statusIcon = {
        show: true,
        side: 'right',
        size: '2em',
      };
  
      switch (item.status) {
        case 'complete':
          statusIcon = { ...statusIcon, color: '#721e5d', animation: 'none', name: 'CheckCircle' };
          break;
        case 'processing':
          statusIcon = { ...statusIcon, color: 'orange', animation: 'spin', name: 'Spinner' };
          break;
        case 'error':
          statusIcon = { ...statusIcon, color: 'red', animation: 'none', name: 'ExclamationCircle' };
          break;
        default:
          statusIcon = { ...statusIcon, show: false };
      }
  
      return (
          <Col key={item.id} xs={12} lg = {6}>
            <ListItemBubble 
              item={item} 
              onClick={() => navigate(`/confirmation/${item.intcode}`)} 
              onRight={removeScannedItem} // Pass the removeScannedItem function as onDelete prop
              rightBubble={{
                color: 'red',
                icon: 'Trash',
                iconColor: 'white',
                show: true,
                confirmationText: 'Delete',
                confirmation: true,
              }}
              showImage={true}
              statusIcon={statusIcon}
            />
          </Col>
      );
    })
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container fluid className="py-3 min-vh-100 d-flex align-items-center justify-content-center ">
      <div className="frosted-glass p-3 rounded shadow-lg" style={{ width: '100%' }}>
      <IntcodeNavigator />
      <Accordion defaultActiveKey="" className="mt-3 mb-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filters</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Items per page</Form.Label>
                  <Form.Select
                    value={limit}
                    onChange={handleLimitChange}
                    aria-label="Limit"
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Order By</Form.Label>
                  <Form.Select
                    value={orderBy}
                    onChange={handleOrderByChange}
                    aria-label="Order By"
                  >
                    <option value="id_asc">Newest</option>
                    <option value="id_desc">Oldest</option>
                    <option value="price_asc">Least Expensive</option>
                    <option value="price_desc">Most Expensive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Form.Check 
                  type="checkbox"
                  label="Show Approved"
                  checked={showApproved}
                  onChange={handleShowApprovedChange}
                />
              </Col>
              <Col md={3}>
                <Form.Check 
                  type="switch"
                  label="Show Only Mine"
                  checked={showOnlyMine}
                  onChange={handleShowOnlyMineChange}
                />
              </Col>
              <Col md={3}>
                <Form.Check 
                  type="switch"
                  label="Show Only Completed"
                  checked={showOnlyCompleted}
                  onChange={handleShowOnlyCompletedChange}
                />
              </Col>
              <Col md={3}>
                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder="Start Date" />
              </Col>
              <Col md={3}>
                <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} placeholder="End Date" />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Row className="m-0 p-0">
      {renderItems()}
      </Row>
      {/* Pagination Controls */}
      <Row className="mt-3">
        <Col>
          <Button
            variant="secondary"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <span className="mx-2">Page {currentPage}</span>
          <Button
            variant="secondary"
            disabled={scannedItems.length < limit}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Col>
      </Row>
      </div>
    </Container>
  );
}

export default ScannedPage;