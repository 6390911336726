import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  Container,
  Row,
  Col,
  InputGroup,
  Button,
  Alert,
} from 'react-bootstrap';
import BarcodeScannerButton from './camera_barcode_reader/BarcodeScannerButton';
import ImageUpload from './ImageUpload'; // Import the ImageUpload component

const ItemSearch = ({ token }) => {
  const [formData, setFormData] = useState({
    upc: '',
    ean: '',
    asin: '',
    fnsku: '',
    remainingImages: [], // No initial images
    newImages: [], // Files of new images to upload
    markedImage: '', // Name of the marked image
    intcode: '',
    company_id: '3',
    origin_code: '',
    search_query: '',
  });
  const [saveOrigin, setSaveOrigin] = useState(false); // State to manage the checkbox
  const inputBufferRef = useRef('');
  const timeoutIdRef = useRef(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null); // State to store the response or error message
  const [isLoading, setIsLoading] = useState(false); // Loading state for form submission

  useEffect(() => {
    // Prefill the origin code from local storage if available
    const savedOriginCode = localStorage.getItem('origin_code');
    if (savedOriginCode) {
      setFormData((prevData) => ({
        ...prevData,
        origin_code: savedOriginCode,
      }));
      setSaveOrigin(true);
    }

    // Add keypress listener
    document.addEventListener('keypress', handleKeyPress);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  const handleKeyPress = (e) => {
    console.log('Key Pressed:', e.key);
    console.log('Buffer:', inputBufferRef.current);

    // Ignore Enter key and other non-alphanumeric characters
    if (e.key === 'Enter' || !/^[a-zA-Z0-9]$/.test(e.key)) {
      return;
    }

    // Append the character to the buffer
    inputBufferRef.current += e.key;

    // Clear the previous timeout
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a new timeout to process the buffer
    timeoutIdRef.current = setTimeout(() => {
      handleBarcodeScan(inputBufferRef.current);
      inputBufferRef.current = ''; // Clear the buffer after processing
    }, 50); // Adjust the timeout duration as needed
  };

  const handleBarcodeScan = (input) => {
    input = input.toUpperCase(); // Convert input to uppercase
    console.log('Scanned Input:', input);

    // Example regular expressions for detecting barcode types
    if (/^B0\w{8}$/.test(input)) {
      // ASIN: Starts with "B0" and is 10 characters long
      setFormData((prevData) => ({
        ...prevData,
        asin: input,
      }));
    } else if (/^\d{12}$/.test(input)) {
      // UPC: 12 digits
      setFormData((prevData) => ({
        ...prevData,
        upc: input,
      }));
    } else if (/^\d{13}$/.test(input)) {
      // EAN: 13 digits
      setFormData((prevData) => ({
        ...prevData,
        ean: input,
      }));
    } else if (/^X0[A-Z0-9]{8}$/.test(input)) {
      // FNSKU: 10 alphanumeric characters starting with "X0"
      setFormData((prevData) => ({
        ...prevData,
        fnsku: input,
      }));
    } else if (/^NP\w{6}$/.test(input)) {
      // INTCODE: Starts with "NP" and is 8 characters long
      setFormData((prevData) => ({
        ...prevData,
        intcode: input,
      }));
    } else if (/^OG\w{5}$/.test(input)) {
      // ORIGIN_CODE: Starts with "OG" and is 7 characters long
      setFormData((prevData) => ({
        ...prevData,
        origin_code: input,
      }));
    }
    // Add additional cases for other barcode types if needed

    // Log the updated formData
    console.log('Updated Form Data:', formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (remainingLinks, newFiles, markedImage) => {
    console.log('Remaining Existing Images:', remainingLinks);
    console.log('New Images to Upload:', newFiles);
    console.log('Marked Image:', markedImage);

    // Update form data with image info while preserving existing state
    setFormData((prevData) => ({
      ...prevData,
      remainingImages: remainingLinks,
      newImages: newFiles,
      markedImage: markedImage,
    }));
  };

  const handleSaveOriginChange = (e) => {
    const isChecked = e.target.checked;
    setSaveOrigin(isChecked);
    if (isChecked) {
      localStorage.setItem('origin_code', formData.origin_code);
    } else {
      localStorage.removeItem('origin_code');
    }
  };

  const handleSubmit = () => {
    // Clear the response field
    setResponse(null);

    if (!validateForm()) {
      return; // If validation fails, stop form submission
    }

    // Check if at least one image is present
    if (formData.newImages.length === 0 && formData.remainingImages.length === 0) {
      alert('Please upload at least one image.');
      return;
    }

    setIsLoading(true); // Start loading

    // Convert relevant fields to uppercase before submission
    const upperCasedFormData = {
      ...formData,
      origin_code: formData.origin_code.toUpperCase(),
      intcode: formData.intcode.toUpperCase(),
      fnsku: formData.fnsku.toUpperCase(),
      asin: formData.asin.toUpperCase(),
    };

    // Create a new FormData object
    const formDataObj = new FormData();

    // Append the non-empty fields to the FormData object
    const filteredData = Object.entries(upperCasedFormData)
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0; // Only include non-empty arrays
        }
        return value !== ''; // Only include non-empty strings
      })
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    formDataObj.append('item_data', JSON.stringify(filteredData));

    // Append each new image file to the FormData object
    formData.newImages.forEach((file) => {
      formDataObj.append('newImages', file);
    });

    // Debugging: Log form data
    for (let pair of formDataObj.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }

    // Perform the fetch request
    fetch('https://api.njpresearch.com/api/item_search', {
      method: 'POST',
      body: formDataObj,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setResponse(JSON.stringify(data, null, 2)); // Pretty print the response
        setIsLoading(false); // Stop loading
        navigate('/scanned');
      })
      .catch((error) => {
        console.error('Error:', error);
        setResponse('An error occurred while submitting the form.'); // Set error message
        setIsLoading(false); // Stop loading
      });
  };

  const handleBarcodeDetected = (targetId, barcode) => {
    setFormData((prevData) => ({
      ...prevData,
      [targetId]: barcode,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { upc, ean, asin, fnsku } = formData;

    if (upc && !/^\d{12}$/.test(upc)) {
      newErrors.upc = 'UPC must be exactly 12 digits.';
    }
    if (ean && !/^\d{13}$/.test(ean)) {
      newErrors.ean = 'EAN must be exactly 13 digits.';
    }
    if (asin && !/^B0\w{8}$/.test(asin)) {
      newErrors.asin = 'ASIN must start with "B0" and be exactly 10 characters.';
    }
    if (fnsku && !/^X0[A-Z0-9]{8}$/.test(fnsku)) {
      newErrors.fnsku = 'FNSKU must start with "X0" and be exactly 10 characters.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Container fluid className="py-3 min-vh-100 d-flex align-items-center justify-content-center ">
      <div className="frosted-glass p-3 rounded shadow-lg" style={{ maxWidth:"800px", width: '100%' }}>
        {/* <h2 className="mb-4 text-center">Item Search</h2> */}
        <Form>
          <Row className="mb-4">
            {/* UPC and EAN Fields */}
            <Col md={6} className="mb-3">
              <Form.Group controlId="upc">
                <Form.Label>UPC</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="upc"
                    placeholder="Enter UPC (12 digits)"
                    value={formData.upc}
                    onChange={handleInputChange}
                    isInvalid={!!errors.upc}
                  />
                  <BarcodeScannerButton
                    targetId="upc"
                    readers={['upc_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.upc}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group controlId="ean">
                <Form.Label>EAN</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="ean"
                    placeholder="Enter EAN (13 digits)"
                    value={formData.ean}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ean}
                  />
                  <BarcodeScannerButton
                    targetId="ean"
                    readers={['ean_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ean}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            {/* ASIN and FNSKU Fields */}
            <Col md={6} className="mb-3">
              <Form.Group controlId="asin">
                <Form.Label>ASIN</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="asin"
                    placeholder="Enter ASIN"
                    value={formData.asin}
                    onChange={handleInputChange}
                    isInvalid={!!errors.asin}
                  />
                  <BarcodeScannerButton
                    targetId="asin"
                    readers={['code_128_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.asin}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group controlId="fnsku">
                <Form.Label>FNSKU</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="fnsku"
                    placeholder="Enter FNSKU"
                    value={formData.fnsku}
                    onChange={handleInputChange}
                    isInvalid={!!errors.fnsku}
                  />
                  <BarcodeScannerButton
                    targetId="fnsku"
                    readers={['code_128_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.fnsku}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-4">
            {/* Intcode and Origin Code Fields */}
            <Col md={6} className="mb-3">
              <Form.Group controlId="intcode">
                <Form.Label>Intcode</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="intcode"
                    placeholder="Enter Intcode"
                    value={formData.intcode}
                    onChange={handleInputChange}
                    isInvalid={!!errors.intcode}
                  />
                  <BarcodeScannerButton
                    targetId="intcode"
                    readers={['code_128_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.intcode}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group controlId="origin_code">
                <Form.Label>Origin Code</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="origin_code"
                    placeholder="Enter Origin Code"
                    value={formData.origin_code}
                    onChange={handleInputChange}
                  />
                  <BarcodeScannerButton
                    targetId="origin_code"
                    readers={['code_128_reader']}
                    onBarcodeDetected={handleBarcodeDetected}
                  />
                </InputGroup>
                <Form.Check
                  type="checkbox"
                  label="Save Origin Code"
                  className="mt-2"
                  checked={saveOrigin}
                  onChange={handleSaveOriginChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Keywords Field */}
          <Row className="mb-4">
            <Col md={12}>
              <Form.Group controlId="search_query">
                <Form.Label>Keywords</Form.Label>
                <Form.Control
                  type="text"
                  name="search_query"
                  placeholder="Enter Keywords"
                  value={formData.search_query}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Image Upload */}
          <Row className="mb-4">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Upload Images</Form.Label>
                <ImageUpload
                  initialImages={[]} // No initial images
                  onChange={handleImageChange}
                  border={['top', 'bottom']}
                  marking={true}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row className="mb-4">
            <Col md={12} className="text-center">
              <Button
                className="animated-button btn-purple" style={{width:'100%'}}
                variant="primary"
                size="lg"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Submitting...
                  </>
                ) : (
                  'Submit'
                )}
              </Button>
            </Col>
          </Row>
        </Form>

        {/* Response Message */}
        {response && (
          <Row className="mt-4">
            <Col md={12}>
              <Alert
                variant={response.startsWith('An error') ? 'danger' : 'success'}
                className="text-center"
              >
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{response}</pre>
              </Alert>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default ItemSearch;
