import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import BarcodeScannerButton from './camera_barcode_reader/BarcodeScannerButton';
import StringBubbles from './StringBubbles';
import ImageUpload from './ImageUpload';
import axios from 'axios';

const categories = {
  "Antiques & Collectables": ["Collectables", "Antiques"],
  "Arts and Crafts": ["Craft Supplies", "Craft Machines", "Sewing Machines"],
  "Music": ["Vinyl Records", "Musical Instruments", "Music Accessories"],
  "Video Gaming": ["Video Games", "Gaming Consoles", "Accessories and Components"],
  "Media": ["DVD/Blu-Ray", "Books"],
  "Home Furniture and Patio": ["Dining Room", "Living Room", "Bedroom Furniture", "Patio Furniture", "Bedroom Items"],
  "Adults": ["Adult Product", "Adult Toys"],
  "Automobiles": ["Cars and Vehicles", "Electrical Scooters & Bicycles", "Boats & Boat's Motors", "Skidoo and Jet Skis"],
  "Office Products": ["Office Furniture", "Printers, Phones and Office Equipment", "Stationery Supplies"],
  "Food and Grocery": ["Food and Grocery"],
  "Toys & Games": ["Toys & Games"],
  "Beauty & Personal Care": ["Health & Personal Care", "Beauty"],
  "Industrial and Medical": ["Medical", "Industrial"],
  "Personal Accessories": ["Wallets & Bags", "Watches", "Jewellery", "Glasses and Frames"],
  "Automotive": ["Parts & Accessories"],
  "Baby Products": ["Baby Supplies", "Car Seats & Strollers", "Baby Furniture", "Baby Food"],
  "Tools and Home Improvement": ["Hand Tools", "Powered Tools", "Home Improvement", "Accessories & Parts"],
  "Shoes": ["Men's Shoes", "Women's Shoes", "Unisex Shoes", "Baby's & Kids Shoes"],
  "Liquidation": ["Liquidation Pallets", "Liquidation Lots"],
  "Pets": ["Pet Furniture", "Pet Supplies"],
  "Fitness and Gym Equipment": ["Weights and Dumbbells", "Treadmills, Cycling & Rowing", "Workout Stations"],
  "Appliances": ["Home Appliances", "Kitchen Appliances", "Major Appliances"],
  "Clothing": ["Men's Clothing", "Unisex Clothing", "Women's Clothing", "Baby and Kids Clothing"],
  "Home & Kitchen": ["Home Decor", "Bathroom", "Kitchen Items", "Cleaning"],
  "Electronics": ["Speakers, Headphones & Earbuds", "Laptops and Computers", "Electronic Accessories", "Smart Watches", "Tablets and Cell Phones", "TVs and Monitors", "Cameras and Lenses", "Parts and Components"],
  "Sports & Outdoors": ["Outdoor", "Sports"]
};

const EditInventoryItem = ({ token }) => {
  const imageUploadRef = useRef();
  const navigate = useNavigate();
  const { itemId } = useParams();
  
  const [formData, setFormData] = useState({
    title: '',
    price: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
    fnsku: '',
    upc: '',
    ean: '',
    asin: '',
    link: '',
    category: '',
    subcategory: '',
    intcode: '',
    brand: '',
    bullets: [],
    description: '',
    condition: '',
    notes: '',
    origin_code: '',
    location: '',
    added_by: '',
    image_label: false,
    imageLabelText: '',
    imageLabelPosition: '',
    imageLabelSize: '',
    remainingImages: [],
    newImages: [], // Files of new images to upload
    markedImage: '',
    images: [],
  });
  
  const [initialData, setInitialData] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const apiUrl = `https://api.njpresearch.com/api/fetch_inventory_item/${itemId}`;
    
      const fetchItemData = async () => {
        try {
          const response = await axios.get(apiUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
    
          const data = response.data;
          console.log('Fetched data:', data);
          data.dimensions = JSON.parse(data.dimensions);
          data.images = JSON.parse(data.images);
          const initialFormData = {
            title: data.title || '',
            price: parseFloat(data.price) || 0,
            height: data.dimensions?.height || 0,
            width: data.dimensions?.width || 0,
            length: data.dimensions?.length || 0,
            weight: data.dimensions?.weight || 0,
            fnsku: data.fnsku || '',
            upc: data.upc || '',
            ean: data.ean || '',
            asin: data.asin || '',
            link: data.link || '',
            category: data.category || '',
            subcategory: data.subcategory || '',
            intcode: data.intcode || '',
            brand: data.brand || '',
            bullets: data.bullets ? JSON.parse(data.bullets) : [],
            description: data.description || '',
            condition: data.item_condition || '',
            notes: data.notes || '',
            origin_code: data.origin_code || '',
            location: data.location || '',
            added_by: data.added_by || '',
            image_label: data.image_label ? true : false,
            imageLabelText: data.image_label?.imageLabelText || '',
            imageLabelPosition: data.image_label?.imageLabelPosition || '',
            imageLabelSize: data.image_label?.imageLabelSize || '',
            remainingImages: data.images,
            images: data.images,

          };
    
          setFormData(initialFormData);
          setInitialData(initialFormData);
          setLoading(false); // Set loading to false once data is fetched
        } catch (error) {
          console.error('Error fetching item data:', error);
          alert('Failed to fetch item data. Please try again.');
          setLoading(false); // Set loading to false even if there's an error
        }
      };
    
      fetchItemData();
    }, [itemId, token]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Handle number fields
    const numberFields = ['price', 'height', 'width', 'length', 'weight'];
    if (numberFields.includes(name)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value === '' ? '' : parseFloat(value),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };



  
  const handleImageChange = (remainingLinks, newFiles, markedImage) => {
    console.log('Remaining Existing Images:', remainingLinks);
    console.log('New Images to Upload:', newFiles);
    console.log('Marked Image:', markedImage);

    // Update form data with image info
    setFormData({
      ...formData,
      remainingImages: remainingLinks,
      newImages: newFiles,
      markedImage: markedImage,
    });
  };

  const handleAddImage = (imageUrl) => {
    imageUploadRef.current.addImageFromUrl(imageUrl);
  
  };
  // Handle category change
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory,
      subcategory: '', // Reset subcategory when category changes
    }));
  };

  const uploadNewImages = async (intcode, newImageFiles, token) => {
    // Extract the last number from the remainingImages array
    let lastNumber = initialData.remainingImages.length;
  
    // Create form data to send the image files
    const imageData = new FormData();
    newImageFiles.forEach((file, index) => {
      imageData.append('images', file);
    });
  
    // Create the API endpoint URL
    const apiUrl = `https://api.njpresearch.com/api/upload-inventory-image/${intcode}/${lastNumber + 1}`;
    try {
      // Send the POST request to the API endpoint
      const response = await axios.post(apiUrl, imageData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        console.log('Images uploaded successfully:', response.data);
        
        // Extract new image links from the response
        const newImageLinks = response.data.new_links;
  
        // Update the remainingImages array with the new image links
        setFormData((prevFormData) => {
          const updatedRemainingImages = [...prevFormData.remainingImages, ...newImageLinks].map(image => 
            image.replace(/(_medium|_large)\.avif$/, '')
          );
  
          return {
            ...prevFormData,
            remainingImages: updatedRemainingImages,
            images: updatedRemainingImages, // Set images to remainingImages
          };
        });
      } else {
        console.error('Failed to upload images:', response.data);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  // Handle bullets change from StringBubbles component
  const handleUpdateBubbles = (bullets) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bullets,
    }));
  };

  // Handle image label toggle
  const handleImageLabelToggle = (e) => {
    const isChecked = e.target.checked;
    setFormData((prevFormData) => ({
      ...prevFormData,
      image_label: isChecked,
      // Reset image label fields if unchecked
      ...(isChecked
        ? {}
        : {
            imageLabelText: '',
            imageLabelPosition: '',
            imageLabelSize: '',
          }),
    }));
  };

  const getUpdatedFields = (recentFormData) => {
    const updatedData = {};
    console.log("FORM DATA INSIDE", recentFormData);
    console.log("INITIAL DATA INSIDE", initialData);
    for (const key in recentFormData) {
      if (key !== 'newImages' && key !== 'remainingImages' && key !== 'markedImage' && recentFormData[key] !== initialData[key]) {
        updatedData[key] = recentFormData[key];
      }
    }
  
    // Check if any dimensions have been updated
    const dimensionFields = ['height', 'width', 'length', 'weight'];
    const dimensionsChanged = dimensionFields.some(field => recentFormData[field] !== initialData[field]);
  
    if (dimensionsChanged) {
      updatedData.height = recentFormData.height;
      updatedData.width = recentFormData.width;
      updatedData.length = recentFormData.length;
      updatedData.weight = recentFormData.weight;
    }
  
    // Handle image_label separately
    if (recentFormData.image_label) {
      if ((recentFormData.imageLabelText).length > 0) {
        updatedData.image_label = true;
        updatedData.image_label_text = recentFormData.imageLabelText;
        updatedData.image_label_position = recentFormData.imageLabelPosition|| 'top-left';
        updatedData.image_label_size = recentFormData.imageLabelSize || 'small';
      } else {
        updatedData.image_label = false;
      }
    } else {
      updatedData.image_label = false;
    }
  
    return updatedData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
  
    // Basic validation
    if (form.checkValidity() === false || !isVerified || !formData.category) {
      event.stopPropagation();
      setValidated(true);
  
      if (!isVerified) {
        alert('Please verify the information before submitting.');
      }
      if (!formData.category) {
        alert('Please select a category.');
      }
      return;
    }
  
    // Upload new images if any
    if (formData.newImages && formData.newImages.length > 0) {
      try {
        await uploadNewImages(formData.intcode, formData.newImages, token);
      } catch (error) {
        console.error('Error uploading images:', error);
        alert('Failed to upload images. Please try again.');
        return;
      }
    }
  
    // Set formData.images to formData.remainingImages after uploading new images
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        images: prevFormData.remainingImages,
      };
  
      // Get the updated fields to submit
      var updatedData = getUpdatedFields(updatedFormData);
      // Remove _medium.avif or _large.avif from the end of the image link if it's there
      updatedData.images = prevFormData.remainingImages.map(image => {
        return image.replace(/(_medium|_large)\.avif$/, '');
      });



      // Proceed with form submission logic here
      console.log('Updated Data:', updatedData);
      (async () => {
        try {
          const apiUrl = `https://api.njpresearch.com/api/edit-inventory-item/${itemId}`;
          console.log('Form Data:', updatedFormData);
          console.log('Updating item with data:', updatedData);
          console.log('New Images:', updatedFormData.newImages);
          console.log('markedImage:', updatedFormData.markedImage);
  
          const response = await axios.put(apiUrl, updatedData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 200) {
            alert('Item updated successfully!');
            navigate('/inventory'); // Redirect to inventory page or any other desired page
          } else {
            alert('Failed to update item. Please try again.');
          }
        } catch (error) {
          console.error('Error updating item:', error);
          alert('An error occurred while updating the item. Please try again.');
        }
      })();
  
      return updatedFormData; // Return the updated state
    });
  
    setValidated(true);
  };

  return (
    <Container>
      <h2 className="my-4">Edit Inventory Item</h2>
      {loading ? (
      <div>Loading...</div> // Show a loading indicator while data is being fetched
    ) : (
      <>
      <ImageUpload 
          ref={imageUploadRef}
          selectedDisplay = 'left'
          initialImages={formData.remainingImages}
          onChange={handleImageChange} 
          border={['top', 'bottom']} 
          marking={true} 
        />
        <Button variant="primary" onClick={() => console.log('Marked Image:', formData.markedImage)}>Marked Image</Button>
        <Button variant="primary" onClick={() => console.log("Remaining Images:", formData.remainingImages)}>Remaining Images</Button>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {/* Image Label Toggle */}
        <Form.Group className="mb-3" controlId="addImageLabelSwitch">
          <Form.Check
            type="switch"
            label="Add Image Label"
            checked={formData.image_label}
            onChange={handleImageLabelToggle}
          />
        </Form.Group>

        {/* Image Label Fields */}
        {formData.image_label && (
          <Row className="mt-3 mb-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '10px', padding: '15px' }}>
            <Col sm={4}>
              <Form.Group className="mb-3" controlId="imageLabelText">
                <Form.Label><strong>Image Label Text</strong></Form.Label>
                <Form.Control
                  type="text"
                  name="imageLabelText"
                  value={formData.imageLabelText}
                  onChange={handleInputChange}
                  placeholder="Enter image label text"
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3" controlId="imageLabelPosition">
                <Form.Label><strong>Image Label Position</strong></Form.Label>
                <Form.Control
                  as="select"
                  name="imageLabelPosition"
                  value={formData.imageLabelPosition}
                  onChange={handleInputChange}
                >
                  <option value="">Select position</option>
                  <option value="top-left">Top Left</option>
                  <option value="top-right">Top Right</option>
                  <option value="bottom-left">Bottom Left</option>
                  <option value="bottom-right">Bottom Right</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group className="mb-3" controlId="imageLabelSize">
                <Form.Label><strong>Image Label Size</strong></Form.Label>
                <Form.Control
                  as="select"
                  name="imageLabelSize"
                  value={formData.imageLabelSize}
                  onChange={handleInputChange}
                >
                  <option value="">Select size</option>
                  <option value="small">Small</option>
                  <option value="medium">Medium</option>
                  <option value="large">Large</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}

        {/* Codes Section */}
        <Row className="mt-3 mb-3" style={{ backgroundColor: '#fff3cd', borderRadius: '10px', padding: '15px' }}>
          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="intcode">
              <Form.Label><strong>Intcode</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  name="intcode"
                  value={formData.intcode}
                  onChange={handleInputChange}
                  placeholder="Enter intcode"
                />
                <BarcodeScannerButton
                  targetId="intcode"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, intcode: barcode }))}
                />
                <Form.Control.Feedback type="invalid">
                  Intcode is required.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="origin_code">
              <Form.Label><strong>Origin Code</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  name="origin_code"
                  value={formData.origin_code}
                  onChange={handleInputChange}
                  placeholder="Enter origin code"
                />
                <BarcodeScannerButton
                  targetId="origin_code"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, origin_code: barcode }))}
                />
                <Form.Control.Feedback type="invalid">
                  Origin Code is required.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="asin">
              <Form.Label><strong>ASIN</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="asin"
                  value={formData.asin}
                  onChange={handleInputChange}
                  placeholder="Enter ASIN"
                />
                <BarcodeScannerButton
                  targetId="asin"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, asin: barcode }))}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="fnsku">
              <Form.Label><strong>FNSKU</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="fnsku"
                  value={formData.fnsku}
                  onChange={handleInputChange}
                  placeholder="Enter FNSKU"
                />
                <BarcodeScannerButton
                  targetId="fnsku"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, fnsku: barcode }))}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="upc">
              <Form.Label><strong>UPC</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="upc"
                  value={formData.upc}
                  onChange={handleInputChange}
                  placeholder="Enter UPC"
                />
                <BarcodeScannerButton
                  targetId="upc"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, upc: barcode }))}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col sm={12} md={6} lg={4}>
            <Form.Group className="mb-3" controlId="ean">
              <Form.Label><strong>EAN</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="ean"
                  value={formData.ean}
                  onChange={handleInputChange}
                  placeholder="Enter EAN"
                />
                <BarcodeScannerButton
                  targetId="ean"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, ean: barcode }))}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {/* Item Primary Information */}
        <Row className="mt-3 mb-3" style={{ backgroundColor: '#cce5ff', borderRadius: '10px', padding: '15px' }}>
          <Col sm={12}>
            <Form.Group className="mb-3" controlId="title">
              <Form.Label><strong>Title</strong></Form.Label>
              <Form.Control
                required
                as="textarea"
                rows={3}
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter title"
              />
              <Form.Control.Feedback type="invalid">
                Title is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="price">
              <Form.Label><strong>Price ($)</strong></Form.Label>
              <Form.Control
                required
                type="number"
                step="0.01"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Enter price"
              />
              <Form.Control.Feedback type="invalid">
                Price is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="brand">
              <Form.Label><strong>Brand</strong></Form.Label>
              <Form.Control
                type="text"
                name="brand"
                value={formData.brand}
                onChange={handleInputChange}
                placeholder="Enter brand"
              />
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="link">
              <Form.Label><strong>Link</strong></Form.Label>
              <Form.Control
                type="url"
                name="link"
                value={formData.link}
                onChange={handleInputChange}
                placeholder="Enter product link"
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Item Extras */}
        <Row className="mt-3 mb-3" style={{ backgroundColor: '#ffeeba', borderRadius: '10px', padding: '15px' }}>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label><strong>Description</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Enter description"
              />
            </Form.Group>
          </Col>

          <Col sm={12}>
            <Form.Group className="mb-3" controlId="bullets">
              <Form.Label><strong>Bullets</strong></Form.Label>
              <StringBubbles
                initialStrings={formData.bullets}
                onBubblesChange={handleUpdateBubbles}
                bubbleBackgroundColor="#cce5ff"
                deleteButtonColor="blue"
                deleteButtonHoverColor="#ff4500"
                addButtonColor="blue"
                addButtonText="Add New Bullet"
                textColor="black"
              />
            </Form.Group>
          </Col>

          <Col sm={12}>
            <Form.Group className="mb-3" controlId="notes">
              <Form.Label><strong>Extra Notes</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
                placeholder="Enter any extra notes"
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Dimensions and Category */}
        <Row className="mt-3 mb-3" style={{ backgroundColor: '#d4edda', borderRadius: '10px', padding: '15px' }}>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="height">
              <Form.Label><strong>Height (cm)</strong></Form.Label>
              <Form.Control
                required
                type="number"
                name="height"
                value={formData.height}
                onChange={handleInputChange}
                placeholder="Enter height"
              />
              <Form.Control.Feedback type="invalid">
                Height is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="width">
              <Form.Label><strong>Width (cm)</strong></Form.Label>
              <Form.Control
                required
                type="number"
                name="width"
                value={formData.width}
                onChange={handleInputChange}
                placeholder="Enter width"
              />
              <Form.Control.Feedback type="invalid">
                Width is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="length">
              <Form.Label><strong>Length (cm)</strong></Form.Label>
              <Form.Control
                required
                type="number"
                name="length"
                value={formData.length}
                onChange={handleInputChange}
                placeholder="Enter length"
              />
              <Form.Control.Feedback type="invalid">
                Length is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="weight">
              <Form.Label><strong>Weight (kg)</strong></Form.Label>
              <Form.Control
                required
                type="number"
                name="weight"
                value={formData.weight}
                onChange={handleInputChange}
                placeholder="Enter weight"
              />
              <Form.Control.Feedback type="invalid">
                Weight is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="condition">
              <Form.Label><strong>Condition</strong></Form.Label>
              <Form.Control
                required
                as="select"
                name="condition"
                value={formData.condition}
                onChange={handleInputChange}
              >
                <option value="">Select condition</option>
                <option value="New">New</option>
                <option value="Open">Open Box</option>
                <option value="Refurbished">Refurbished</option>
                <option value="Used">Used</option>
                <option value="As-is">As-is</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Condition is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label><strong>Category</strong></Form.Label>
              <Form.Control
                required
                as="select"
                name="category"
                value={formData.category}
                onChange={handleCategoryChange}
              >
                <option value="">Select a category</option>
                {Object.keys(categories).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Category is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            {formData.category && (
              <Form.Group className="mb-3" controlId="subcategory">
                <Form.Label><strong>Subcategory</strong></Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="subcategory"
                  value={formData.subcategory}
                  onChange={handleInputChange}
                >
                  <option value="">Select a subcategory</option>
                  {categories[formData.category].map((subcategory) => (
                    <option key={subcategory} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Subcategory is required.
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Col>
        </Row>

        {/* Additional Information */}
        <Row className="mt-3 mb-3" style={{ backgroundColor: '#fefefe', borderRadius: '10px', padding: '15px' }}>
          <Col sm={4}>
            <Form.Group className="mb-3" controlId="origin_code">
              <Form.Label><strong>Origin Code</strong></Form.Label>
              <Form.Control
                required
                type="text"
                name="origin_code"
                value={formData.origin_code}
                onChange={handleInputChange}
                placeholder="Enter origin code"
              />
              <Form.Control.Feedback type="invalid">
                Origin Code is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group className="mb-3" controlId="location">
              <Form.Label><strong>Location</strong></Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Enter location"
                />
                <BarcodeScannerButton
                  targetId="location"
                  readers={['upc_reader', 'ean_reader', 'code_128_reader']}
                  onBarcodeDetected={(barcode) => setFormData((prev) => ({ ...prev, location: barcode }))}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {/* Verification Checkbox */}
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="I have verified that the information is correct"
            checked={isVerified}
            onChange={(e) => setIsVerified(e.target.checked)}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit">
          Update Item
        </Button>
      </Form>

      <div style={{ height: '100px' }}></div>
      </>
    )}
    </Container>
  );
};

export default EditInventoryItem;
