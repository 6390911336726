import React, { useState } from 'react';
import { Badge, Row, Col, Card, Button, Spinner, Container } from 'react-bootstrap';
import { getImageLink } from './utils';
import './GoogleResultsTab.css'; // Assuming you have a CSS file for custom styles

const GoogleResultsTab = ({ googleResults, handleShowModal, Scrapegraph }) => {
  const [loadingCards, setLoadingCards] = useState({}); // State to manage loading status of each card
  const [searchedItems, setSearchedItems] = useState({}); // State to track searched items

  const handleUseClick = async (url, idx) => {
    setLoadingCards(prevState => ({ ...prevState, [idx]: true })); // Set loading state for the card
    await Scrapegraph(url);
    setLoadingCards(prevState => ({ ...prevState, [idx]: false })); // Reset loading state for the card
    setSearchedItems(prevState => ({ ...prevState, [idx]: true })); // Mark item as searched
  };

  return (
    <Container fluid >
      {googleResults.valid_results.length > 0 && (
        <>
          <Row className = "p-2">
            {googleResults.valid_results.map((result, idx) => (
              <Col key={idx} xs={12} md={6} lg={4} >
                <Card className="mb-3 position-relative">
                  {loadingCards[idx] && (
                    <div className="loading-overlay">
                      <Spinner animation="border" variant="secondary" /> {/* Changed spinner color */}
                    </div>
                  )}
                  <Card.Body>
                    <Card.Title className="truncate-title">{result.title || 'Title Unavailable'}</Card.Title>
                    <Card.Text className="truncate-text">
                      {result.url}
                    </Card.Text>
                    <Button variant="primary" onClick={() => window.open(result.url, '_blank')} disabled={loadingCards[idx]}>Open Link</Button>
                    <Button 
                      variant="secondary" 
                      onClick={() => handleUseClick(result.url, idx)} 
                      disabled={loadingCards[idx] || searchedItems[idx]}
                    >
                      {searchedItems[idx] ? 'Already Searched' : 'Use'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      {googleResults.sponsored_results.length > 0 && (
        <>
          <h5>Sponsored Results</h5>
          <Row>
            {googleResults.sponsored_results.map((result, idx) => (
              <Col key={idx} xs={12} md={6} lg={4}>
                <Card className="mb-3 position-relative">
                  {loadingCards[idx] && (
                    <div className="card-loading-overlay">
                      <Spinner animation="border" className="custom-spinner" /> {/* Changed spinner color */}
                    </div>
                  )}
                  <Card.Body>
                    <Card.Title className="truncate-title">{result.title || 'Title Unavailable'}</Card.Title>
                    <Card.Text className="truncate-text">
                      {result.url}
                    </Card.Text>
                    <Button variant="primary" onClick={() => window.open(result.url, '_blank')} disabled={loadingCards[idx]}>Open Link</Button>
                    <Button 
                      variant="secondary" 
                      onClick={() => handleUseClick(result.url, idx)} 
                      disabled={loadingCards[idx] || searchedItems[idx]}
                    >
                      {searchedItems[idx] ? 'Already Searched' : 'Use'}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default GoogleResultsTab;