import React, { useState } from 'react';
import axios from 'axios';

const InvalidateUser = ({ token }) => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleInvalidate = async (e) => {
    e.preventDefault();
    const apiUrl = 'https://api.njpresearch.com/api/invalidate_tokens';
    try {
      const response = await axios.post(apiUrl, null, {
        params: { username },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Device-Info': 'unknown', // Adjust as needed
        },
      });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <div>
      <h2>Invalidate User Tokens</h2>
      <form onSubmit={handleInvalidate}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <button type="submit">Invalidate Tokens</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default InvalidateUser;