import React, { useState, useEffect } from 'react';
import { Button, Table, Container, Modal, Col, Row } from 'react-bootstrap';
import Axios from 'axios';
import AuctionForm from './AuctionForm';
import GenericModal from './GenericModal'; // Import the GenericModal component
import ItemBubble from './ItemBubble';

const Auctions = ({ token }) => {
    const [auctions, setAuctions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentAuction, setCurrentAuction] = useState(null);

    useEffect(() => {
        fetchAuctions();
    }, []);

    const fetchAuctions = () => {
        Axios.get('https://api.njpresearch.com/api/auctions', {  // Change company_id as needed
            headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => setAuctions(response.data))
        .catch(error => console.error('Error fetching auctions:', error));
    };

    const handleEdit = (auction) => {
        setCurrentAuction(auction);
        setShowModal(true);
    };

    const handleAdd = () => {
        setCurrentAuction(null);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const deleteAuction = (auctionId) => {
        if (window.confirm("Are you sure you want to delete this auction?")) {
            Axios.delete(`https://api.njpresearch.com/api/delete_auction/${auctionId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(() => {
                alert('Auction deleted successfully!');
                fetchAuctions();
            })
            .catch(error => {
                console.error('Error deleting auction:', error.response.data);
                alert('Error deleting auction: ' + (error.response.data.detail || error.message));
            });
        }
    };

    return (
        <Container fluid>
            <h1>Auctions List</h1>
            {auctions.map(auction => (
                <ItemBubble key={auction.id} item={auction}
                onRight={() => handleEdit(auction)}
                rightBubble={{
                    color: 'lightblue',
                    icon: 'Cog',
                    iconColor: 'white',
                    show: true,
                  }}
                >
              <Container fluid>
                <Row>
                  <Col xs={3} md={1}>
                    {/* <img src={user.picture + '_small.avif'} alt={user.username}   style = {{maxHeight: "20px" }} fluid/> */}
                  </Col>
                  <Col xs={12} md={3} xl = {2}className='m-0'>
                    <div >Title:</div>
                    <div className='fs-6'>{auction.title}</div>
                  </Col>
                  <Col xs={12} md={3}  xl = {2}>
                    <div >Start Time:</div>
                    <div>{auction.start_time}</div>
                  </Col>
                  <Col xs={12} md={3}  xl = {2}>
                    <div >End Time:</div>
                    <div>{auction.end_time}</div>
                  </Col>
                </Row>
              </Container>
                </ItemBubble>
            ))}
            <Button variant="primary" onClick={handleAdd}>Add Auction</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {auctions.map(auction => (
                        <tr key={auction.id}>
                            <td>{auction.title}</td>
                            <td>{auction.start_time}</td>
                            <td>{auction.end_time}</td>
                            <td>{auction.status}</td>
                            <td>
                                <Button variant="info" onClick={() => handleEdit(auction)}>Edit</Button>{' '}
                                <Button variant="danger" onClick={() => deleteAuction(auction.id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {showModal && (
                <AuctionForm
                    show={showModal}
                    handleClose={handleClose}
                    auction={currentAuction}
                    refreshList={fetchAuctions}
                    token={token}
                />
            )}
        </Container>
    );
};

export default Auctions;
