import React, { useState } from 'react';
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Image,
  Alert
} from 'react-bootstrap';
import permissionsData from './permissions.json'; // Adjust the path as needed

const EditUser = ({ token, user }) => {
  // Parse permissions
  let permissions = user.permissions;
  if (typeof permissions === 'string') {
    try {
      permissions = JSON.parse(permissions);
      permissions = Array.isArray(permissions) ? permissions : [];
    } catch (error) {
      console.error('Failed to parse permissions:', error);
      permissions = [];
    }
  } else if (!Array.isArray(permissions)) {
    permissions = [];
  }

  const initialFormData = {
    user_id: parseInt(user.id),
    username: user.username,
    email: user.email || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    active: user.active || false,
    permissions: permissions,
    deleted: false,
    company_id: user.company_id || user.companyId // Ensure user prop includes company_id
  };

  const [formData, setFormData] = useState(initialFormData);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // State for profile picture update
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [imageUploadResponse, setImageUploadResponse] = useState(null);
  const [imageUploadError, setImageUploadError] = useState(null);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle permission checkbox changes
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const permissionValue = parseInt(value); // Convert value to number
    setFormData((prevData) => {
      const newPermissions = checked
        ? [...prevData.permissions, permissionValue]
        : prevData.permissions.filter((perm) => perm !== permissionValue);
      return {
        ...prevData,
        permissions: newPermissions
      };
    });
  };

  // Handle active status change
  const handleActiveChange = (e) => {
    setFormData({
      ...formData,
      active: e.target.checked
    });
  };

  // Handle delete action
  const handleDelete = () => {
    setFormData({
      ...formData,
      deleted: true
    });
    handleSubmit();
  };

  // Get modified fields to send to the backend
  const getModifiedFields = () => {
    const modifiedFields = { user_id: formData.user_id, username: formData.username };
    for (const key in formData) {
      if (formData[key] !== initialFormData[key]) {
        modifiedFields[key] = formData[key];
      }
    }
    return modifiedFields;
  };

  // Handle main form submission (user details update)
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const modifiedFields = getModifiedFields();
    console.log(formData);
    console.log(modifiedFields);
    try {
      const response = await fetch('https://api.njpresearch.com/api/edit-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(modifiedFields) // Send only modified fields
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail);
      }
      const data = await response.json();
      setResponse(data.message);
      setError(null);
    } catch (err) {
      setError(err.message);
      setResponse(null);
    }
  };

  // Handle profile picture selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type on client side
      if (!['image/jpeg', 'image/png', 'image/avif'].includes(file.type)) {
        setImageUploadError(
          'Invalid image type. Only JPEG, PNG, and AVIF are allowed.'
        );
        setSelectedImage(null);
        setImagePreviewUrl(null);
        return;
      }

      setSelectedImage(file);
      setImageUploadError(null);

      // Create a preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
      setImagePreviewUrl(null);
      setImageUploadError(null);
    }
  };

  // Handle profile picture upload
  const handleImageUpload = async () => {
    if (!selectedImage) {
      setImageUploadError('Please select an image to upload.');
      return;
    }

    try {
      const uploadData = new FormData();
      uploadData.append('username', formData.username); // Use the username from formData
      uploadData.append('user_company_id', formData.company_id); // Use company_id from formData
      uploadData.append('image', selectedImage); // Append the selected image

      const response = await fetch(
        'https://api.njpresearch.com/api/update-user-profile-picture',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
            // 'Content-Type': is NOT set here because the browser automatically sets the correct boundary when using FormData
          },
          body: uploadData
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to upload image.');
      }

      const data = await response.json();
      setImageUploadResponse(data.message);
      setImageUploadError(null);

      // Optionally, update the user's image_url in the main form or refresh user data
      // setFormData(prev => ({ ...prev, image_url: data.image_url }));
    } catch (err) {
      setImageUploadError(err.message);
      setImageUploadResponse(null);
    }
  };

  return (
    <Container>
      <h2>Edit User</h2>
      <Form onSubmit={handleSubmit}>
        {/* User Details Form */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                readOnly // Make email read-only if you don't want it changed
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="first_name">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="last_name">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="active">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={formData.active}
                onChange={handleActiveChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group controlId="permissions">
              <Form.Label>Permissions</Form.Label>
              {Object.entries(permissionsData).map(
                ([categoryName, actions]) => (
                  <div key={categoryName} className="mb-3">
                    <h5>{categoryName}</h5>
                    <Row>
                      {Object.entries(actions).map(([actionName, code]) => (
                        <Col xs={12} md={6} key={code}>
                          <Form.Check
                            type="checkbox"
                            label={`${actionName} (${code})`}
                            value={code}
                            checked={formData.permissions.includes(code)}
                            onChange={handleCheckboxChange}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
              )}
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit">
          Save Changes
        </Button>
        <Button
          variant="danger"
          className="ml-2"
          onClick={() => setShowDeleteModal(true)}
        >
          Delete User
        </Button>
      </Form>
      {response && (
        <Alert variant="success" className="mt-3">
          {response}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}

      {/* Profile Picture Update Section */}
      <hr />
      <h3>Update Profile Picture</h3>
      <Form>
        <Form.Group controlId="profilePicture">
          <Form.Label>Select New Profile Picture</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </Form.Group>
        {imagePreviewUrl && (
          <div className="mb-3">
            <Image
              src={imagePreviewUrl}
              alt="Image Preview"
              rounded
              fluid
              style={{ maxWidth: '200px' }}
            />
          </div>
        )}
        {user.image_url && !imagePreviewUrl && (
          <div className="mb-3">
            <Image
              src={user.image_url}
              alt="Current Profile Picture"
              rounded
              fluid
              style={{ maxWidth: '200px' }}
            />
          </div>
        )}
        <Button variant="secondary" onClick={handleImageUpload}>
          Update Profile Picture
        </Button>
        {imageUploadResponse && (
          <Alert variant="success" className="mt-3">
            {imageUploadResponse}
          </Alert>
        )}
        {imageUploadError && (
          <Alert variant="danger" className="mt-3">
            {imageUploadError}
          </Alert>
        )}
      </Form>

      {/* Delete User Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EditUser;
