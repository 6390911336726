import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Axios from 'axios';

const LocationForm = ({ show, handleClose, location, refreshList, token }) => {
    const [locationDetails, setLocationDetails] = useState({
        location_name: '',
        location_code: ''
    });

    useEffect(() => {
        setLocationDetails(location || {
            location_name: '',
            location_code: ''
        });
    }, [location, show]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocationDetails(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const method = location ? 'PUT' : 'POST';
        const url = location ? `https://api.njpresearch.com/api/edit_location/${location.id}` : 'https://api.njpresearch.com/api/add_location';
        Axios({
            method: method,
            url: url,
            data: locationDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        .then(() => {
            alert('Operation successful!');
            refreshList();
            handleClose();
        })
        .catch(error => {
            console.error('Submission error:', error.response.data);
            alert('Error: ' + error.message);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{location ? 'Edit Location' : 'Add Location'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="location_name"
                            value={locationDetails.location_name}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Location Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="location_code"
                            value={locationDetails.location_code}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">Save Changes</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default LocationForm;
