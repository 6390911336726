import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import './LoginPage.css';

const LoginPage = ({ setToken, setRefreshToken, setLoggedIn, setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
    if (isLoggedIn) {
      navigate('/inventory'); // Redirect to inventory if already logged in
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://api.njpresearch.com/token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        username,
        password
      })
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data);
      const expiryTime = Date.now() + 60 * 60 * 1000; // 60 minutes from now
      setToken(data.access_token);
      console.log('Access token:', data.access_token);
      console.log('Refresh token:', data.refresh_token);
      setRefreshToken(data.refresh_token);

      // Fetch user information
      const userResponse = await fetch('https://api.njpresearch.com/users/me', {
        headers: {
          Authorization: `Bearer ${data.access_token}`
        }
      });

      if (userResponse.ok) {
        const userData = await userResponse.json();
        setLoggedIn(true);
        setUser({
          username: userData.username,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          company_id: userData.company_id,
          id: userData.id,
        });

        // Store token, refresh token, and expiry time in localStorage
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        localStorage.setItem('expiryTime', expiryTime.toString());
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('user', JSON.stringify({
          username: userData.username,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          company_id: userData.company_id,
          id: userData.id,
        }));

        console.log('Login successful');
        navigate('/item-search');
      } else {
        alert('Failed to fetch user information');
      }
    } else {
      alert('Login failed');
    }
  };

  return (
    <Container fluid className="py-3 d-flex align-items-center justify-content-center" style = {{height:'100%'}}>
      <div className="frosted-glass p-3 rounded shadow-lg right-margin-lg" style={{  maxWidth: "600px", width: '100%' }}>
      <h2 className='text-center'>Login</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
        </Form.Group>
        <Button type="submit" className = "w-100 mt-4" style = {{backgroundColor:"#721e5d"}}>Login</Button>
      </Form>
      <div className="text-center">
        <Button 
          variant="link" 
          onClick={() => navigate('/forgot-password-recovery')}
          className="forgot-password-link text-decoration-none"
          style = {{color: 'black'}}
        >
          Forgot password?
        </Button>
      </div>
    </div>
    </Container>
  );
};

export default LoginPage;
