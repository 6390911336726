import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Axios from 'axios';

const ShipmentForm = ({ show, handleClose, shipment, refreshList, token }) => {
    const [shipmentDetails, setShipmentDetails] = useState({
        purchase_date: '',
        receiving_date: '',
        unpack_date: '',
        article_number: '',
        payout_status: false,
        price_paid: '',
        revenue: '',  // These values are kept for display
        profit: '',   // but will not have input fields
        status: '',
        articles_processed: '',
        extra_notes: '',
        supplier_id: ''
    });
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        if (show) {
            Axios.get('https://api.njpresearch.com/api/fetch_suppliers', {
                headers: { 'Authorization': `Bearer ${token}` }
            })
            .then(response => setSuppliers(response.data))
            .catch(error => console.error('Error fetching suppliers:', error));
        }

        setShipmentDetails(shipment || {
            purchase_date: '',
            receiving_date: '',
            unpack_date: '',
            article_number: '',
            payout_status: false,
            price_paid: '',
            revenue: '',
            profit: '',
            status: '',
            articles_processed: '',
            extra_notes: '',
            supplier_id: ''
        });
    }, [shipment, show, token]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setShipmentDetails(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Exclude revenue and profit from being sent to the server
        const { revenue, profit, ...processedDetails } = shipmentDetails;
        
        // Convert empty strings to null for all fields in processedDetails
        const submissionData = Object.keys(processedDetails).reduce((data, key) => {
            data[key] = processedDetails[key] === '' ? null : processedDetails[key];
            return data;
        }, {});
    
        const method = shipment ? 'PUT' : 'POST';
        const url = shipment ? `https://api.njpresearch.com/api/edit_shipments/${shipment.id}` : 'https://api.njpresearch.com/api/add_shipments';
        Axios({
            method: method,
            url: url,
            data: submissionData,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        .then(() => {
            alert('Operation successful!');
            refreshList();
            handleClose();
        })
        .catch(error => {
            console.error('Submission error:', error.response.data);
            alert('Error: ' + error.message);
        });
    };
    

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{shipment ? 'Edit Shipment' : 'Add Shipment'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {Object.keys(shipmentDetails).map(key => (
                        key !== 'revenue' && key !== 'profit' ? (
                            <Form.Group key={key}>
                                <Form.Label>{key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Form.Label>
                                {key === 'status' ? (
                                    <Form.Control as="select" name={key} value={shipmentDetails[key]} onChange={handleChange}>
                                        <option value="">Select Status</option>
                                        <option value="shipping">Shipping</option>
                                        <option value="receiving">Receiving</option>
                                        <option value="unpacking">Unpacking</option>
                                        <option value="complete">Complete</option>
                                    </Form.Control>
                                ) : key === 'supplier_id' ? (
                                    <Form.Control as="select" name={key} value={shipmentDetails[key]} onChange={handleChange}>
                                        <option value="">Select Supplier</option>
                                        {suppliers.map(supplier => (
                                            <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                        ))}
                                    </Form.Control>
                                ) : (
                                    <Form.Control
                                        type={key === 'price_paid' ? 'number' : key.includes('date') ? 'date' : key === 'payout_status' ? 'checkbox' : 'text'}
                                        name={key}
                                        value={key === 'payout_status' ? undefined : shipmentDetails[key]}
                                        onChange={handleChange}
                                        checked={key === 'payout_status' ? shipmentDetails[key] : undefined}
                                    />
                                )}
                            </Form.Group>
                        ) : (
                            <Form.Group key={key}>
                                <Form.Label>{key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Form.Label>
                                <Form.Control plaintext readOnly defaultValue={shipmentDetails[key]} />
                            </Form.Group>
                        )
                    ))}
                    <Button variant="primary" type="submit">Save Changes</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ShipmentForm;
