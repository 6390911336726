import React from 'react';
import { Row, Col, Badge, Tab, Container } from 'react-bootstrap';
import SearchResultCard from './SearchResultCard';
import { getImageLink } from './utils';

const AmazonResultsTab = ({ amazonResults, handleExpand, handleUse, handleShowModal, usedItemLink }) => (
  <Container fluid>
    <Row className='p-2 tab-content'>
      {amazonResults.map((result, idx) => (
        result.image_url = getImageLink(result),
        <Col key={idx} xs={6} md = {3} lg={2}>
          <SearchResultCard
            key={idx}
            item={result}
            imageUrl={result.image_url}
            showExpandButton={!result.expanded} // Show the expand button only if not expanded
            showUseButton={true} // Show the use button
            onExpand={(link) => handleExpand(link)} // Button to expand the item
            onUse={() => handleUse(result)} // Button to use the item
            onPreview={() => handleShowModal(idx, result)}
            isLoading={result.loading} // Pass the loading state to the card
            expanded={result.expanded}
            used={result.link === usedItemLink} // Pass the used state to the card
          />
        </Col>
      ))}
    </Row>
  </Container>
);

export default AmazonResultsTab;