import React, { useRef, useState } from 'react';
import * as Icons from 'react-icons/fa';
import ConfirmationModal from './ConfirmationModal';
import './ListItemBubble.css';

const getIconComponent = (iconName, color, size, animation) => {
  const IconComponent = Icons[`Fa${iconName}`];
  return IconComponent ? (
    <div className={animation === 'spin' ? 'spin' : ''}>
      <IconComponent color={color} size={size} />
    </div>
  ) : null;
};

const ItemBubble = ({
  children,
  onClick,
  onLeft,
  onRight,
  bubbleColor = '#e1e1e1',
  leftBubble = {
    color: '#ff6b6b',
    icon: 'Trash',
    iconColor: 'white',
    show: false,
    confirmationText: 'Delete',
    confirmation: false,
  },
  rightBubble = {
    color: '#ff6b6b',
    icon: 'Trash',
    iconColor: 'white',
    show: false,
    confirmationText: 'Edit',
    confirmation: false,
  },
  iconSize = '2em',
  statusIcon = {
    show: false,
    side: 'left',
    color: 'green',
    animation: false,
    size: '2em',
    name: 'CheckCircle',
  },
}) => {
  const bubbleRef = useRef(null);
  const [modalState, setModalState] = useState({
    action: null,
    show: false,
  });
  const [isSwiping, setIsSwiping] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
    setIsDragging(false);
    if (bubbleRef.current) {
      bubbleRef.current.style.transition = 'none';
    }
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;
    const touchCurrentX = e.touches[0].clientX;
    setCurrentX(touchCurrentX);
    const diffX = touchCurrentX - startX;
    if (
      (diffX < 0 && rightBubble.show) ||
      (diffX > 0 && leftBubble.show)
    ) {
      setIsDragging(true);
      if (bubbleRef.current) {
        bubbleRef.current.style.transform = `translateX(${diffX}px)`;
      }
    }
  };

  const handleTouchEnd = () => {
    if (!isSwiping) return;
    setIsSwiping(false);
    const diffX = currentX - startX;
    const bubbleWidth = bubbleRef.current
      ? bubbleRef.current.offsetWidth
      : 0;
    const swipePercentage = Math.abs(diffX) / bubbleWidth;

    if (bubbleRef.current) {
      bubbleRef.current.style.transition = 'transform 0.3s ease';
    }

    if (isDragging && swipePercentage > 0.3) {
      const side = diffX < 0 ? 'right' : 'left';
      if (
        (side === 'right' && rightBubble.confirmation) ||
        (side === 'left' && leftBubble.confirmation)
      ) {
        setModalState({ action: side, show: true });
      } else {
        confirmAction(side);
      }
    } else {
      if (bubbleRef.current) {
        bubbleRef.current.style.transform = 'translateX(0)';
      }
    }
  };

  const handleMouseClick = (side) => {
    if (
      (side === 'left' && leftBubble.confirmation) ||
      (side === 'right' && rightBubble.confirmation)
    ) {
      setModalState({ action: side, show: true });
    } else {
      confirmAction(side);
    }
  };

  const confirmAction = (side) => {
    setModalState({ action: null, show: false });
    if (side === 'left') {
      onLeft();
    } else if (side === 'right') {
      onRight();
    }
    if (bubbleRef.current) {
      bubbleRef.current.style.transform = 'translateX(0)';
    }
  };

  const handleCancel = () => {
    setModalState({ action: null, show: false });
    if (bubbleRef.current) {
      bubbleRef.current.style.transform = 'translateX(0)';
    }
  };

  const getBubbleClass = () => {
    if (leftBubble.show && rightBubble.show) return 'shrink-both';
    if (leftBubble.show) return 'shrink-left';
    if (rightBubble.show) return 'shrink-right';
    return 'shrink-none';
  };

  return (
    <>
      <div
        className={`list-item-bubble-container p-0`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={`list-item-bubble ${getBubbleClass()} m-0`}
          onClick={onClick}
          ref={bubbleRef}
          style={{ backgroundColor: bubbleColor }}
        >
          {children}
          {statusIcon.show && statusIcon.side === 'right' && (
            <div
              className={`status-icon ${
                statusIcon.animation ? 'animate' : ''
              }`}
            >
              {getIconComponent(
                statusIcon.name,
                statusIcon.color,
                statusIcon.size,
                statusIcon.animation
              )}
            </div>
          )}
        </div>
        {statusIcon.show && statusIcon.side === 'left' && (
          <div
            className={`status-icon ${
              statusIcon.animation ? 'animate' : ''
            }`}
          >
            {getIconComponent(
              statusIcon.name,
              statusIcon.color,
              statusIcon.size,
              statusIcon.animation
            )}
          </div>
        )}
        {leftBubble.show && (
          <div
            className="list-item-left-background"
            onClick={() => handleMouseClick('left')}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ backgroundColor: leftBubble.color }}
          >
            {getIconComponent(
              leftBubble.icon,
              leftBubble.iconColor,
              iconSize
            )}
          </div>
        )}
        {rightBubble.show && (
          <div
            className="list-item-right-background"
            onClick={() => handleMouseClick('right')}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ backgroundColor: rightBubble.color }}
          >
            {getIconComponent(
              rightBubble.icon,
              rightBubble.iconColor,
              iconSize
            )}
          </div>
        )}
      </div>

      {/* Reusable Confirmation Modal */}
      <ConfirmationModal
        show={modalState.show}
        onHide={handleCancel}
        question={`Are you sure you want to ${
          modalState.action === 'left'
            ? leftBubble.confirmationText
            : rightBubble.confirmationText
        } this item?`}
        onConfirm={() => confirmAction(modalState.action)}
        confirmText={
          modalState.action === 'left'
            ? leftBubble.confirmationText
            : rightBubble.confirmationText
        }
        cancelText="Cancel"
      />
    </>
  );
};

export default ItemBubble;
