// SupplierDetails.js
import React from 'react';
import { Card, ListGroup, Row, Col, Badge } from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaBuilding, FaUserTie, FaToilet } from 'react-icons/fa';
import PropTypes from 'prop-types';

const SupplierDetails = ({ supplier }) => {
    return (
                <ListGroup variant="flush">
                    {/* Supplier Name */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaBuilding size={20} color="#0d6efd" aria-label="Supplier Name Icon" />
                            </Col>
                            <Col>
                                <strong>Name:</strong> {supplier.name || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Representative Name */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaUserTie size={20} color="#0d6efd" aria-label="Representative Name Icon" />
                            </Col>
                            <Col>
                                <strong>Representative:</strong> {supplier.representative_name || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Phone Number */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaPhoneAlt size={20} color="#0d6efd" aria-label="Phone Number Icon" />
                            </Col>
                            <Col>
                                <strong>Phone:</strong> {supplier.phone_number || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Email */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaEnvelope size={20} color="#0d6efd" aria-label="Email Icon" />
                            </Col>
                            <Col>
                                <strong>Email:</strong> {supplier.email || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Payout Email */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaEnvelope size={20} color="#0d6efd" aria-label="Payout Email Icon" />
                            </Col>
                            <Col>
                                <strong>Payout Email:</strong> {supplier.payout_email || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Address */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaMapMarkerAlt size={20} color="#0d6efd" aria-label="Address Icon" />
                            </Col>
                            <Col>
                                <strong>Address:</strong> {supplier.address || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Business Number */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaBuilding size={20} color="#0d6efd" aria-label="Business Number Icon" />
                            </Col>
                            <Col>
                                <strong>Business Number:</strong> {supplier.business_number || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Status */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                {/* You can choose different icons based on status */}
                                {supplier.status === 'Active' ? (
                                    <FaBuilding size={20} color="#28a745" aria-label="Active Status Icon" />
                                ) : (
                                    <FaBuilding size={20} color="#dc3545" aria-label="Inactive Status Icon" />
                                )}
                            </Col>
                            <Col>
                                <strong>Status:</strong> 
                                <Badge 
                                    bg={supplier.status === 'Active' ? 'success' : 'danger'} 
                                    className="ms-2"
                                >
                                    {supplier.status || 'N/A'}
                                </Badge>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                    {/* Extra Notes */}
                    <ListGroup.Item>
                        <Row>
                            <Col xs={2}>
                                <FaToilet size={20} color="#0d6efd" aria-label="Extra Notes Icon" />
                            </Col>
                            <Col>
                                <strong>Extra Notes:</strong> {supplier.extra_notes || 'N/A'}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
    );
};


export default SupplierDetails;
