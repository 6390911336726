// ImageModal.js
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ image }) => {
  const [show, setShow] = useState(false);

  const handleImageClick = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <>
      <img
        src={image.url}
        alt={image.name}
        className="image-content"
        onClick={handleImageClick}
        style={{ cursor: 'pointer' }}
      />

      <Modal show={show} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{image.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={image.url}
            alt={image.name}
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageModal;
