import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import Axios from 'axios';

const AuctionForm = ({ show, handleClose, auction, refreshList, token }) => {
    const [auctionDetails, setAuctionDetails] = useState({
        title: '',
        start_time: '',
        end_time: '',
        status: '',
        auction_notes: '',
        auction_premium: 0,
        autobid: false,
        auction_expiry: '',
        auction_description: '',
        min_bid_1_50: 0.0,
        min_bid_51_100: 0.0,
        min_bid_101_500: 0.0,
        min_bid_501_1000: 0.0,
        min_bid_1001: 0.0
    });

    useEffect(() => {
        setAuctionDetails(auction || {
            title: '',
            start_time: '',
            end_time: '',
            status: '',
            auction_notes: '',
            auction_premium: 0,
            autobid: false,
            auction_expiry: '',
            auction_description: '',
            min_bid_1_50: 0.0,
            min_bid_51_100: 0.0,
            min_bid_101_500: 0.0,
            min_bid_501_1000: 0.0,
            min_bid_1001: 0.0
        });
    }, [auction, show]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setAuctionDetails(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const processedDetails = Object.fromEntries(
            Object.entries(auctionDetails).map(([key, value]) => [key, value === '' ? null : value])
        );
        processedDetails.articles_processed = 0; // Ensure articles_processed is always 0


        const method = auction ? 'PUT' : 'POST';
        const url = auction ? `https://api.njpresearch.com/api/edit_auction/${auction.id}` : 'https://api.njpresearch.com/api/create_auction';
        console.log('Submitting:', processedDetails);
        Axios({
            method: method,
            url: url,
            data: processedDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        })
        .then(() => {
            alert('Operation successful!');
            refreshList();
            handleClose();
        })
        .catch(error => {
            console.error('Submission error:', error.response.data);
            alert('Error: ' + error.message);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{auction ? 'Edit Auction' : 'Add Auction'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {Object.keys(auctionDetails).map(key => (
                        key !== 'articles_processed' && (
                            <Form.Group key={key}>
                                <Form.Label>{key.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</Form.Label>
                                {key === 'status' ? (
                                    <Form.Control as="select" name={key} value={auctionDetails[key]} onChange={handleChange} required>
                                        <option value="">Select Status</option>
                                        <option value="upcoming">Upcoming</option>
                                        <option value="ongoing">Ongoing</option>
                                        <option value="completed">Completed</option>
                                    </Form.Control>
                                ) : key === 'autobid' ? (
                                    <Form.Check
                                        type="checkbox"
                                        name={key}
                                        checked={auctionDetails[key]}
                                        onChange={handleChange}
                                    />
                                ) : key === 'auction_description' || key === 'auction_notes' ? (
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        value={auctionDetails[key]}
                                        onChange={handleChange}
                                        as="textarea"
                                    />
                                ) : key === 'auction_premium' ? (
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            name={key}
                                            value={auctionDetails[key]}
                                            onChange={handleChange}
                                            style={{ width: '100px' }}
                                            required
                                        />
                                        <InputGroup.Text>%</InputGroup.Text>
                                    </InputGroup>
                                ) : (
                                    <Form.Control
                                        type={key.includes('time') || key.includes('expiry') ? 'datetime-local' : 'text'}
                                        name={key}
                                        value={auctionDetails[key]}
                                        onChange={handleChange}
                                        required={key !== 'auction_description' && key !== 'auction_notes'}
                                    />
                                )}
                            </Form.Group>
                        )
                    ))}
                    <Button variant="primary" type="submit">Save Changes</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AuctionForm;