import React, { useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import * as Icons from 'react-icons/fa'; // Import all icons from react-icons/fa
import ConfirmationModal from './ConfirmationModal'; // Import your reusable modal
import './ListItemBubble.css';

// Function to get the icon component based on the string value, color, size, and animation
const getIconComponent = (iconName, color, size, animation) => {
  const IconComponent = Icons[`Fa${iconName}`];
  return IconComponent ? (
    <div className={animation === 'spin' ? 'spin' : ''}>
      <IconComponent color={color} size={size} />
    </div>
  ) : null;
};

const ListItemBubble = ({
  item,
  onClick,
  onLeft,
  onRight,
  imageSide = 'left',
  bubbleColor = '#e1e1e1',
  textColor = 'black',
  showImage = false,
  leftBubble = {
    color: '#ff6b6b',
    icon: 'Trash',
    iconColor: 'white',
    show: false,
    confirmationText: 'Delete',
    confirmation: false,
  },
  rightBubble = {
    color: '#ff6b6b',
    icon: 'Trash',
    iconColor: 'white',
    show: false,
    confirmationText: 'Edit',
    confirmation: false,
  },
  iconSize = '2em',
  statusIcon = {
    show: false,
    side: 'left',
    color: '#721e5d',
    animation: false,
    size: '2em',
    name: 'CheckCircle',
  },
}) => {
  const bubbleRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isSlidingOut, setIsSlidingOut] = useState(false);
  const [action, setAction] = useState(null);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
    setIsDragging(false);
    bubbleRef.current.style.transition = 'none';
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;
    const touchCurrentX = e.touches[0].clientX;
    setCurrentX(touchCurrentX);
    const diffX = touchCurrentX - startX;
    if ((diffX < 0 && rightBubble.show) || (diffX > 0 && leftBubble.show)) {
      setIsDragging(true);
      bubbleRef.current.style.transform = `translateX(${diffX}px)`;
    }
  };

  const handleTouchEnd = () => {
    if (!isSwiping) return;
    setIsSwiping(false);
    const diffX = currentX - startX;
    const bubbleWidth = bubbleRef.current.offsetWidth;
    const swipePercentage = Math.abs(diffX) / bubbleWidth;

    bubbleRef.current.style.transition = 'transform 0.3s ease';

    if (isDragging && swipePercentage > 0.3) {
      if ((diffX < 0 && rightBubble.confirmation) || (diffX > 0 && leftBubble.confirmation)) {
        setShowDeleteModal(true);
      } else {
        confirmAction();
      }
      setAction(diffX < 0 ? 'right' : 'left');
      bubbleRef.current.style.transform = `translateX(${diffX < 0 ? -30 : 30}%)`;
    } else {
      bubbleRef.current.style.transform = 'translateX(0)';
    }
  };

  const handleMouseClick = (side) => {
    setAction(side);
    if ((side === 'left' && leftBubble.confirmation) || (side === 'right' && rightBubble.confirmation)) {
      setShowDeleteModal(true);
    } else {
      confirmAction();
    }
  };

  const confirmAction = () => {
    setShowDeleteModal(false); // Hide the modal immediately
    setIsSlidingOut(true); // Start the slide-out animation
    setTimeout(() => {
      if (action === 'left') {
        onLeft(item.intcode); // Perform the left action (edit) after the animation completes
      } else if (action === 'right') {
        onRight(item.intcode); // Perform the right action (delete) after the animation completes
      }
    }, 300); // Match the duration of the slide-out animation
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
    // Reset the bubble position on cancel
    bubbleRef.current.style.transform = 'translateX(0)';
  };

  const getBubbleClass = () => {
    if (leftBubble.show && rightBubble.show) return 'shrink-both';
    if (leftBubble.show) return 'shrink-left';
    if (rightBubble.show) return 'shrink-right';
    return 'shrink-none';
  };

  return (
    <>
      <div
        className={`list-item-bubble-container ${isSlidingOut ? 'slide-out' : ''}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={`list-item-bubble ${getBubbleClass()}`}
          onClick={onClick}
          ref={bubbleRef}
          style={{ backgroundColor: bubbleColor, color: textColor }}
        >
          <div className={`list-item-bubble-content ${imageSide}`}>
            {showImage && item.images && item.images.length > 0 ? (
              <Image
                src={`${JSON.parse(item.images)[0]}_small.avif`}
                className="list-item-bubble-image"
                fluid
              />
            ) : (
              showImage && <div className="list-item-bubble-no-image">No Image</div>
            )}
            <div className="list-item-bubble-text">
              <div className="list-item-bubble-intcode">{item.intcode}</div>
              <div className="list-item-bubble-title">{item.title}</div>
            </div>
            {statusIcon.show && statusIcon.side === 'right' && (
              <div className={`status-icon ${statusIcon.animation ? 'animate' : ''}`}>
                {getIconComponent(statusIcon.name, statusIcon.color, statusIcon.size, statusIcon.animation)}
              </div>
            )}
          </div>
          {statusIcon.show && statusIcon.side === 'left' && (
            <div className={`status-icon ${statusIcon.animation ? 'animate' : ''}`}>
              {getIconComponent(statusIcon.name, statusIcon.color, statusIcon.size, statusIcon.animation)}
            </div>
          )}
        </div>
        {leftBubble.show && (
          <div
            className="list-item-left-background"
            onClick={() => handleMouseClick('left')}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ backgroundColor: leftBubble.color }}
          >
            {getIconComponent(leftBubble.icon, leftBubble.iconColor, iconSize)}
          </div>
        )}
        {rightBubble.show && (
          <div
            className="list-item-right-background"
            onClick={() => handleMouseClick('right')}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ backgroundColor: rightBubble.color }}
          >
            {getIconComponent(rightBubble.icon, rightBubble.iconColor, iconSize)}
          </div>
        )}
      </div>

      {/* Reusable Confirmation Modal */}
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleCancel}  // Reset the bubble position on cancel
        question={`Are you sure you want to ${action === 'left' ? leftBubble.confirmationText : rightBubble.confirmationText} this item?`}
        onConfirm={confirmAction} // Call confirmAction on confirm
        confirmText={action === 'left' ? leftBubble.confirmationText : rightBubble.confirmationText}
        cancelText="Cancel"
      />
    </>
  );
};

export default ListItemBubble;